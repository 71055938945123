import React, { useContext, useEffect } from "react";
import IconButton from '@mui/material/IconButton';
import AppContext from "../../Context/AppContext";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from "@mui/material";
import LockResetIcon from '@mui/icons-material/LockReset';


export default function Resetear(props) {
  const [open, setOpen] = React.useState(false);
  const { token } = useContext(AppContext);

  //Conexión API 
  const handleReset = () => {
    fetch(process.env.REACT_APP_API_PATH + "/usuario/restaurarPasswordByIdEmp/" + props.empleado, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        token: token,
      }
    })
      .then((response) => response.json())
      .then((data) => { console.log(data); })
      .catch((error) => {
        console.log(error);
        console.log(error);
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
    handleReset();
  };

  const handleCancel = (event) => {
    event.preventDefault();
    setOpen(false);
  };

  return (
    <>
      <LockResetIcon fontSize="small" onClick={handleClickOpen} />

      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Resetear Contraseña"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography>La contraseña ha sido reseteada. La nueva contraseña del usuario es su dni.</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} variant="outlined" sx={{ color: '#62A0C0', borderColor: '#62A0C0' }} fullWidth>Aceptar</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
