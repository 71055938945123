import React from 'react';
import cody from '../../images/Cody-Pregunta1.png'

export default function PantallaPaginaNoEncontrada(){
    return(
        <div className="divError">
            <img src={cody} className="codyError" alt='imagen-error'></img>
            <h1>Error 404</h1>
            <h3>Página No Encontrada</h3>
            <p style={{margin:'1%'}}>La URL requerida no fue encontrada en el servidor.</p>

        </div>
    )
    
}