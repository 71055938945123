import React from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import { useContext} from 'react';
import AppContext from "../../Context/AppContext";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function BorrarEmpleado(props) {
  const [open, setOpen] = React.useState(false);
  const {token} = useContext(AppContext);

  //Conexión API
  const handleDelete= () => {
    fetch(process.env.REACT_APP_API_PATH + "/empleado/" + props.empleadoId, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        token: token,
      }
    })
    .then((response) => response.json())
    .then((data) => {console.log(data);
        props.setReloadData(true);
        alert('El empleado fue borrado correctamente.');})
    .catch((error) => {console.log(error);
        alert('Error. No se pudo eliminar el empleado.')
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event) => {
    event.preventDefault();
    handleDelete();
    setOpen(false);
  };

  const handleCancel = (event) => {
    event.preventDefault(); 
    setOpen(false);
  };

  return (
    <>
      <>{props.texto ? (
        <Button
          variant="contained"
          sx={{ backgroundColor: '#62A0C0', textTransform: 'none', "&:hover": {backgroundColor: '#2C4C58',}}}
          onClick={handleClickOpen}
        >
          {props.texto}
        </Button>
      ) : (
        <DeleteIcon fontSize="small" onClick={handleClickOpen} />
      )}</>

      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Dar de Baja Empleado"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Está seguro/a que desea dar de baja el empleado?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} variant="outlined" sx={{color:"#AF1F1F", borderColor:"#AF1F1F", "&:hover": {borderColor: '#6c1313',}}}>Cancelar</Button>
          <Button onClick={handleClose} variant="contained" autoFocus sx={{backgroundColor:"#AF1F1F", "&:hover": {backgroundColor: '#6c1313',}}} >Confirmar Baja</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}