import React from "react";
import { useState } from "react";
import { DataGrid, GridActionsCellItem, useGridApiRef, esES} from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import SearchIcon from '@mui/icons-material/Search';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useContext} from 'react';
import AppContext from "../Context/AppContext";
import FirmarDocumento from "../Usuario/FirmarDocumento";
import PdfViewerModal from "../PDFViewer";
import CustomNoRowsOverlay from "./CustomNoRowsOverlay";

import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import TaskAltIcon from '@mui/icons-material/TaskAlt';


var today = new Date();

const options = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric'
};

//Descarga de Datos de la Tabla
const DownloadPDFButton = ({ data }) => {
  const handleDownloadPDF = () => {
    const doc = new jsPDF();
    doc.text("Documentos", 10, 10)
    const tableData = data.map((row) => [
      row.id,
      row.fecha,
      row.tipo,
      row.descEstado,
    ]);

    doc.autoTable({
      head: [['Id', 'Fecha', 'Tipo de Documento', 'Estado']],
      body: tableData,
    });

    doc.setFontSize(12);
    var now = today.toLocaleString();
    doc.text(now, 40, 10)
    doc.save('documentos.pdf');
  };

  return (
    <Button variant="contained" onClick={handleDownloadPDF} sx={{ backgroundColor: '#62A0C0', textTransform: 'none', "&:hover": {backgroundColor: '#2C4C58',}  }}>
      Descargar
    </Button>
  );
};


//Setear el color de los chips de estado
function colorChip(prop){
  switch(prop){
    case 'Anulado': 
      return 'error';
    case 'Pendiente':
      return 'secondary';
    case 'Leido Sin Firmar':
      return 'info';
    case 'Firmado Con Conformidad':
      return 'success';
    case 'Firmado Con Protesto':
      return 'warning';
    default:
      return 'info';
  }
}

//Setear el icono de los chips de estado
function iconoChip(prop){
  switch(prop){
    case 'Anulado': 
      return <CloseIcon fontSize="small"/>;
    case 'Pendiente':
      return <CheckIcon fontSize="small"/>;
    case 'Leido Sin Firmar':
      return <DoneAllIcon fontSize="small"/>;
    case 'Firmado Con Conformidad':
      return <TaskAltIcon fontSize="small"/>;
    case 'Firmado Con Protesto':
      return  <ErrorOutlineIcon fontSize="small" />;
    default:
      return <CloseIcon fontSize="small"/>;
  }
}

export default function ConsultaDocumentos(){
  const [openPdfViewer, setOpenPdfViewer] = useState(false);
  const [selectedPdf, setSelectedPdf] = useState(null);

  const [reloadData, setReloadData] = React.useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [pageSize, setPageSize] = useState(5);
  const [documentos, setDocumentos] = useState([]);
  const [documento, setDocumento] = useState('');
  const [filters, setFilters] = useState({
    estado: '',
    tipo: '',
    busqueda: '',
  });

  const { token } = useContext(AppContext);

  const handleRowClick = (params) => {
    setSelectedPdf(params.row.pdf);
    setOpenPdfViewer(true);
  };

  const handleClosePdfViewer = () => {
    setOpenPdfViewer(false);
    setSelectedPdf(null);
  };

  const columns = [
    {
      field: 'id', headerName: 'ID', width: 'flex', flex: 0.3, 
    },
    { field: 'fecha', headerName: 'Fecha', width: 'flex', flex: 0.5, minWidth: 100,},
    { field: 'tipo', headerName: 'Tipo de Documento', width: 'flex', flex: 1.3, minWidth: 230,
    renderCell: (params) => {
      return(<Chip label={params.value} variant={"outlined"} size="small" color={params.value === "Control de Asignacion de Equipo" ? "primary" : "secondary"}></Chip>)
    } },
    { field: 'descEstado', headerName: 'Estado', width: 'flex', flex: 1.1, minWidth: 200,
      renderCell: (params) => {
        return(<Chip label={params.value} icon={iconoChip(params.value)} variant={"outlined"} size="small" color={colorChip(params.value)}></Chip>)
      }},
    {
      field: 'actions',
      type: 'actions',
      width: 'flex',
      flex: 1.3,
      minWidth: 280,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<FirmarDocumento rol={3} firmado={params.row.firmado} setReloadData={setReloadData} documento={params.row.id}/>}
          label="Firmar Documento"
        />,//params.row.documento
        
        <GridActionsCellItem
          icon={<PdfViewerModal open={openPdfViewer} onClose={handleClosePdfViewer} pdfDocument={params.row.documento} idDocumento={params.row.id} tipo={params.row.tipo} rol={3} setReloadData={setReloadData} />}
          label="Ver Documento"
        />,
      ],
    },
  ];

  //Filtrado de datos según selección
  const filteredData = ConvertirObjeto(documentos).filter(item => {
    return (
      (filters.tipo === '' || item.tipo=== filters.tipo) &&
      (filters.estado === '' || item.estado.toString() === filters.estado) &&
      (filters.busqueda === '' ||
        item.id.toString().includes(filters.busqueda) ||
        item.tipo.includes(filters.busqueda) ||
        item.descEstado.includes(filters.busqueda) ||
        item.fecha.includes(filters.busqueda))
    );
  });


  const apiRef = useGridApiRef();

  //Conexión API - Mis Documentos
  const getDocumentos = async () => {
    try {
      const url = process.env.REACT_APP_API_PATH + '/usuario/documentos' + (reloadData ? `?timestamp=${Date.now()}` : '');
      const response = await fetch(url, {
        method: "GET",
        headers: {
          token: token
        },
      });
      const res = await response.json();
      setDocumentos(res);
      setReloadData(false);
      console.log(res.status);
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    getDocumentos();
  }, [reloadData]);

  function ConvertirObjeto (documentosJson) {

    const dataProcesada = [];
    documentosJson.map( (e, index) => {
      dataProcesada.push({
        id: e.idDocumento,
        firmado: e.idEstadoFirma === 4 || e.idEstadoFirma === 5,
        documento: e.documento,
        fecha: new Date(e.fechaDoc).toLocaleDateString('es-ES', options),
        tipo: e.idTipo === 1 ? "Control de Asignacion de Equipo" : "Responsabilidad Sobre el Equipo",
        estado: e.idEstadoFirma.toString(),
        descEstado: e.idEstadoFirma === 1 ? "Anulado" : (
          e.idEstadoFirma === 2 ? "Pendiente" : (
            e.idEstadoFirma === 3 ? "Leido Sin Firmar" : (
              e.idEstadoFirma === 4 ? "Firmado Con Conformidad" : "Firmado Con Protesto"
            )
          )
        ),
      })
    });

    return dataProcesada;
  }  

  const rows = documentos != null ? ConvertirObjeto(documentos) : { id: 0, equipo: 'uwu', componentes: ['Mouse', 'Teclado', 'Monitor', 'Auriculares'] };

  //Filas Seleccionadas
  const onRowsSelectionHandler = (ids) => {
    const selectedRowsData = ids.map((id) => rows.find((row) => row.id === id));
    setSelectedRows(selectedRowsData)
  };

  return (
    <div className="divCentral">
      <Stack direction='row' useFlexGap flexWrap="wrap" alignItems="center" justifyContent="space-between" paddingBottom={'2%'}>
        <h1>Documentos</h1>
      </Stack>

      <Stack direction='row' useFlexGap flexWrap="wrap" alignItems="center"  spacing={'10%'} justifyContent={{sx:"center", sm:"space-between"}}>
        {/* Filtro por Tipo de Documento */}
        <FormControl sx={{ m: 1, minWidth:{xs:'45%', sm:160}, mr:0, ml:0 }} size="small">
          <InputLabel id="demo-select-small-label">Tipo de Documento</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={filters.tipo}
            onChange={e => setFilters({ ...filters, tipo: e.target.value })}
            label="Tipo de Documento"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={"Control de Asignacion de Equipo"}>Control de Asignación de Equipo</MenuItem>
            <MenuItem value={"Responsabilidad Sobre el Equipo"}>Responsabilidad Sobre el Equipo</MenuItem>
          </Select>
        </FormControl>

        {/* Filtro por Estado */}
        <FormControl sx={{ m: 1, minWidth:{xs:'45%', sm:120}, mr:0, ml:0 }} size="small">
          <InputLabel id="demo-select-small-label">Estado</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={filters.estado}
            onChange={e => setFilters({ ...filters, estado: e.target.value })}
            label="Estado"
          >
            <MenuItem value=''>
              <em>None</em>
            </MenuItem>
            <MenuItem value={'1'}>Anulado</MenuItem>
            <MenuItem value={'2'}>Pendiente</MenuItem>
            <MenuItem value={'3'}>Leido Sin Firmar</MenuItem>
            <MenuItem value={'4'}>Firmado Con Conformidad</MenuItem>
            <MenuItem value={'5'}>Firmado Con Protesto</MenuItem>
          </Select>
        </FormControl>


        {/* Busqueda */}
        <FormControl sx={{ m: 1, width:{xs:'100%', sm:'25ch'}, mr:0, ml:0}} variant="outlined" size="small">
          <InputLabel htmlFor="outlined-adornment-password">Buscar...</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            value={filters.busqueda}
            onChange={e => setFilters({ ...filters, busqueda: e.target.value })}
            endAdornment={
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            }
            label="Buscar"
          />
        </FormControl>
      </Stack>

      <Box
        sx={{
          height: 400,
          width: '100%',
          paddingTop: '1%'
        }}>
        <DataGrid
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          rows={documentos ? filteredData : null}
          apiRef={apiRef}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[5, 10, 20]}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          onRowClick={handleRowClick}
          pagination
          checkboxSelection
          disableColumnFilter
          disableDensitySelector
          disableColumnSelector
          headerClassName="headerCeleste"
          onRowSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
          selectionModel={selectedRows}
          slots={{
            noRowsOverlay: CustomNoRowsOverlay,
          }}
        />
      </Box>
      <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" justifyContent="center" sx={{ width: '100%', paddingTop: '1%' }} spacing={1}>
        <DownloadPDFButton data={selectedRows.length !== 0 ? selectedRows : rows} />
      </Stack>

    </div>
  )
}
