import React from "react";
import { useContext} from 'react';
import AppContext from "../Context/AppContext";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import { TextField } from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import Typography from '@mui/material/Typography';

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import CAE from "../pdf/CAE";
import RSE from "../pdf/RSE";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

/**
 * Esta App utiliza la librería pdfMake para generar documentos PDF dinámicos, listos para descargar e imprimir.
 * Se completa el PDF con los datos ingresados a través del input y seleccionados de la base de datos.
 * Los documentos generados son 
 *      - Responsabilidad sobre los equipos (RSE)
 *      - Control de asignación de equipos (CAE)
 * 
 * Las plantillas se encuentran en la carpeta 'pdf'
 */

export default function GenerarDocumento(){
    //Constantes para validaciones
    const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z0-9- ]+$/;
    const ALPHA_NUMERIC_DOT = /^[a-zA-Z0-9.]*$/;
    const NUMERIC_DOT = /^[Z0-9-]*$/;

    //Datos
    const[empleado, setEmpleado] = React.useState('');
    const[equipo, setEquipo] = React.useState('');
    const[puesto, setPuesto] = React.useState('');
    const[cuil, setCuil] = React.useState('');
    const[legajo, setLegajo] = React.useState('');
    const[sector, setSector] = React.useState('');
    const[rol, setRol] = React.useState('');
    const {token} = useContext(AppContext);

    //Colecciones
    const[empleados, setEmpleados] = React.useState([]);
    const[equipos, setEquipos] = React.useState([]);
    const[puestos, setPuestos] = React.useState([]);
    const[cadena, setCadena] = React.useState([]);

    const componentes = () => {
        //"- RAM 8gb \n - Disco SSD 256gb \n - Monitor x"
        setCadena([]);
        if(equipo.componentes){
            equipo.componentes.map((c, index2) => {
                cadena.push(c.tipo_componente.descripcion.toUpperCase() + ": " 
                + "id "+ c.idComponente
                + (c.marca ? (" - marca: " + c.marca) : "")
                + (c.modelo ? (" - modelo: " + c.modelo) : "")
                + (c.numSerie ? (" - numero de serie: " + c.numSerie) : "")
                + (c.capacidadMemoria ? (" - capacidad: " + c.capacidadMemoria) : "")
                + (c.descripcion ? (" - descripcion: " + c.descripcion) : "")
                + " \n");
            })
        }  
    }

    const createRSE = () => {
        componentes();
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        const fecha = new Date(Date.now());
        console.log("cadena", cadena.toString());
        // ------- Abre ventana emergente -------
        pdfMake.createPdf(RSE(empleado, cuil, legajo, rol, cadena.toString().replaceAll(',', ''), equipo.id, equipo.descripcion, fecha.toLocaleDateString("es-AR", options), equipo.tipo)).open();
    };
      const createCAE = () => {
        const fecha = new Date(Date.now())
        componentes();
        console.log("cadena", cadena);
        pdfMake.createPdf(CAE(empleado, rol, equipo.componentes, fecha.toLocaleDateString(), fecha.toLocaleTimeString('it-IT'), equipo.id)).open();
      };

    const getEmpleados = async () => {
        try {
          const url = process.env.REACT_APP_API_PATH + '/empleado';
          const response = await fetch(url, {
            method: "GET",
            headers: {
              token: token
            },
          });
          const res = await response.json();
          setEmpleados(res);
          console.log(res.status);
        } catch (error) {
          console.error(error);
        }
    };

    const getEquipos = async () => {
        try {
          const url = process.env.REACT_APP_API_PATH + '/equipo';
          const response = await fetch(url, {
            method: "GET",
            headers: {
              token: token
            }
          })
          const res = await response.json()
          setEquipos(res);
          console.log(res.status);
        } catch (error) {
          console.error(error);
        }
      }; 
    
/*       const getPuestos = async () => {
        try {
          const url = process.env.REACT_APP_API_PATH + '/puesto';
          const response = await fetch(url, {
            method: "GET",
            headers: {
              token: token
            }
          })
          const res = await response.json()
          setPuestos(res);
          console.log(res.status);
        } catch (error) {
          console.error(error);
        }
      };     */
    
    
    React.useEffect(() => {
        getEmpleados()
        getEquipos()
    }, []);

    return(
        <div className="divCentral">
            <div style={{paddingBottom: '2%'}}>
                <h1>Generador de Documentos</h1>
            </div>

            <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ marginTop: 2 }} spacing={2} alignItems="center" justifyContent="center">
                {/* ---- Seleccion Empleado ---- */}
                <FormControl sx={{ width:'100%'}} size="small">
                    <InputLabel id="demo-simple-select-required-label" required>Empleado</InputLabel>
                    <Select
                        labelId="demo-simple-select-required-label"
                        id="demo-simple-select-required"
                        value={empleado}
                        onChange={(e) => setEmpleado(e.target.value)}
                        label = "empleado"
                    >
                        <MenuItem value=""><em>None</em></MenuItem>
                    {empleados ? empleados.map((e, index) => (
                        <MenuItem key={index} value={e.nombre}>
                        {e.nombre}
                        </MenuItem>
                    )) : <MenuItem value=""><em>None</em></MenuItem>}
                    </Select>
                </FormControl>

            </Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ marginTop: 2 }} spacing={2} alignItems="center" justifyContent="center">
            <TextField
                fullWidth
                size="small"
                label="CUIL"
                value={cuil}
                inputProps={{
                  maxLength: 11,
                }}
                required
                onChange={(e) => {
                  if (e.target.value !== "" && !NUMERIC_DOT.test(e.target.value)) {
                    return;
                  }
                  setCuil(e.target.value)
                }}
              />

            <TextField
                fullWidth
                size="small"
                label="Numero de Legajo"
                value={legajo}
                inputProps={{
                  maxLength: 10,
                }}
                required
                onChange={(e) => {
                  if (e.target.value !== "" && !ALPHA_NUMERIC_DASH_REGEX.test(e.target.value)) {
                    return;
                  }
                  setLegajo(e.target.value)
                }}
            />

            <TextField
                fullWidth
                size="small"
                label="Rol"
                value={rol}
                required
                onChange={(e) => {
                  if (e.target.value !== "" && !ALPHA_NUMERIC_DASH_REGEX.test(e.target.value)) {
                    return;
                  }
                  setRol(e.target.value)
                }}
            />
            </Stack>

            <Stack direction='row' flexWrap="wrap" alignItems="center" spacing={1} justifyContent={{xs:'center', sm:'center', md:'space-between'}} sx={{marginTop: 2}}>
                {/* ---- Seleccion Equipo ---- */}
                <FormControl sx={{ width:'100%'}} size="small">
                    <InputLabel id="demo-simple-select-required-label" required>Equipo</InputLabel>
                    <Select
                    
                        labelId="demo-simple-select-required-label"
                        id="demo-simple-select-required"
                        value={equipo}
                        onChange={(e) => setEquipo(e.target.value)}
                        label = "equipo"
                    >
                        <MenuItem value=""><em>None</em></MenuItem>
                        {equipos ? equipos.map((e, index) => (
                            <MenuItem key={index} value={e}>
                            {e.descripcion}
                            </MenuItem>
                        )) : <MenuItem value=""><em>None</em></MenuItem>}
                    </Select>
                </FormControl>
            </Stack>

            <Stack direction='row' flexWrap="wrap" alignItems="center" spacing={1} justifyContent={{xs:'center', sm:'center', md:'space-between'}} sx={{marginTop: 2}}>
            </Stack>

            <Stack direction='row' alignItems='center' spacing={1} justifyContent='center' sx={{marginTop:1, width:'100%'}}>
                <Stack direction='column' sx={{width:'50%', height:'fit-content'}} >
                  <Typography variant="h5" margin={1}>Seleccionados</Typography>
                        
                    <TreeView
                        aria-label="documentos"
                        defaultCollapseIcon={<ExpandMoreIcon />}
                        defaultExpandIcon={<ChevronRightIcon />}
                        sx={{
                            width: '90%',
                            flexGrow: 1,
                            overflowY: 'auto',
                            overflowX: 'hidden',
                            height: 200
                        }}>

                            <>
                                <TreeItem key={1} nodeId={1} label={ 'Empleado: '+ empleado}></TreeItem>
                                <TreeItem key={3} nodeId={3} label={'Equipo: '+ (equipo ? equipo.id : ' ')}>
                                {!equipo.componentes ? null : equipo.componentes.map((c, index2) => (
                                    <TreeItem key={1 + '-' + index2} nodeId={1 + '-' + index2} label={c.tipo_componente.descripcion} />
                                ))}
                                </TreeItem>
                            </>
                        </TreeView>
                </Stack>

                <Stack direction='column' spacing={3} sx={{height:'fit-content', width:'50%', flexDirection:'column'}} display={'flex'}>
                    <Button onClick={createRSE} variant="contained" sx={{backgroundColor:'#62A0C0', textTransform:'none', "&:hover": {backgroundColor: '#2C4C58',}}} style={{ flexGrow: 1}}>
                        <DownloadIcon/>
                        Documento de Responsabilidad Sobre Equipos
                    </Button>
                    <Button onClick={createCAE} variant="contained" sx={{backgroundColor:'#62A0C0', textTransform:'none', "&:hover": {backgroundColor: '#2C4C58',}}}  style={{ flexGrow: 1 }}>
                        <DownloadIcon/>
                        Documento de Control de Asignación de Equipos
                    </Button>
                </Stack>
            </Stack>

        </div>
    )
}