import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import { DialogContentText, Stack, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ComponentesContext from '../../Context/ComponenteContext';
import EditIcon from '@mui/icons-material/Edit';
import { useContext} from 'react';
import AppContext from "../../Context/AppContext";


export default function FormModificarDisco(props) {
  const [open, setOpen] = React.useState(false);
  const [mensajeError, setMensajeError] = React.useState(" ");
  const {token} = useContext(AppContext);
  const {setIsOpen} = React.useContext(ComponentesContext);

  //Data
  const [capacidad, setCapacidad] = React.useState('');
  const [tipo, setTipo] = React.useState('');
  const [encriptado, setEncriptado] = React.useState(false);
  const [equipo, setEquipo] = React.useState(null);
  const [equipos, setEquipos] = React.useState('');
  const [baja, setBaja] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsOpen(false);
  };

  //Conexión API Componente por Id
  const getComponente = async () => {
    try {
      const url = process.env.REACT_APP_API_PATH + '/componente/';
      const response = await fetch(url + props.id, {
        method: "GET",
        headers: {
          token: token
        }
      })
      const res = await response.json()
      setCapacidad(res.capacidadMemoria);
      setTipo(res.tipo_componente.idTipoComponente);
      setEncriptado(res.estaEncriptado === 1 ? true : false);
      setEquipo(res.idEquipo)
      setBaja(res.motivoBaja != null);
    } catch (error) {
      console.error(error);
    }
  };

  //Conexion API - Disco
  const handleSubmit = () => {
    if (tipo !== '' && capacidad !== '') {
      const DiscoNuevo = {
        capacidadMemoria: capacidad,
        estaEncriptado: encriptado,
        idTipoComponente: tipo,
        idEquipo: equipo,
      }
      fetch(process.env.REACT_APP_API_PATH + "/componente/" + props.id, {
        method: "PUT",
        headers: {
          token: token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(DiscoNuevo)
      })
        .then((response) => {
          if(response.ok){
            return response.json()
          }
          throw new Error('No se pudo modificar el disco.');
        })
        .then((data) => {
          alert("El disco fue modificado correctamente");
          props.setReloadData(true);
          limpiarForm()
        })
        .catch((error) => { console.log(error); alert("Error. Revise los datos ingresados.") });
      setOpen(false);
    }
    else {
      setMensajeError("Error. Debe completar todos los campos.");
    }
  }

  //Conexión API
  const getEquipos = async () => {
    try {
      const url = process.env.REACT_APP_API_PATH + '/equipo';
      const response = await fetch(url, {
        method: "GET",
        headers: {
          token: token
        }
      })
      const res = await response.json()
      setEquipos(res);
      console.log(res.status);
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    getEquipos()
    getComponente()
  }, []) 

  const limpiarForm = () => {
    setMensajeError(" ");
    setEncriptado(false);
    setCapacidad('');
    setTipo('');
    setEquipo('');
  }


  return (
    <>
      <>
        {props.texto ? (
          <Button variant="contained" disabled={baja} sx={{ backgroundColor: '#62A0C0', textTransform: 'none', "&:hover": {backgroundColor: '#2C4C58',}}} onClick={handleClickOpen}>
            {props.texto}
          </Button>)
          : (
              <EditIcon onClick={handleClickOpen} fontSize='small'/>
          )}
      </>

      <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth>
        <DialogTitle>Modificar Disco</DialogTitle>
        <DialogContent>

          {/* ---- Capacidad ---- */}
          <TextField
            sx={{ marginTop: 2 }}
            fullWidth
            label="Capacidad"
            required
            size="small"
            value={capacidad}
            onChange={(e) => setCapacidad(e.target.value)}
          />

          {/* ---- Tipo Disco ---- */}
          <FormControl sx={{ marginTop: 2, minWidth: 140, ml: 0 }} size="small" fullWidth>
            <InputLabel id="demo-select-small-label">Tipo Disco</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={tipo}
              required
              label="Tipo Disco"
              onChange={(e) => setTipo(e.target.value)}
            >
              <MenuItem><em>None</em></MenuItem>
              <MenuItem value={5} key={5}>SSD</MenuItem>
              <MenuItem value={6} key={6}>HDD</MenuItem>
              <MenuItem value={8} key={8}>NVMe-M.2</MenuItem>
            </Select>
          </FormControl>

          {/* ---- Esta Encriptado ---- */}
          <FormControlLabel
            sx={{ marginTop: 2 }}
            label="Encriptado"
            control={<Checkbox checked={encriptado} onChange={(e) => setEncriptado(e.target.checked)}></Checkbox>}
          />

          {/* ---- Equipo ---- */}
          <FormControl size='small' sx={{ width: '100%', marginTop: 2 }}>
            <InputLabel id="demo-select-small-label">Equipo</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={equipo}
              label="Equipo"
              onChange={(e) => setEquipo(e.target.value)}
            >
              <MenuItem value=''><em>None</em></MenuItem>
              {equipos !== '' ? equipos.map((e, index) => (
                <MenuItem key={index} value={e.id}>
                  {e.id}
                </MenuItem>
              )) : <MenuItem value=''><em>None</em></MenuItem>}
            </Select>
          </FormControl>

          <DialogContentText color="error">{mensajeError}</DialogContentText>

        </DialogContent>

        <DialogActions>
          <Stack spacing={1} width={'100%'} margin={1}>
            <Button type="submit" onClick={handleSubmit} variant="contained" sx={{ backgroundColor: '#62A0C0', textTransform: 'none', "&:hover": {backgroundColor: '#2C4C58',}}}>Registrar</Button>
            <Button onClick={handleClose} variant="contained" sx={{ backgroundColor: '#BABABA', textTransform: 'none', "&:hover": {backgroundColor: '#2C4C58',}}}>Cancelar</Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
}