import React from "react";
import { Stack } from '@mui/material';
import Fila from './Fila';
import Maceta from "./Maceta";

export function PlantillaPuestos5(props) {
    return (
        <Stack direction='column' alignItems='center'>
            <Fila color={props.color} puestos={[props.puestos[0], props.puestos[9]]} sx={{ maxWidth: '100%' }} />
            <Fila color={props.color} puestos={[props.puestos[1], props.puestos[8]]} sx={{ maxWidth: '100%' }}/>
            <Fila color={props.color} puestos={[props.puestos[2], props.puestos[7]]} sx={{ maxWidth: '100%' }}/>
            <Fila color={props.color} puestos={[props.puestos[3], props.puestos[6]]} sx={{ maxWidth: '100%' }}/>
            <Fila color={props.color} puestos={[props.puestos[4], props.puestos[5]]} sx={{ maxWidth: '100%' }}/>
            <Maceta planta={1}/>
        </Stack>
    )
}