import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AppContext from '../../Context/AppContext';
import { useContext } from 'react';
import AddIcon from '@mui/icons-material/Add';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Stack
} from '@mui/material';

const FormModificarEquipo = (props) => {
  const {token} = useContext(AppContext);

  //Constantes para validaciones
  const ALPHA_NUMERIC_DASH_REGEX = /^[A-Za-zÀ-ÿ-Z0-9- ]+$/;
  const ALPHA_NUMERIC_DOT = /^[a-zA-Z0-9.]*$/;
  const NUMERIC_DOT = /^[Z0-9.]*$/;
  const ALPHA_NUMERIC_MAC = /^[a-zA-Z0-9.:]*$/;
  const ALPHA_NUMERIC_NS = /^[a-zA-Z0-9./-]*$/;
  
  //Colecciones
  const [perifericos, setPerifericos] = useState([]);
  const [monitores, setMonitores] = useState([]);
  const [discos, setDiscos] = useState([]);
  const [memorias, setMemorias] = useState([]);

  //Datos
  const [tiposEquipo, setTiposEquipo] = React.useState('');
  const [dominios, setDominios] = React.useState('');
  const [descripcion, setDescripcion] = React.useState('');
  const [tipoEquipo, setTipoEquipo] = useState('');
  const [dominio, setDominio] = useState('');
  const [numeroMac, setNumeroMac] = useState('');
  const [ip, setIp] = useState('');
  const [vpn, setVpn] = useState('');
  const [numSerieCPU, setNumSerieCPU] = useState('');
  const [descCPU, setDescCPU] = useState('');
  const [idCPU, setIdCPU] = useState('');
  const [placaMadre, setPlacaMadre] = useState('');
  const [remotoActualizado, setRemotoActualizado] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);

  //Conexión API Tipos Equipo
  const getTiposEquipo = async () => {
    try {
      const url = process.env.REACT_APP_API_PATH + '/tipoEquipo';
      const response = await fetch(url, {
        method: "GET",
        headers: {
          token: token
        }
      })
      const res = await response.json()
      setTiposEquipo(res);
    } catch (error) {
      console.error(error);
    }
  };

  //Conexión API Dominios
  const getDominios = async () => {
    try {
      const url = process.env.REACT_APP_API_PATH + '/dominio';
      const response = await fetch(url, {
        method: "GET",
        headers: {
          token: token
        }
      })
      const res = await response.json()
      setDominios(res);
    } catch (error) {
      console.error(error);
    }
  };

  const guardarDatos = (res) => {
      setNumSerieCPU(res.cpu ? res.cpu.numSerie : '')
      setDescCPU(res.cpu ? res.cpu.descripcion : '')
      setIdCPU(res.cpu ? res.cpu.idCPU : '')
      setTipoEquipo(res.info.equipo.idTipoEquipo);
      setDominio(res.info.equipo.idDominio);
      setNumeroMac(res.info.equipo.mac);
      setIp(res.info.equipo.ipEquipo);
      setVpn(res.info.equipo.ipVPN);
      setPerifericos(res.componentes.filter((c) => c.tipo_componente.idTipoComponente < 4));
      setMemorias(res.componentes.filter((c) => c.tipo_componente.idTipoComponente === 4));
      setPlacaMadre(res.info.equipo.placaMadre);
      setDescripcion(res.info.equipo.descripcion);
      setMonitores(res.componentes.filter((c) => c.tipo_componente.idTipoComponente === 7));
      setDiscos(res.componentes.filter((c) => c.tipo_componente.idTipoComponente === 5 || c.tipo_componente.idTipoComponente === 6 || c.tipo_componente.idTipoComponente === 8));
      setRemotoActualizado(res.info.equipo.remotoActualizado === 1 ? true : false);
  }


  //Conexión API Equipos por Id
  const getEquipo = async () => {
    try {
      const url = process.env.REACT_APP_API_PATH + '/equipo/';
      const response = await fetch(url + props.equipoId, {
        method: "GET",
        headers: {
          token: token
        }
      })
      const res = await response.json()
      guardarDatos(res);
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    getTiposEquipo();
    getDominios();
    getEquipo();
  }, [])

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleAddPeriferico = () => {
    setPerifericos([...perifericos, { idTipoComponente: null, esPropio: true, idComponente: null, numSerie: null, marca: null, modelo: null, capacidadMemoria: null, descripcion: null, estaEncriptado: null, idEstado: 1 }]);
  };

  const handleAddMonitor = () => {
    setMonitores([...monitores, { numSerie: null, marca: null, modelo: null, idTipoComponente: 7, esPropio: false, idComponente: null, capacidadMemoria: null, descripcion: null, estaEncriptado: null, idEstado: 1 }]);
  };

  const handleAddDisco = () => {
    setDiscos([...discos, { idTipoComponente: null, capacidadMemoria: null, estaEncriptado: false, esPropio: false, idComponente: null, numSerie: null, marca: null, modelo: null, descripcion: null, idEstado: 1 }]);
  };

  const handleAddMemoria = () => {
    setMemorias([...memorias, { descripcion: null, idTipoComponente: 4, esPropio: false, idComponente: null, numSerie: null, marca: null, modelo: null, capacidadMemoria: null, descripcion: null, estaEncriptado: null, idEstado: 1 }]);
  };

  const handlePerifericoChange = (index, key, value) => {
    const updatedPerifericos = [...perifericos];
    updatedPerifericos[index][key] = value;
    setPerifericos(updatedPerifericos);
  };

  const handleMonitorChange = (index, key, value) => {
    const updatedMonitores = [...monitores];
    updatedMonitores[index][key] = value;
    setMonitores(updatedMonitores);
  };

  const handleDiscoChange = (index, key, value) => {
    const updatedDiscos = [...discos];
    updatedDiscos[index][key] = value;
    setDiscos(updatedDiscos);
  };

  const handleMemoriaChange = (index, key, value) => {
    const updatedMemorias = [...memorias];
    updatedMemorias[index][key] = value;
    setMemorias(updatedMemorias);
  };

  const handleDeletePeriferico = (index) => {
    const updatedPerifericos = [...perifericos];
    updatedPerifericos.splice(index, 1);
    setPerifericos(updatedPerifericos);
  };

  const handleDeleteMonitor = (index) => {
    const updatedMonitores = [...monitores];
    updatedMonitores.splice(index, 1);
    setMonitores(updatedMonitores);
  };

  const handleDeleteDisco = (index) => {
    const updatedDiscos = [...discos];
    updatedDiscos.splice(index, 1);
    setDiscos(updatedDiscos);
  };

  const handleDeleteMemoria = (index) => {
    const updatedMemorias = [...memorias];
    updatedMemorias.splice(index, 1);
    setMemorias(updatedMemorias);
  };

  //Conexión API - Equipo
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (tipoEquipo !== '' && dominio !== '' && numeroMac !== '' && ip !== '' && descCPU !== '' && numSerieCPU !== '') {
        const todosComponentes = [...perifericos, ...monitores, ...discos, ...memorias];
      const formData = {
        idEquipo: props.equipoId,
        descripcion: descripcion,
        idTipoEquipo: tipoEquipo,
        idDominio: dominio,
        mac: numeroMac,
        ipEquipo: ip,
        ipVPN: vpn,
        placaMadre: placaMadre,
        CPU: {
          idCPU: idCPU,
          descripcion: descCPU,
          numSerie: numSerieCPU,
        },
        componentes: todosComponentes,
      };
      fetch(process.env.REACT_APP_API_PATH + "/equipo/" + props.equipoId, {
        method: "PUT",
        headers: {
          token: token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(formData)
      })
        .then((response) => {
          if(response.ok){
            return response.json()
          }
          throw new Error('No se pudo modificar el equipo');
        })
        .then((data) => {
          alert("El equipo fue modificado correctamente");
          if(props.setDescripcion){
            props.setDescripcion(descripcion);
          }
          props.setReloadData(true);
        })
        .catch((error) => { console.log(error); alert("Error. Revise los datos ingresados.") });
      handleCloseDialog();
    }
    else {
      alert("Debe completar todos los campos");
    }
  };



  return (
    <>
      <>
        {props.texto ? (
          <Button variant="contained" sx={{ backgroundColor: '#62A0C0', textTransform: 'none', "&:hover": {backgroundColor: '#2C4C58',}}} onClick={handleOpenDialog}>
            {props.texto}
          </Button>)
          : (
              <EditIcon onClick={handleOpenDialog} fontSize='small'/>
          )}
      </>

      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md">
        <DialogTitle>Modificar Equipo</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>

            <Typography variant="h6">• Información</Typography>
            <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ marginTop: 2 }} spacing={2} alignItems="center" justifyContent="center">
              <TextField
                    fullWidth
                    size="small"
                    label="Descripcion"
                    value={descripcion}
                    onChange={(e) => {
                      if (e.target.value !== "" && !ALPHA_NUMERIC_DASH_REGEX.test(e.target.value)) {
                        return;
                      }
                      setDescripcion(e.target.value);
                    }}
                  />
              
              {/* Tipo Equipo */}
              <FormControl size='small' sx={{ width: '100%', marginTop: 2 }}>
                  <InputLabel id="demo-select-small-label">Tipo Equipo</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={tipoEquipo}
                    label="Tipo Equipo"
                    onChange={(e) => setTipoEquipo(e.target.value)}
                  >
                    <MenuItem value=""><em>None</em></MenuItem>
                    {tiposEquipo !== '' ? tiposEquipo.map((e, index) => (
                      <MenuItem key={e.idTipoEquipo} value={e.idTipoEquipo}>
                        {e.descripcion}
                      </MenuItem>
                    )) : <MenuItem value=""><em>None</em></MenuItem>}
                  </Select>
                </FormControl>
            </Stack>
            

            {/* Dominio */}
            <FormControl size='small' sx={{ width: '100%', marginTop: 2 }}>
              <InputLabel id="demo-select-small-label">Dominio</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={dominio}
                label="Dominio"
                onChange={(e) => setDominio(e.target.value)}
              >
                <MenuItem value=""><em>None</em></MenuItem>
                {dominios !== '' ? dominios.map((e, index) => (
                  <MenuItem key={index} value={e.idDominio}>
                    {e.descripcion}
                  </MenuItem>
                )) : <MenuItem value=""><em>None</em></MenuItem>}
              </Select>
            </FormControl>

            {/* MAC, IP, IPVPN*/}
            <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ marginTop: 2 }} spacing={2} alignItems="center" justifyContent="center">
              <TextField
                fullWidth
                size="small"
                label="Número de MAC"
                value={numeroMac}
                onChange={(e) => {
                  if (e.target.value !== "" && !ALPHA_NUMERIC_MAC.test(e.target.value)) {
                    return;
                  }
                  setNumeroMac(e.target.value)
                }}
              />

              <TextField
                fullWidth
                size="small"
                label="IP"
                value={ip}
                onChange={(e) => {
                  if (e.target.value !== "" && !NUMERIC_DOT.test(e.target.value)) {
                    return;
                  }
                  setIp(e.target.value);
                }}
              />

              <TextField
                fullWidth
                size="small"
                label="IP VPN"
                value={vpn}
                onChange={(e) => {
                  if (e.target.value !== "" && !NUMERIC_DOT.test(e.target.value)) {
                    return;
                  }
                  setVpn(e.target.value);
                }}
              />
            </Stack>

            <TextField
              fullWidth
              size="small"
              label="Placa Madre"
              value={placaMadre}
              onChange={(e) => {
                if (e.target.value !== "" && !ALPHA_NUMERIC_NS.test(e.target.value)) {
                  return;
                }
                setPlacaMadre(e.target.value);
              }}
              margin="normal"
            />

            <FormControlLabel
              label="Remoto Actualizado"
              control={<Checkbox checked={remotoActualizado} onChange={(e) => setRemotoActualizado(e.target.checked)} color='default' />}
            />

            <Typography variant="h6" sx={{ marginTop: 2, marginBottom: 2 }}>• CPU</Typography>
            <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ marginTop: 2 }} spacing={2} alignItems="center" justifyContent="center">
              <TextField
                fullWidth
                label="Número de serie"
                value={numSerieCPU}
                onChange={(e) => {
                  if (e.target.value !== "" && !ALPHA_NUMERIC_NS.test(e.target.value)) {
                    return;
                  }
                  setNumSerieCPU(e.target.value);
                }}
                size="small"
              />

              <TextField
                fullWidth
                label="Descripción"
                value={descCPU}
                onChange={(e) => {
                  if (e.target.value !== "" && !ALPHA_NUMERIC_DASH_REGEX.test(e.target.value)) {
                    return;
                  }
                  setDescCPU(e.target.value);
                }}
                size="small"
              />
            </Stack>

            {/* ---- Agregar perifericos ---- */}
            <Stack direction='row' sx={{ marginTop: 2, marginBottom: 2 }} alignItems={'center'} spacing={2}>
              <Typography variant="h6">• Periféricos</Typography>
              <IconButton sx={{ height: 'fit-content', width: 'fit-content' }} onClick={handleAddPeriferico}>
                <AddIcon />
              </IconButton>
            </Stack >
            {perifericos.map((componente, index) => (
              <div key={index} className="DivMonitor">
                <Stack direction='row' spacing={1} alignItems={'center'} fullWidth>
                  <Typography variant='p'>Periférico {index + 1}</Typography>
                  <IconButton sx={{ height: 'fit-content', width: 'fit-content' }} onClick={(e) => { e.preventDefault(); handleDeletePeriferico(index) }}>
                    <DeleteOutlineIcon />
                  </IconButton>
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ marginTop: 1 }} spacing={1} alignItems="center" justifyContent="center">

                  <FormControl sx={{ m: 1, minWidth: 200, ml: 0 }} size="small" fullWidth>
                    <InputLabel id="demo-select-small-label">Tipo Periférico</InputLabel>
                    <Select
                      fullWidth
                      labelId="demo-select-small-label"
                      value={componente.idTipoComponente}
                      label="Tipo Componente"
                      onChange={(e) =>
                        handlePerifericoChange(index, 'idTipoComponente', e.target.value)}
                    >
                      <MenuItem>
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={1} key={1}>Auriculares</MenuItem>
                      <MenuItem value={2} key={2}>Mouse</MenuItem>
                      <MenuItem value={3} key={3}>Teclado</MenuItem>
                    </Select>
                  </FormControl>


                  <FormControl sx={{ m: 1, minWidth: 200, ml: 0 }} size="small" fullWidth>
                    <InputLabel id="demo-select-small-label">Propiedad</InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      value={componente.esPropio}
                      label="Propiedad"
                      onChange={(e) => handlePerifericoChange(index, 'esPropio', e.target.value)}
                    >
                      <MenuItem>
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={true} key={0}>Propio</MenuItem>
                      <MenuItem value={false} key={1}>Empresa</MenuItem>
                    </Select>
                  </FormControl>

                  <TextField
                    fullWidth
                    label="Identificador"
                    type='number'
                    value={componente.idComponente}
                    onChange={(e) =>
                      {
                        handlePerifericoChange(index, 'idComponente', e.target.value);
                        handlePerifericoChange(index, 'esPropio', false);
                      }
                    }
                    size="small"
                  />
                </Stack>

              </div>
            ))}

            {/* --- Agregar monitores --- */}
            <Stack direction='row' sx={{ marginTop: 2, marginBottom: 2 }} alignItems={'center'} spacing={2}>
              <Typography variant="h6">• Monitores</Typography>
              <IconButton sx={{ height: 'fit-content', width: 'fit-content' }} onClick={handleAddMonitor}>
                <AddIcon />
              </IconButton>
            </Stack>
            {monitores.map((monitor, index) => (
              <div key={index} className="DivMonitor">
                <Stack direction='row' spacing={1} alignItems={'center'} fullWidth>
                  <Typography variant='p'>Monitor {index + 1}</Typography>
                  <IconButton sx={{ height: 'fit-content', width: 'fit-content' }} onClick={(e) => { e.preventDefault(); handleDeleteMonitor(index) }}>
                    <DeleteOutlineIcon />
                  </IconButton>
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ marginTop: 1 }} spacing={2} alignItems="center" justifyContent="center" fullWidth>
                  <TextField
                    fullWidth
                    label="Número de Serie"
                    size="small"
                    value={monitor.numSerie}
                    onChange={(e) => {
                      if (e.target.value !== "" && !ALPHA_NUMERIC_NS.test(e.target.value)) {
                        return;
                      }
                      handleMonitorChange(index, 'numSerie', e.target.value)
                    }
                    }
                  />
                  <TextField
                    fullWidth
                    label="Marca"
                    size="small"
                    value={monitor.marca}

                    onChange={(e) => {
                      if (e.target.value !== "" && !ALPHA_NUMERIC_DASH_REGEX.test(e.target.value)) {
                        return;
                      }
                      handleMonitorChange(index, 'marca', e.target.value)
                    }}
                  />

                  <TextField
                    fullWidth
                    label="Modelo"
                    size="small"
                    value={monitor.modelo}
                    onChange={(e) => {
                      if (e.target.value !== "" && !ALPHA_NUMERIC_DASH_REGEX.test(e.target.value)) {
                        return;
                      }
                      handleMonitorChange(index, 'modelo', e.target.value)
                    }}
                  />
                </Stack>

              </div>
            ))}

            {/* Agregar discos */}
            <Stack direction='row' sx={{ marginTop: 2, marginBottom: 2 }} alignItems={'center'} spacing={2}>
              <Typography variant="h6">• Discos</Typography>
              <IconButton sx={{ height: 'fit-content', width: 'fit-content' }} onClick={handleAddDisco}>
                <AddIcon />
              </IconButton>
            </Stack>
            {discos.map((disco, index) => (
              <div key={index} className="DivMonitor">
                <Stack direction='row' spacing={1} alignItems={'center'} fullWidth>
                  <Typography variant='p'>Disco {index + 1}</Typography>
                  <IconButton sx={{ height: 'fit-content', width: 'fit-content' }} onClick={(e) => { e.preventDefault(); handleDeleteDisco(index) }}>
                    <DeleteOutlineIcon />
                  </IconButton>
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ marginTop: 1 }} spacing={2} alignItems="center" justifyContent="center" fullWidth>
                  <TextField
                    fullWidth
                    label="Capacidad"
                    size="small"
                    value={disco.capacidadMemoria}
                    onChange={(e) => {
                      if (e.target.value !== "" && !ALPHA_NUMERIC_DOT.test(e.target.value)) {
                        return;
                      }
                      handleDiscoChange(index, 'capacidadMemoria', e.target.value)
                    }
                    }
                  />

                  <FormControl sx={{ m: 1, minWidth: 140, ml: 0 }} size="small" fullWidth>
                    <InputLabel id="demo-select-small-label">Tipo Disco</InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={disco.idTipoComponente}
                      required
                      label="Tipo Disco"
                      onChange={(e) => handleDiscoChange(index, 'idTipoComponente', e.target.value)}
                    >
                      <MenuItem><em>None</em></MenuItem>
                      <MenuItem value={5} key={5}>SSD</MenuItem>
                      <MenuItem value={6} key={6}>HDD</MenuItem>
                      <MenuItem value={8} key={8}>NVMe-M.2</MenuItem>
                    </Select>
                  </FormControl>

                  <FormControlLabel
                    label="Encriptado"
                    control={<Checkbox checked={(disco.estaEncriptado === 1 || disco.estaEncriptado === true) ? true : false} onChange={(e) => handleDiscoChange(index, 'estaEncriptado', e.target.checked)} color='default' />}
                  />
                </Stack>

              </div>
            ))}

            {/* Agregar RAM */}
            <Stack direction='row' sx={{ marginTop: 2, marginBottom: 2 }} alignItems={'center'} spacing={2}>
              <Typography variant="h6">• Memoria RAM</Typography>
              <IconButton sx={{ height: 'fit-content', width: 'fit-content' }} onClick={handleAddMemoria}>
                <AddIcon />
              </IconButton>
            </Stack>
            {memorias.map((memoria, index) => (
              <div key={index} className="DivMonitor">
                <Stack direction='row' spacing={1} alignItems={'center'} fullWidth>
                  <Typography variant='p'>Memoria {index + 1}</Typography>
                  <IconButton sx={{ height: 'fit-content', width: 'fit-content' }} onClick={(e) => { e.preventDefault(); handleDeleteMemoria(index) }}>
                    <DeleteOutlineIcon />
                  </IconButton>
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ marginTop: 1 }} spacing={2} alignItems="center" justifyContent="center" fullWidth>
                  <TextField
                    fullWidth
                    label="Capacidad"
                    size="small"
                    value={memoria.capacidadMemoria}
                    onChange={(e) => {
                      if (e.target.value !== "" && !ALPHA_NUMERIC_DOT.test(e.target.value)) {
                        return;
                      }
                      handleMemoriaChange(index, 'capacidadMemoria', e.target.value)
                    }
                    }
                  />

                  <TextField
                    fullWidth
                    label="Descripcion"
                    size="small"
                    value={memoria.descripcion}
                    onChange={(e) => {
                      if (e.target.value !== "" && !ALPHA_NUMERIC_DASH_REGEX.test(e.target.value)) {
                        return;
                      }
                      handleMemoriaChange(index, 'descripcion', e.target.value)
                    }}
                  />
                </Stack>
              </div>
            ))}
          </form>
        </DialogContent>
        <DialogActions>
          <Stack spacing={1} width={'100%'} margin={1}>
            <Button type="submit" onClick={handleSubmit} variant="contained" sx={{ backgroundColor: '#62A0C0', textTransform: 'none', "&:hover": {backgroundColor: '#2C4C58',}}}>Registrar</Button>
            <Button onClick={handleCloseDialog} variant="contained" sx={{ backgroundColor: '#BABABA', textTransform: 'none', "&:hover": {backgroundColor: '#2C4C58',}}}>Cancelar</Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FormModificarEquipo;
