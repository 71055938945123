import React from "react";
import { useState } from "react";
import { DataGrid, GridActionsCellItem, useGridApiRef, esES} from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import SearchIcon from '@mui/icons-material/Search';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useContext} from 'react';
import AppContext from "../Context/AppContext";
import PdfViewerModal from "../PDFViewer";
import FirmarDocumento from "../Usuario/FirmarDocumento";
import ReferenciasFirma from "../ReferenciasFirma";
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CustomNoRowsOverlay from "./CustomNoRowsOverlay";

import PopOverFirma from "../PopOverFirma";

import Notificacion from "../Formularios/RRHH/Notificacion";

var today = new Date();

const options = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric'
};

const DownloadPDFButton = ({ data }) => {
  const handleDownloadPDF = () => {
    const doc = new jsPDF();
    doc.text("Documentos", 10, 10)
    const tableData = data.map((row) => [
      row.id,
      row.fecha,
      row.tipo,
      row.descEstado,
    ]);

    doc.autoTable({
      head: [['Id', 'Fecha', 'Tipo de Documento', 'Estado']],
      body: tableData,
    });

    doc.setFontSize(12);
    var now = today.toLocaleString();
    doc.text(now, 40, 10)
    doc.save('documentos.pdf');
  };

  return (
    <Button variant="contained" onClick={handleDownloadPDF} sx={{ backgroundColor: '#62A0C0', textTransform: 'none', "&:hover": {backgroundColor: '#2C4C58',}}}>
      Descargar
    </Button>
  );
};


//Setear el color de los chips de estado
function colorChip(prop){
  switch(prop){
    case 'Anulado': 
      return 'error';
    case 'Pendiente de Firma ADMIN' || 'Pendiente de Firma RRHH' || 'Pendiente de Firma Empleado':
      return 'secondary';
    case 'Firmado ADMIN' || 'Firmado RRHH' || 'Leido Sin Firmar Empleado':
      return 'info';
    case 'Firmado con Conformidad Empleado':
      return 'success';
    case 'Firmado con Protesto Empleado':
      return 'warning';
    default:
      return 'info';
  }
}

//Setear el icono de los chips de estado
function iconoChip(prop){
  switch(prop){
    case 'Anulado': 
      return <CloseIcon fontSize="small"/>;
    case 'Pendiente de Firma ADMIN' || "Pendiente de Firma RRHH" || "Pendiente de Firma Empleado":
      return <CheckIcon fontSize="small"/>;
    case 'Leido Sin Firmar Empleado':
      return <DoneAllIcon fontSize="small"/>;
    case 'Firmado con Conformidad Empleado' || "Firmado RRHH" || "Firmado ADMIN":
      return <TaskAltIcon fontSize="small"/>;
    case 'Firmado con Protesto Empleado':
      return  <ErrorOutlineIcon fontSize="small" />;
    default:
      return <CloseIcon fontSize="small"/>;
  }
}

//<Button variant="contained" sx={{ backgroundColor: '#62A0C0', textTransform: 'none' }} disabled={params.row.tipo === "firmado" ? true : false}>Firmar Documento</Button>

export default function ConsultaDocumentosRRHH(){
  const [openPdfViewer, setOpenPdfViewer] = useState(false);
  const [selectedPdf, setSelectedPdf] = useState(null);

  const [reloadData, setReloadData] = React.useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [pageSize, setPageSize] = useState(5);
  const [documentos, setDocumentos] = useState([]);
  const [filters, setFilters] = useState({
    estado: '',
    tipo: '',
    busqueda: '',
  });

  const handleRowClick = (params) => {
    setOpenPdfViewer(true);
  };

  const handleClosePdfViewer = () => {
    setOpenPdfViewer(false);
  };

  const columns = [
    {
      field: 'equipo', headerName: 'Equipo', width: 'flex', flex: 0.7, minWidth: 100,
    },
    { field: 'empleado', headerName: 'Empleado', width: 'flex', flex: 1.2, minWidth: 150, },
    { field: 'tipo', headerName: 'Tipo', width: 'flex', flex: 0.5, minWidth: 80,
    renderCell: (params) => {
      return(<Chip label={params.value} variant={"outlined"} size="small" color={params.value === "CAE" ? "primary" : "secondary"}></Chip>)
    } },
    { field: 'fecha', headerName: 'Fecha', width: 'flex', flex: 0.7, minWidth: 100, },
    { field: 'firmaADMIN', headerName: 'Admin', width: 'flex', flex: 0.5, minWidth: 80,
      renderCell: (params) => {
        return(<PopOverFirma estado={params.value}/>)
      }},
      { field: 'firmaRRHH', headerName: 'RRHH', width: 'flex', flex: 0.5, minWidth: 80,
      renderCell: (params) => {
        return(<PopOverFirma estado={params.value}/>)
      }},
      { field: 'firmaEmpleado', headerName: 'Empleado', width: 'flex', flex: 0.5, minWidth: 80,
      renderCell: (params) => {
        return(<PopOverFirma estado={params.value}/>)
      }},
    {
      field: 'actions',
      type: 'actions',
      width: 'flex',
      flex: 0.9,
      minWidth: 160,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Notificacion empleado={params.row.idEmpleado} firmadoEmpleado={params.row.firmadoEmpleado}/>}
          label="Notificar"
        />,
        <GridActionsCellItem
          icon={<PdfViewerModal open={openPdfViewer} onClose={handleClosePdfViewer} pdfDocument={params.row.documento} tipo={params.row.tipo} rol={2}/>}
          label="Guardar"
        />,
        <GridActionsCellItem
          icon={<FirmarDocumento rol={params.row.idEmpleado} firmado={params.row.firmado} documento={params.row.id} setReloadData={setReloadData}/>}
          label="Firmar"
        />,
      ],
    },
  ];

  //Configuración de subcolumnas de firma
  const columnGroupingModel = [
    {
      groupId: 'estados',
      headerName: 'Estado de Firma',
      height:26,
      description: '',
      children: [{ field: 'firmaADMIN'}, { field: 'firmaRRHH'}, { field: 'firmaEmpleado'}],
    },
  ];


  //Filtrado de datos según selección
  const filteredData = ConvertirObjeto(documentos).filter(item => {
    return (
      (filters.tipo === '' || item.tipo=== filters.tipo) &&
      (filters.estado === '' || item.firmaRRHH === filters.estado) &&
      (filters.busqueda === '' ||
        item.id.toString().includes(filters.busqueda) ||
        item.tipo.includes(filters.busqueda) ||
        item.firmaRRHH.includes(filters.busqueda) ||
        item.firmaADMIN.includes(filters.busqueda) ||
        item.firmaEmpleado.includes(filters.busqueda) ||
        item.empleado.toLowerCase().includes(filters.busqueda.toLowerCase()) ||
        item.fecha.includes(filters.busqueda))
    );
  });

  const apiRef = useGridApiRef();

  const { token } = useContext(AppContext);
  //Conexión API - Mis Documentos
  const getDocumentos = async () => {
    try {
      const url = process.env.REACT_APP_API_PATH + '/documento' + (reloadData ? `?timestamp=${Date.now()}` : '');
      const response = await fetch(url, {
        method: "GET",
        headers: {
          token: token
        },
      });
      const res = await response.json();
      setDocumentos(res);
      setReloadData(false);
      console.log(res.status);
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    getDocumentos();
  }, [reloadData]);

  const getDocumento = async (documento) => {
    try {
      const url = process.env.REACT_APP_API_PATH + '/documento/nombre/' + documento;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          token: token
        },
      });
      const res = await response.json();
      console.log(res.status);
      return(res)
    } catch (error) {
      console.error(error);
    }
  };

  const rows = documentos != null ? ConvertirObjeto(documentos) : { id: 0, equipo: 'uwu', componentes: ['Mouse', 'Teclado', 'Monitor', 'Auriculares'] };

  //Filas Seleccionadas
  const onRowsSelectionHandler = (ids) => {
    const selectedRowsData = ids.map((id) => rows.find((row) => row.id === id));
    setSelectedRows(selectedRowsData)
  };

  return (
    <div className="divCentral">
      <Stack direction='row' useFlexGap flexWrap="wrap" alignItems="center" justifyContent="space-between" paddingBottom={'2%'}>
        <h1>Documentos</h1>
      </Stack>


      <Stack direction='row' useFlexGap flexWrap="wrap" alignItems="center" spacing={'10%'} justifyContent={{sx:"center", sm:"space-between"}}>
        {/* Filtro por Tipo de Documento */}
        <FormControl sx={{ m: 1, minWidth:{xs:'45%', sm:300}, mr:0, ml:0 }} size="small">
          <InputLabel id="demo-select-small-label">Tipo de Documento</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={filters.tipo}
            onChange={e => setFilters({ ...filters, tipo: e.target.value })}
            label="Tipo de Documento"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={"CAE"}>Control de Asignación de Equipo</MenuItem>
            <MenuItem value={"RSE"}>Responsabilidad Sobre el Equipo</MenuItem>
          </Select>
        </FormControl>

        {/* Filtro por Estado */}
        <FormControl sx={{ m: 1, minWidth:{xs:'45%', sm:220}, mr:0, ml:0 }} size="small">
          <InputLabel id="demo-select-small-label">Estado</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={filters.estado}
            onChange={e => setFilters({ ...filters, estado: e.target.value })}
            label="Estado"
          >
            <MenuItem value=''>
              <em>None</em>
            </MenuItem>
            <MenuItem value={'Anulado'}>Anulado</MenuItem>
            <MenuItem value={'Pendiente'}>Pendiente</MenuItem>
            <MenuItem value={'Firmado con conformidad'}>Firmado con conformidad</MenuItem>
            <MenuItem value={'Firmado con protesto'}>Firmado con protesto</MenuItem>
            <MenuItem value={'Leido sin firmar'}>Leido sin firmar</MenuItem>
          </Select>
        </FormControl>


        {/* Busqueda */}
        <FormControl sx={{ m: 1, width:{xs:'100%', sm:'25ch'}, mr:0, ml:0}} variant="outlined" size="small">
          <InputLabel htmlFor="outlined-adornment-password">Buscar...</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            value={filters.busqueda}
            onChange={e => setFilters({ ...filters, busqueda: e.target.value })}
            endAdornment={
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            }
            label="Buscar"
          />
        </FormControl>
      </Stack>

      <Box
        sx={{
          height: 400,
          width: '100%',
          paddingTop: '1%'
        }}>
        <DataGrid
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          rows={documentos ? filteredData : null}
          experimentalFeatures={{ columnGrouping: true }}
          columnGroupingModel={columnGroupingModel}
          apiRef={apiRef}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[5, 10, 20]}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          onRowClick={handleRowClick}
          pagination
          checkboxSelection
          columnHeaderHeight={30}
          disableColumnFilter
          disableDensitySelector
          disableColumnSelector
          headerClassName="headerCeleste"
          onRowSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
          selectionModel={selectedRows}
          slots={{
            noRowsOverlay: CustomNoRowsOverlay,
          }}
        />
      </Box>
      <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="flex-start" justifyContent="flex-start" sx={{ width: '100%', paddingTop: '1%' }}>
        <ReferenciasFirma/>
        <DownloadPDFButton data={selectedRows.length !== 0 ? selectedRows : rows} />
      </Stack>

    </div>
  )
}

function ConvertirObjeto(documentosJson) {
  const { token } = useContext(AppContext);

  const dataProcesada = [];
  documentosJson.map((e, index) => {
    dataProcesada.push({
      id: e.idDocumento,
      firmado: e.firmas.firmaRRHH === 'Pendiente',
      firmadoEmpleado: e.firmas.firmaEmpleado === 'Pendiente', 
      equipo: e.descripcion,
      empleado: e.empleado,
      documento: e.documento,
      fecha: new Date(e.fechaDoc).toLocaleDateString('es-ES', options),
      tipo: e.idTipo === 1 ? "CAE" : "RSE",
      estado: e.firmas.firmaADMIN.idEstadoFirma,
      firmaADMIN: e.firmas.firmaADMIN,
      firmaRRHH: e.firmas.firmaRRHH,
      firmaEmpleado: e.firmas.firmaEmpleado

    })
  });

  return dataProcesada;
}  