import React, { useContext, useEffect} from "react";
import AppContext from "../../Context/AppContext";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from "@mui/material";
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';

export default function Notificacion(props) {
  const [open, setOpen] = React.useState(false);
  const {token} = useContext(AppContext);

  //Conexión API
  const enviarNotificacion = () => {
    fetch(process.env.REACT_APP_API_PATH + "/mail/" + props.empleado, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: token,
      }
    })
    .then((response) => response.json())
    .then((data) => {console.log(data);})
    .catch((error) => {console.log(error);
      alert('Error. No se pudo enviar la notificación al empleado.')
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
    enviarNotificacion(); 
  };

  const handleCancel = (event) => {
    event.preventDefault(); 
    setOpen(false);
  };

  return (
    <>
      {/* Botón De Notificación */}
        <>{
          props.firmadoEmpleado ? <NotificationsOutlinedIcon onClick={handleClickOpen} fontSize="small"/> : <NotificationsOutlinedIcon  fontSize="small" color="disabled"/>
        }
        </>

      {/* Mensaje */}
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
            {"Empleado Notificado"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography>Se le envió una notificación al empleado para que firme el documento a la brevedad.</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} variant="outlined" sx={{color:'#62A0C0', borderColor:'#62A0C0', "&:hover": {backgroundColor: '#2C4C58',}}} fullWidth>Aceptar</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
