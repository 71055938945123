import React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { Popover } from "@mui/material";
import Typography from '@mui/material/Typography';

//Iconos Usuario 
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import TaskAltIcon from '@mui/icons-material/TaskAlt';


function iconoChip(prop){
  switch(prop){
    case 'Anulado': 
      return <CloseIcon fontSize="small"/>;
    case 'Pendiente':
      return <CheckIcon fontSize="small"/>;
    case 'Leido sin firmar':
      return <DoneAllIcon fontSize="small"/>;
    case 'Firmado con conformidad':
      return <TaskAltIcon fontSize="small"/>;
    case 'Firmado con protesto':
      return  <ErrorOutlineIcon fontSize="small" />;
    default:
      return <CloseIcon fontSize="small"/>;
  }
}

function colorChip(prop){
  switch(prop){
    case 'Anulado': 
      return 'error';
    case 'Pendiente':
      return 'secondary';
    case 'Leido Sin Firmar':
      return 'info';
    case 'Firmado con conformidad':
      return 'success';
    case 'Firmado con protesto':
      return 'warning';
    default:
      return 'info';
  }
}

export default function PopOverFirma(props){
    const [anchorEl, setAnchorEl] = React.useState(null); //popover

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return(
        <Box onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
          <IconButton color={colorChip(props.estado)}>
            {iconoChip(props.estado)}
          </IconButton>
          <Popover
            id="mouse-over-popover"
            sx={{
              pointerEvents: 'none',
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Typography sx={{ p: 0.5}} variant="caption">{props.estado}</Typography>
          </Popover>
        </Box>
    )
}