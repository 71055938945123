import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import { DialogContentText, Stack, TextField } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useContext} from 'react';
import AppContext from "../../Context/AppContext";

export default function FormModificarEmpleado(props) {
  const [open, setOpen] = React.useState(false);
  const [mensajeError, setMensajeError] = React.useState(" ");
  const {token} = useContext(AppContext);

  //Datos
  const [nombre, setNombre] = React.useState(null);
  const [dni, setDni] = React.useState(null);
  const [usuario, setUsuario] = React.useState(null);
  const [correo, setCorreo] = React.useState(null);
  const [cuil, setCuil] = React.useState('');
  const [legajo, setLegajo] = React.useState('');
  const [rol, setRol] = React.useState('');

  //Constantes para validaciones
  const ALPHA_NUMERIC_DASH_REGEX = /^[A-Za-zÀ-ÿ-Z0-9- ]+$/;
  const EMAIL_REGEX = /^[a-zA-Z0-9@.]*$/;
  const NUMERIC_DOT = /^[Z0-9-]*$/;

  const handleChangeNombre = (event) => {
    setNombre(event.target.value);
  }

  const handleChangeDni= (event) => {
    setDni(event.target.value);
  }

  const handleChangeUsuario= (event) => {
    setUsuario(event.target.value);
  }

  const handleChangeCorreo= (event) => {
    setCorreo(event.target.value);
  }

  const handleChangeCuil= (event) => {
    setCuil(event.target.value);
  }

  const handleChangeLegajo= (event) => {
    setLegajo(event.target.value);
  }

  const handleChangeRol= (event) => {
    setRol(event.target.value);
  }


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //Conexión API
  const getEmpleado = async () => {
    try {
      const url = process.env.REACT_APP_API_PATH + '/empleado/';
      const response = await fetch(url + props.empleado, {
        method: "GET",
        headers: {
          token: token
        }
      })
      const res = await response.json()
      setNombre(res.nombre);
      setCorreo(res.email);
      setDni(res.dni);
      setUsuario(res.usuario);
      setRol(res.titulo);
      setCuil(res.cuil);
      setLegajo(res.legajo);

      console.log(res.status);
    } catch (error) {
      console.error(error);
    }
  };
  
  React.useEffect(() => {
    getEmpleado()
  }, [])

  //Conexion API - Empleado
  const handleSubmit = async () => {
    if (nombre !== null && dni !== null && correo !== null && usuario !== null && cuil !== null && rol !== null && legajo !== null) {
      const EmpleadoNuevo = {
        dni: dni,
        email: correo,
        nombre: nombre,
        usuario: usuario,
        titulo: rol, 
        cuil: cuil, 
        legajo, legajo
      }
      fetch(process.env.REACT_APP_API_PATH + "/empleado/" + props.empleado, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
        body: JSON.stringify(EmpleadoNuevo),
      })
        .then((response) => {
          if(response.ok){
            return response.json()
          }
          throw new Error('No se pudo modificar el empleado.');
        })
        .then((data) => {
          alert("El empleado fue modificado correctamente");
          props.setReloadData(true);
          limpiarForm();
          setOpen(false);
        })
        .catch((error) => {
          console.error(error);
          setMensajeError("Error. Revise los datos ingresados.");
        });
      setOpen(false);
    }
    else {
      setMensajeError("Error. Debe completar todos los campos.");
    }
  }

  const limpiarForm = () => {
    setMensajeError(" ");
    setNombre(null);
    setDni(null);
    setUsuario(null);
    setCorreo(null);
    setCuil(null);
    setRol(null);
    setLegajo(null);
  }


  return (
    <>
      <>
        {props.texto ? (
          <Button variant="contained" sx={{ backgroundColor: '#62A0C0', textTransform: 'none', "&:hover": {backgroundColor: '#2C4C58',}}} onClick={handleClickOpen}>
            {props.texto}
          </Button>)
          : (
              <EditIcon onClick={handleClickOpen} fontSize='small'/>
          )}
      </>
      <Dialog open={open} onClose={handleClose} maxWidth='xs' fullWidth>
        <DialogTitle>Modificar Empleado</DialogTitle>
        <DialogContent>

          {/* ---- Nombre y Apellido ---- */}
          <FormControl sx={{ width: '100%', marginTop: 2 }}>
            <TextField
              label="Nombre y Apellido"
              size="small"
              value={nombre}
              id="validation-outlined-input"
              required
              onChange={(event) => {
                if (event.target.value !== "" && !ALPHA_NUMERIC_DASH_REGEX.test(event.target.value)) {
                  return;
                }
                setNombre(event.target.value);
              }}>
            </TextField>
          </FormControl>

          <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ marginTop: 2 }} spacing={2} alignItems="center" justifyContent="center">

            {/* ---- DNI ---- */}
            <FormControl sx={{ width: '100%' }}>
              <TextField
                label="DNI"
                size="small"
                value={dni}
                id="validation-outlined-input"
                required
                inputProps={{
                  maxLength: 8, 
                  inputMode: 'numeric',
                }}
                onChange={handleChangeDni}>
              </TextField>
            </FormControl>

            {/* ---- CUIL ---- */}
            <FormControl sx={{ width: '100%' }}>
              <TextField
                label="CUIL"
                id="validation-outlined-input"
                required
                size="small"
                value={cuil}
                onChange={(event) => {
                  if (event.target.value !== "" && !NUMERIC_DOT.test(event.target.value)) {
                    return;
                  }
                  handleChangeCuil(event);
                }}>
              </TextField>
            </FormControl>

          </Stack>

          {/* ---- Usuario ---- */}
          <FormControl sx={{ width: '100%', marginTop: 2 }}>
              <TextField
                label="Usuario"
                id="validation-outlined-input"
                required
                size="small"
                value={usuario}
                onChange={(event) => {
                  if (event.target.value !== "" && !ALPHA_NUMERIC_DASH_REGEX.test(event.target.value)) {
                    return;
                  }
                  setUsuario(event.target.value);
                }}>
              </TextField>
            </FormControl>

          {/* ---- Correo ---- */}
          <FormControl sx={{ width: '100%', marginTop: 2 }}>
            <TextField
              label="Correo Electrónico"
              id="validation-outlined-input"
              required
              size="small"
              value={correo}
              type='email'
              onChange={(event) => {
                if (EMAIL_REGEX.test(event.target.value) || event.target.value === '') {
                  handleChangeCorreo(event);
                }
              }}>
            </TextField>
          </FormControl>

          <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ marginTop: 2 }} spacing={2} alignItems="center" justifyContent="center">

            {/* ---- Legajo ---- */}
            <FormControl sx={{ width: '100%' }}>
              <TextField
                label="Número de Legajo"
                size="small"
                value={legajo}
                id="validation-outlined-input"
                required
                inputProps={{
                  maxLength: 20, 
                  inputMode: 'numeric',
                }}
                onChange={handleChangeLegajo}>
              </TextField>
            </FormControl>

            {/* ---- Rol ---- */}
            <FormControl sx={{ width: '100%' }}>
              <TextField
                label="Rol"
                id="validation-outlined-input"
                required
                size="small"
                value={rol}
                onChange={(event) => {
                  if (event.target.value !== "" && !ALPHA_NUMERIC_DASH_REGEX.test(event.target.value)) {
                    return;
                  }
                  handleChangeRol(event);
                }}>
              </TextField>
            </FormControl>
            </Stack>

          <DialogContentText color="error">{mensajeError}</DialogContentText>

        </DialogContent>

        <DialogActions>
          <Stack spacing={1} width={'100%'} margin={1}>
            <Button type="submit" onClick={handleSubmit} variant="contained" sx={{ backgroundColor: '#62A0C0', textTransform: 'none', "&:hover": {backgroundColor: '#2C4C58',}}}>Modificar</Button>
            <Button onClick={handleClose} variant="contained" sx={{ backgroundColor: '#BABABA', textTransform: 'none', "&:hover": {backgroundColor: '#2C4C58',}}}>Cancelar</Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
}