import * as React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import MonitorIcon from '@mui/icons-material/Monitor';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { useNavigate } from 'react-router-dom';


export default function ListaBarraLateralUser() {
    const [open, setOpen] = React.useState(true);
    const navigate = useNavigate();

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <>
            {/* Mi Información */}
            <List
                sx={{ width: '100%', maxWidth: 360, bgcolor: '#E6ECED' }}
                component="nav"
                aria-labelledby="nested-list-subheader"
            >
                <ListItemButton onClick={handleClick} sx={{ paddingBottom: 0, paddingTop: 0 }}>
                    <ListItemText
                        primary="Mi Información"
                        primaryTypographyProps={{
                            fontSize: 15,
                            fontStyle: 'normal',
                            fontWeight: 600,
                            lineHeight: '20px',
                        }}
                    />
                    {open ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton onClick={() => navigate("/consulta/documentos")}
                            sx={{ pl: 4, py: 0, minHeight: 32 }}>
                            <ListItemIcon sx={{ my: 0 }}>
                               <NoteAddOutlinedIcon sx={{ color: '#2C4C58' }}/>
                            </ListItemIcon>
                            <ListItemText
                                sx={{ my: 0 }}
                                primary="Documentos"
                                primaryTypographyProps={{ fontSize: 15 }} />
                        </ListItemButton>

                        <ListItemButton sx={{ pl: 4, py: 0, minHeight: 32 }} onClick={() => navigate("/equipo")}>
                            <ListItemIcon>
                                <MonitorIcon sx={{ color: '#2C4C58' }}/>
                            </ListItemIcon>
                            <ListItemText
                                primary="Equipo"
                                sx={{ my: 0 }}
                                primaryTypographyProps={{ fontSize: 15 }} />
                        </ListItemButton>

                        <ListItemButton sx={{ pl: 4, py: 0, minHeight: 32 }} onClick={() => navigate("/lector/qr")}>
                            <ListItemIcon>
                                <QrCodeScannerIcon sx={{ color: '#2C4C58' }}/>
                            </ListItemIcon>
                            <ListItemText
                                primary="Lector de QR"
                                sx={{ my: 0 }}
                                primaryTypographyProps={{ fontSize: 15 }} />
                        </ListItemButton>
                    </List>
                </Collapse>
            </List>
        </>

    );
}