import encodeLogo from "../../images/imageBase64.js"

export default function RSE(nombre, cuil, legajo, rol, componentes, idEquipo, descEquipo, fecha, tipoEquipo) {
  const docDefinition = {
    pageSize: 'A4',
    content: [
       {
       //imagen en base64
        image: encodeLogo(),
        fit: [160, 160]
      },
      {
        text: fecha,
        style: "fecha",
      },
      {
        text: "\n\nResponsabilidad sobre los equipos entregados por ENCODE S.A.",
        style: "header",
      },
      "\n \n Mediante la presente se hace constar que el/la Sr/a:",
      "\n" + nombre + " CUIL: " + cuil,
      "\nquien desempeña rol de " + rol + " , es responsable del cuidado y resguardo del equipo entregado, correspondientes para el uso de sus tareas habituales, cuya identificación es:",
      "\n",
      "TIPO DE EQUIPO: " + tipoEquipo,
      "CARACTERISTICAS:" + " id: "+ idEquipo + " - descripción: " + descEquipo, 
      componentes,
      "\nAsimismo, hacemos de su conocimiento que el equipo recibido debe mantenerse en condiciones de operación y limpieza, no permitir el acceso de terceros al uso del mismo, no modificar su configuración, no instalar software sin ser autorizado por escrito, y ser devuelto cuando sea necesario su recambio, se culmine o modifique la actividad para la cual le fue asignado o le sea solicitado por ENCODE S.A. El equipo podrá ser revisado regularmente por personal técnico de ENCODE S.A como parte de los controles de seguridad establecidos.",

      "\n\n\n_______________________________________________________________________________________________",

      "\n\n\nPor la presente recibo de conformidad, tomando debida nota de mis obligaciones.",

      {
        columns: [
          {
            // auto-sized columns have their widths based on their content
            width: '*',
            style: 'firma',
            text:["\n\n\nFirma del Colaborador",
            "\n\n\n\nFirma: ..........................................................",
            "\n\nAclaración: .....................................................",]
          },
          {
            // star-sized columns fill the remaining space
            // if there's more than one star-column, available width is divided equally
            width: '*',
            style: 'firma',
            text:["\n\n\nFirma del Resp. de Microinformática e Infraestructura",
            "\n\n\nFirma: ..........................................................",
            "\n\nAclaración: .....................................................",
          ]
          },
        ],
        // optional space between columns
        columnGap: 10
      },

    ],
    styles: {
      header: {
        fontSize: 16,
        bold: true,
        margin: [0, 0, 0, 10],
        alignment: "center"
      },
      subheader: {
        fontSize: 16,
        bold: true,
        margin: [0, 10, 0, 5],
      },
      tableExample: {
        margin: [0, 5, 0, 15],
      },
      tableHeader: {
        bold: true,
        fontSize: 13,
        color: "black",
      },
      firma:{
        alignment: "center",
      },
      fecha:{
        alignment: "right",
      }
    },
    defaultStyle: {
      //font: 'Arial',
      alignment: 'justify'
    },
  };
  return docDefinition;
}