import { useEffect } from 'react';
import React from 'react';
import AppContext from './Componentes/Context/AppContext';
import ResponsiveDrawer from './Componentes/BarraLateral/BarraLateral'; 
import Cookies from 'js-cookie';
import {useNavigate } from 'react-router-dom';

export default function App() {
  const { establecerToken, token, setRol, rol, establecerUrl, url} = React.useContext(AppContext);
  const [token2, setToken2] = React.useState('');
  const navigate = useNavigate();

  const redirectURL = window.location.pathname;
  if(redirectURL !== '/login'){
    Cookies.set('redirectURL', redirectURL);
  }

  useEffect(() => {
    const tokenFromCookie = Cookies.get('token');
    const rolFromCookie = Cookies.get('rol');
    const urlFromCookie = Cookies.get('redirectURL');
    setRol( Number(rolFromCookie));
    setToken2(tokenFromCookie);
    establecerToken(tokenFromCookie);
    establecerUrl(urlFromCookie)
  }, []);

  useEffect(() => {
    if (!rol) {
      if(url !== '/login'){
        navigate(url);
      }
      navigate("/login");
    }
    else{
      if(url){
        navigate(url);
      }
      else{
        navigate("/"); 
      }
    }
  }, [token, rol]);

  return <ResponsiveDrawer />;
}
