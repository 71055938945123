import React from "react";
import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import FormModificarEmpleado from "../Formularios/Modificar/ModificarEmpleado";
import Paper from '@mui/material/Paper';
import { useParams } from 'react-router-dom';
import { useContext } from 'react';
import AppContext from "../Context/AppContext";
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import BorrarEmpleado from "../Formularios/Borrar/BorrarEmpleado";
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import CrearUsuario from "../Formularios/Usuario/CrearUsuario";

function Empleado() {
    const [empleado, setEmpleado] = React.useState();
    const [reloadData, setReloadData] = React.useState(false);
    const { id } = useParams();
    const nombreEmpleado = (id.replace('%20', ' ')).toString();
    const { token } = useContext(AppContext);

    //Conexión API Equipos por Id
    const getEmpleado = async () => {
        try {
            const url = process.env.REACT_APP_API_PATH + '/empleado/by/nombre?nombre=' + nombreEmpleado + (reloadData ? `&timestamp=${Date.now()}` : '');
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    token: token
                },
            });
            const res = await response.json();
            setEmpleado(res);
            setReloadData(false);
        } catch (error) {
            console.error(error);
        }
    };

    React.useEffect(() => {
        getEmpleado();
    }, [reloadData]);


    if (!empleado) {
        return <div className="divCentral"> <h1>Cargando...</h1></div>;
    }

    return (
        <div className="divCentral">
            {/* Cabecera */}
            <Stack direction='row' useFlexGap flexWrap="wrap" justifyContent="space-between" alignItems="center" spacing={1}>
                <Stack direction='row' spacing={2} alignItems="center" justifyContent="flex-start">
                    <AccountCircleOutlinedIcon sx={{ fontSize: 85, color: '#B2C5C5' }} />
                    <Stack direction="column" spacing={0}>
                        <h1>{empleado.nombre ? empleado.nombre : 'Empleado'}</h1>
                        <Typography variant="subtitle2">@{empleado.usuario ? empleado.usuario : null}</Typography>
                    </Stack>

                </Stack>
                <Stack direction='row' spacing={2} alignItems="center" justifyContent="flex-end">
                    {empleado.puesto ? <Button variant="contained" disabled sx={{ backgroundColor: '#62A0C0', textTransform: 'none' }}>Editar</Button> : <FormModificarEmpleado texto="Editar" empleado={empleado.idEmpleado} setReloadData={setReloadData} />}
                    {empleado.puesto ? <Button variant="contained" disabled sx={{ backgroundColor: '#62A0C0', textTransform: 'none' }}>Dar de Baja</Button> : <BorrarEmpleado texto="Dar de Baja" empleadoId={empleado.idEmpleado} setReloadData={setReloadData} />}
                    {empleado.idUsuario ? <Button variant="contained" disabled sx={{ backgroundColor: '#62A0C0', textTransform: 'none' }}>Crear Usuario</Button> : <CrearUsuario texto="Crear Usuario" empleado={empleado.idEmpleado} nombre={empleado.nombre} setReloadData={setReloadData} />}
                </Stack>
            </Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="flex-start" spacing={5}>
                <Stack direction="column" justifyContent="flex-start" width={{ sm: '50%', xs: '90%' }} >
                    <Typography variant="h6" m={1}>Información General:</Typography>
                    <Paper elevation={3}>
                        <Stack direction="column" spacing={0.1} m={1.5}>
                            <Typography variant="body1" sx={{ color: '#62A0C0', fontWeight: 'bold' }}>Puesto: {
                                <Link className="non-link-cell" to={`/vista/puesto/${empleado.puesto}`}>
                                    <span>{empleado.puesto ? empleado.puesto : '-'}</span>
                                </Link>}</Typography>
                            <Typography variant="body1" sx={{ color: '#62A0C0', fontWeight: 'bold' }}>Equipo: {
                                <Link className="non-link-cell" to={`/vista/equipo/${empleado.descripcion}`}>
                                    <span>{empleado.descripcion ? empleado.descripcion : '-'}</span>
                                </Link>
                            }</Typography>
                            <Typography variant="body1">ID:{empleado.idEmpleado ? empleado.idEmpleado : null}</Typography>
                            <Typography variant="body1">DNI:{empleado.dni ? empleado.dni : null}</Typography>
                            <Typography variant="body1">CUIL:{empleado.cuil ? empleado.cuil : null}</Typography>
                            <Typography variant="body1">Número de Legajo:{empleado.legajo ? empleado.legajo : null}</Typography>
                            <Typography variant="body1">Título:{empleado.titulo ? empleado.titulo : null}</Typography>
                            <Typography variant="body1">Correo: {empleado.email ? empleado.email : null}</Typography>
                            <Typography variant="body1">Estado: {empleado.estado ? empleado.estado : null}</Typography>
                        </Stack>
                    </Paper>
                </Stack>
            </Stack>
        </div>
    )
}

export default Empleado;