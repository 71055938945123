import * as React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import GridViewIcon from '@mui/icons-material/GridView';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import MonitorIcon from '@mui/icons-material/Monitor';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import QrCode2OutlinedIcon from '@mui/icons-material/QrCode2Outlined';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';

import { useNavigate } from 'react-router-dom';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';

import { Box } from '@mui/system';

export default function ListaBarraLateral() {
    const [open, setOpen] = React.useState(true);
    const [openCons, setOpenCons] = React.useState(true);
    const [openGen, setOpenGen] = React.useState(true);

    const navigate = useNavigate();

    const handleClick = () => {
        setOpen(!open);
    };

    const handleClickCons = () => {
        setOpenCons(!openCons);
    };

    const handleClickGen = () => {
        setOpenGen(!openGen);
    };

    return (
        <Box>
            {/* Home */}
            <List
                sx={{ width: '100%', maxWidth: 360, bgcolor: '#E6ECED', py: 0 }}
                component="nav"
                aria-labelledby="nested-list-subheader"
            >
                <ListItemButton onClick={handleClick} sx={{ paddingBottom: 0, paddingTop: 0 }}>
                    <ListItemText
                        primary="Home"
                        primaryTypographyProps={{
                            fontSize: 15,
                            fontStyle: 'normal',
                            fontWeight: 600,
                            lineHeight: '20px',
                        }}
                    />
                    {open ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton onClick={() => navigate("/home/primerpiso")}
                            sx={{ pl: 4, py: 0, minHeight: 28 }}>
                            <ListItemIcon sx={{ my: 0 }}>
                                <h4>1P</h4>
                            </ListItemIcon>
                            <ListItemText
                                sx={{ my: 0 }}
                                primary="Primer Piso"
                                primaryTypographyProps={{ fontSize: 15 }} />
                        </ListItemButton>

                        <ListItemButton sx={{ pl: 4, py: 0, minHeight: 28 }} onClick={() => navigate("/home/plantabaja")}>
                            <ListItemIcon>
                                <h4>PB</h4>
                            </ListItemIcon>
                            <ListItemText
                                primary="Planta Baja"
                                sx={{ my: 0 }}
                                primaryTypographyProps={{ fontSize: 15 }} />
                        </ListItemButton>

                        <ListItemButton sx={{ pl: 4, py: 0, minHeight: 28 }} onClick={() => navigate("/home/subsuelo")}>
                            <ListItemIcon>
                                <h4>SS</h4>
                            </ListItemIcon >
                            <ListItemText primary="Subsuelo"
                                sx={{ my: 0 }}
                                primaryTypographyProps={{ fontSize: 15 }}
                            />
                        </ListItemButton>
                    </List>
                </Collapse>
            </List>


            {/* Consultar */} 
            <List
                sx={{ width: '100%', maxWidth: 360, bgcolor: '#E6ECED', py: 0, }}
                component="nav"
                aria-labelledby="nested-list-subheader"
            >
                <ListItemButton onClick={handleClickCons} sx={{ paddingBottom: 0, paddingTop: 0 }}>
                    <ListItemText
                        primary="Consultar"
                        primaryTypographyProps={{
                            fontSize: 15,
                            fontStyle: 'normal',
                            fontWeight: 600,
                            lineHeight: '20px',
                        }} />
                    {openCons ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openCons} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton sx={{ pl: 4, py: 0, minHeight: 28 }} onClick={() => navigate("/consulta/puestos")}>
                            <ListItemIcon>
                                <GridViewIcon sx={{ color: '#2C4C58' }} />
                            </ListItemIcon>
                            <ListItemText primary="Puestos"
                                primaryTypographyProps={{ fontSize: 15 }}
                                sx={{ my: 0 }} />
                        </ListItemButton>

                        <ListItemButton sx={{ pl: 4, py: 0, minHeight: 28 }} onClick={() => navigate("/consulta/empleados")}>
                            <ListItemIcon>
                                <PersonOutlineIcon sx={{ color: '#2C4C58' }} />
                            </ListItemIcon>
                            <ListItemText primary="Empleados"
                                primaryTypographyProps={{ fontSize: 15 }}
                                sx={{ my: 0 }} />
                        </ListItemButton>

                        <ListItemButton sx={{ pl: 4, py: 0, minHeight: 28 }} onClick={() => navigate("/consulta/equipos")}>
                            <ListItemIcon>
                                <MonitorIcon sx={{ color: '#2C4C58' }} />
                            </ListItemIcon>
                            <ListItemText primary="Equipos"
                                primaryTypographyProps={{ fontSize: 15 }}
                                sx={{ my: 0 }} />
                        </ListItemButton>

                        <ListItemButton sx={{ pl: 4, py: 0, minHeight: 28 }} onClick={() => navigate("/consulta/componentes")}>
                            <ListItemIcon>
                                <KeyboardIcon sx={{ color: '#2C4C58' }} />
                            </ListItemIcon>
                            <ListItemText primary="Componentes"
                                primaryTypographyProps={{ fontSize: 15 }}
                                sx={{ my: 0 }} />
                        </ListItemButton>

                        <ListItemButton sx={{ pl: 4, py: 0, minHeight: 28 }} onClick={() => navigate("/consulta/historial")}>
                            <ListItemIcon>
                                <HistoryOutlinedIcon sx={{ color: '#2C4C58' }} />
                            </ListItemIcon>
                            <ListItemText primary="Historial"
                                primaryTypographyProps={{ fontSize: 15 }}
                                sx={{ my: 0 }} />
                        </ListItemButton>

                        <ListItemButton sx={{ pl: 4, py: 0, minHeight: 28 }} onClick={() => navigate("/consulta/documentos")}>
                            <ListItemIcon>
                                <InsertDriveFileOutlinedIcon sx={{ color: '#2C4C58' }} />
                            </ListItemIcon>
                            <ListItemText primary="Documentos"
                                primaryTypographyProps={{ fontSize: 15 }}
                                sx={{ my: 0 }} />
                        </ListItemButton>

                        <ListItemButton sx={{ pl: 4, py: 0, minHeight: 28 }} onClick={() => navigate("/lector/qr")}>
                            <ListItemIcon>
                                <QrCodeScannerIcon sx={{ color: '#2C4C58' }}/>
                            </ListItemIcon>
                            <ListItemText
                                primary="Lector de QR"
                                sx={{ my: 0 }}
                                primaryTypographyProps={{ fontSize: 15 }} />
                        </ListItemButton>
                    </List>
                </Collapse>
            </List>


            {/* ---- Generar ---- */}
            <List
                sx={{ width: '100%', maxWidth: 360, bgcolor: '#E6ECED', py: 0}}
                component="nav"
                aria-labelledby="nested-list-subheader"
            >
                <ListItemButton onClick={handleClickGen} sx={{ paddingBottom: 0, paddingTop: 0 }}>
                    <ListItemText
                        primary="Generar"
                        primaryTypographyProps={{
                            fontSize: 15,
                            fontStyle: 'normal',
                            fontWeight: 600,
                            lineHeight: '20px',
                        }} />
                    {openGen ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openGen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton sx={{ pl: 4, py: 0, minHeight: 28 }} onClick={() => navigate("/generar/qr")}>
                            <ListItemIcon>
                                <QrCode2OutlinedIcon sx={{ color: '#2C4C58' }} />
                            </ListItemIcon>
                            <ListItemText primary="QR"
                                primaryTypographyProps={{ fontSize: 15 }}
                                sx={{ my: 0 }} />
                        </ListItemButton>
                    </List>
                </Collapse>
            </List>
        </Box>

    );
}