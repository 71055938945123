import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import CAE from "../pdf/CAE";
import RSE from "../pdf/RSE";
import { List } from "@mui/material";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const componentes = (equipo) => {
    //"- RAM 8gb \n - Disco SSD 256gb \n - Monitor x"
    var cadena = new List();
    if(equipo.componentes){
        equipo.componentes.map((c, index2) => {
            console.log(cadena);
            cadena.push(c.tipo_componente.descripcion.toUpperCase() + ": " 
            + "id "+ c.idComponente
            + (c.marca ? (" - marca: " + c.marca) : "")
            + (c.modelo ? (" - modelo: " + c.modelo) : "")
            + (c.numSerie ? (" - numero de serie: " + c.numSerie) : "")
            + (c.capacidadMemoria ? (" - capacidad: " + c.capacidadMemoria) : "")
            + (c.descripcion ? (" - descripcion: " + c.descripcion) : "")
            + " \n");
        })
    }  
}


const createRSE = (equipo, empleado, cuil, legajo, rol) => {
    var cadena = []; 
    if(equipo.componentes){
        equipo.componentes.map((c, index2) => {
            cadena.push(c.tipo_componente.descripcion.toUpperCase() + ": " 
            + "id "+ c.idComponente
            + (c.marca ? (" - marca: " + c.marca) : "")
            + (c.modelo ? (" - modelo: " + c.modelo) : "")
            + (c.numSerie ? (" - numero de serie: " + c.numSerie) : "")
            + (c.capacidadMemoria ? (" - capacidad: " + c.capacidadMemoria) : "")
            + (c.descripcion ? (" - descripcion: " + c.descripcion) : "")
            + " \n");
        })
    }
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const fecha = new Date(Date.now());
    const rse = pdfMake.createPdf(RSE(empleado, cuil, legajo, rol, cadena.toString().replaceAll(',', ''), equipo.id, equipo.descripcion, fecha.toLocaleDateString("es-AR", options), equipo.tipo));
    return rse; 
};


export default createRSE; 
