import React from 'react';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

const style = ({
  customButtonVerde: {
    borderRadius: '0', 
    borderWidth: '1px', 
    borderColor: 'black',
    borderStyle: 'solid',
    backgroundColor: '#9FDB5B',
    height: '75px', 
    minWidth: '45px',
    width: '45px',
    padding: 0,
    "&:active": {
      backgroundColor: '#67C200', 
    },
    "&:hover": {
      backgroundColor: '#67C200', 
    },
  },

  customButtonRojo: {
    borderRadius: '0',
    borderWidth: '1px',
    borderColor: 'black',
    borderStyle: 'solid',
    backgroundColor: '#CA4945',
    height: '75px',
    minWidth: '45px',
    width: '45px',
    padding: 0,
    "&:active": {
      backgroundColor: '#C20600',
    },
    "&:hover": {
      backgroundColor: '#C20600',
    },
  },

  customButtonAmarillo: {
    borderRadius: '0',
    borderWidth: '1px',
    borderColor: 'black',
    borderStyle: 'solid',
    backgroundColor: '#FFE073',
    height: '75px',
    minWidth: '45px',
    width: '45px',
    padding: 0,
    "&:active": {
      backgroundColor: '#FAC300',
    },
    "&:hover": {
      backgroundColor: '#FAC300',
    },
  },

  customButtonBlanco: {
    borderRadius: '0',
    borderWidth: '1px',
    borderColor: 'black',
    borderStyle: 'solid',
    backgroundColor: '#FFFFFF',
    height: '75px',
    minWidth: '45px',
    width: '45px',
    padding: 0,
    "&:active": {
      backgroundColor: '#D9D9D9',
    },
    "&:hover": {
      backgroundColor: '#D9D9D9',
    },
  },

  customButtonAzul: {
    borderRadius: '0',
    borderWidth: '1px',
    borderColor: 'black',
    borderStyle: 'solid',
    backgroundColor: '#62AAC1',
    height: '75px',
    minWidth: '45px',
    width: '45px',
    padding: 0,
    "&:active": {
      backgroundColor: '#0093C2',
    },
    "&:hover": {
      backgroundColor: '#0093C2',
    },
  },
  });

const CustomButton = (props) => {

  const [anchorEl, setAnchorEl] = React.useState(null);

  const navigate = useNavigate();

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Button
        disableElevation
        sx={props.color == 1 ? style.customButtonVerde : (props.color === 2 ? style.customButtonAzul : (props.color === 3 ? style.customButtonRojo : (props.color === 4 ? style.customButtonAmarillo : style.customButtonBlanco)))}
        variant="contained"
        onClick={() => props.puesto === 'Pecera 1' || props.puesto === 'Pecera 2' || props.puesto === 'Pecera 3' ? null : navigate("/vista/puesto/"+ props.puesto)}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}>
      </Button>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 1 }}>{props.puesto}</Typography>
      </Popover>
    </>

  );
};

export default CustomButton;