import * as React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { useNavigate } from 'react-router-dom';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';

export default function ListaBarraLateralRRHH() {
    const [openCons, setOpenCons] = React.useState(true);
    const navigate = useNavigate();

    const handleClickCons = () => {
        setOpenCons(!openCons);
    };

    return (
        <>
            {/* Mi Información */}
            <List
                sx={{ width: '100%', maxWidth: 360, bgcolor: '#E6ECED', paddingTop: 0 }}
                component="nav"
                aria-labelledby="nested-list-subheader"
            >
                <ListItemButton onClick={handleClickCons} sx={{ paddingBottom: 0, paddingTop: 0 }}>
                    <ListItemText
                        primary="Mi Información"
                        primaryTypographyProps={{
                            fontSize: 15,
                            fontStyle: 'normal',
                            fontWeight: 600,
                            lineHeight: '20px',
                        }} />
                    {openCons ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openCons} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton sx={{ pl: 4, py: 0, minHeight: 32 }} onClick={() => navigate("/consulta/documentos")}>
                            <ListItemIcon>
                                <InsertDriveFileOutlinedIcon sx={{ color: '#2C4C58' }} />
                            </ListItemIcon>
                            <ListItemText primary="Documentos"
                                primaryTypographyProps={{ fontSize: 15 }}
                                sx={{ my: 0 }} />
                        </ListItemButton>

                        <ListItemButton sx={{ pl: 4, py: 0, minHeight: 32 }} onClick={() => navigate("/lector/qr")}>
                            <ListItemIcon>
                                <QrCodeScannerIcon sx={{ color: '#2C4C58' }}/>
                            </ListItemIcon>
                            <ListItemText
                                primary="Lector de QR"
                                sx={{ my: 0 }}
                                primaryTypographyProps={{ fontSize: 15 }} />
                        </ListItemButton>
                    </List>
                </Collapse>
            </List>
        </>

    );
}