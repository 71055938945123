import * as React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { useNavigate } from 'react-router-dom';
import AppContext from '../Context/AppContext';
import { Box } from '@mui/system';
import IconButton from '@mui/material/IconButton';

import LogoutIcon from '@mui/icons-material/Logout';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import ButtonBase from '@mui/material/ButtonBase';


export default function InfoUsuario(){
    const [empleado, setEmpleado] = React.useState();
    const { token, cerrarSesion} = React.useContext(AppContext);

    //Conexión API Empleados por Nombre
    const getEmpleado = async () => {
        try {
            const url = process.env.REACT_APP_API_PATH + '/usuario/info ';
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    token: token
                },
            });
            const res = await response.json();
            setEmpleado(res);
        } catch (error) {
            console.error(error);
        }
    };

    React.useEffect(() => {
        getEmpleado();
    }, []);


    const navigate = useNavigate();

    return(
        <Box sx={{
            color: '#543440',
            textTransform: 'none',
            width: '100%',
            height: 'auto',
            position: 'absolute',
            bottom: '10px',
        }}>
            <Divider orientation="horizontal" flexItem />
            <Stack
                direction={'row'}
                justifyContent="space-between"
                alignItems="center"
                fullWidth
                padding={1}
                >
                <ButtonBase onClick={() => navigate("/perfil")} fullWidth variant='text'>
                  <Stack direction={'row'} spacing={2} alignItems="center" justifyContent="center">
                    <PersonOutlineIcon style={{fontSize:'30px', color:'#2C4C58'}}/>
                    <Stack direction={'column'} justifyContent="flex-start" alignItems="center">
                        <Typography variant='subtitle2' style={{fontWeight:'bold', color:'#2C4C58'}}>{empleado ? empleado.nombre : 'Usuario'}</Typography>
                        <Typography variant='caption'>{empleado ? empleado.email : 'email'}</Typography>
                    </Stack>
                </Stack>  
                </ButtonBase>
                
                <IconButton onClick={cerrarSesion}>
                    <LogoutIcon fontSize='medium'/>
                </IconButton>
            </Stack>
        </Box>
    )
}