import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AppContext from '../../Context/AppContext';
import { useContext } from 'react';
import AddIcon from '@mui/icons-material/Add';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputAdornment from '@mui/material/InputAdornment';

import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Stack
} from '@mui/material';


const FormNuevoEquipo = (props) => {
  const [tipoEquipo, setTipoEquipo] = useState('');
  const [dominio, setDominio] = useState('');
  const [numeroMac, setNumeroMac] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [ip, setIp] = useState('');
  const [vpn, setVpn] = useState('');
  const [numSerieCPU, setNumSerieCPU] = useState('');
  const [descCPU, setDescCPU] = useState('');
  const [placaMadre, setPlacaMadre] = useState('');
  const [remotoActualizado, setRemotoActualizado] = useState(false);

  //Colecciones
  const [componentes, setComponentes] = useState([]);
  const [monitores, setMonitores] = useState([]);
  const [discos, setDiscos] = useState([]);
  const [memorias, setMemorias] = useState([]);

  //Datos
  const [tiposEquipo, setTiposEquipo] = React.useState('');
  const [dominios, setDominios] = React.useState('');
  const {token} = useContext(AppContext);

  //Constantes para validaciones
  const ALPHA_NUMERIC_DASH_REGEX = /^[A-Za-zÀ-ÿ-Z0-9- ]+$/;
  const ALPHA_NUMERIC_DOT = /^[a-zA-Z0-9.]*$/;
  const NUMERIC_DOT = /^[Z0-9.]*$/;
  const ALPHA_NUMERIC_MAC = /^[a-zA-Z0-9.:]*$/;
  const ALPHA_NUMERIC_NS = /^[a-zA-Z0-9./-]*$/;

  // State para el diálogo
  const [openDialog, setOpenDialog] = useState(false);

  //Conexión API Tipos Equipo
  const getTiposEquipo = async () => {
    try {
      const url = process.env.REACT_APP_API_PATH + '/tipoEquipo';
      const response = await fetch(url, {
        method: "GET",
        headers: {
          token: token
        }
      })
      const res = await response.json()
      setTiposEquipo(res);
    } catch (error) {
      console.error(error);
    }
  };

  //Conexión API Dominios
  const getDominios = async () => {
    try {
      const url = process.env.REACT_APP_API_PATH + '/dominio';
      const response = await fetch(url, {
        method: "GET",
        headers: {
          token: token
        }
      })
      const res = await response.json()
      setDominios(res);
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    getTiposEquipo();
    getDominios();
  }, [])

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    CleanForm();
    setOpenDialog(false);
  };

  const handleAddComponente = () => {
    setComponentes([...componentes, { idTipoComponente: null, esPropio: false, idComponente: null, numSerie: null, marca: null, modelo: null, capacidadMemoria: null, descripcion:null, estaEncriptado:false, idEstado: 1}]);
  };

  const handleAddMonitor = () => {
    setMonitores([...monitores, { numSerie: null, marca: null, modelo: null, idComponente: null, idTipoComponente: 7, esPropio: false, descripcion: null, capacidadMemoria: null, estaEncriptado:false, idEstado: 1}]);
  };

  const handleAddDisco = () => {
    setDiscos([...discos, { idTipoComponente: null, capacidadMemoria: null, estaEncriptado: false, idComponente: null, marca: null, modelo: null, descripcion: null, esPropio: false, numSerie: null, idEstado: 1 }]);
  };

  const handleAddMemoria = () => {
    setMemorias([...memorias, { capacidadMemoria: null, descripcion: null, idComponente: null, idTipoComponente: 4, numSerie: null, marca: null, modelo: null, esPropio:false, estaEncriptado: false, idEstado: 1 }]);
  };

  const handleComponenteChange = (index, key, value) => {
    const updatedComponentes = [...componentes];
    updatedComponentes[index][key] = value;
    setComponentes(updatedComponentes);
  };

  const handleMonitorChange = (index, key, value) => {
    const updatedMonitores = [...monitores];
    updatedMonitores[index][key] = value;
    setMonitores(updatedMonitores);
  };

  const handleDiscoChange = (index, key, value) => {
    const updatedDiscos = [...discos];
    updatedDiscos[index][key] = value;
    setDiscos(updatedDiscos);
  };

  const handleMemoriaChange = (index, key, value) => {
    const updatedMemorias = [...memorias];
    updatedMemorias[index][key] = value;
    setMemorias(updatedMemorias);
  };

  const handleDeleteComponente = (index) => {
    const updatedComponentes = [...componentes];
    updatedComponentes.splice(index, 1);
    setComponentes(updatedComponentes);
  };

  const handleDeleteMonitor = (index) => {
    const updatedMonitores = [...monitores];
    updatedMonitores.splice(index, 1);
    setMonitores(updatedMonitores);
  };

  const handleDeleteDisco = (index) => {
    const updatedDiscos = [...discos];
    updatedDiscos.splice(index, 1);
    setDiscos(updatedDiscos);
  };

  const handleDeleteMemoria = (index) => {
    const updatedMemorias = [...memorias];
    updatedMemorias.splice(index, 1);
    setMemorias(updatedMemorias);
  };

  //Conexión API - Nuevo Equipo
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (tipoEquipo !== '' && dominio !== '' && numeroMac !== '' && ip !== '' && descCPU !== '' && numSerieCPU !== '') {
      const todosComponentes = [...componentes, ...monitores, ...discos, ...memorias];
      const formData = {
        idTipoEquipo: tipoEquipo,
        descripcion: descripcion,
        idDominio: dominio,
        mac: numeroMac,
        ipEquipo: ip,
        ipVPN: vpn,
        placaMadre: placaMadre,
        remotoActualizado: remotoActualizado,
        CPU: {
          descripcion: descCPU,
          numSerie: numSerieCPU,
        },
        componentes: todosComponentes,
      };
      fetch(process.env.REACT_APP_API_PATH + "/equipo/", {
        method: "POST",
        headers: {
          token: token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(formData)
      })
        .then((response) => {
          if(response.ok){
            return response.json();
          }
          throw new Error('No se pudo crear el equipo');
        })
        .then((data) => {
          console.log(data);
          alert("El equipo fue creado correctamente");
          props.setReloadData(true);
        })
        .catch((error) => {
          console.log(error);
           alert("Error. Revise los datos ingresados.");
        });
      handleCloseDialog();
    }
    else {
      alert("Debe completar todos los campos");
    }
  };

  const CleanForm = () => {
    setTipoEquipo('');
    setDominio('');
    setNumeroMac('');
    setIp('');
    setVpn('');
    setDescCPU('');
    setNumSerieCPU('');
    setPlacaMadre('');
    setRemotoActualizado(false);
    setComponentes([]);
    setMonitores([]);
    setDiscos([]);
    setMemorias([]);
  }

  return (
    <>
      <Button variant="contained" sx={{ backgroundColor: '#62A0C0', textTransform: 'none', "&:hover": {backgroundColor: '#2C4C58',}}} onClick={handleOpenDialog}>
        Nuevo Equipo
      </Button>

      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
        <DialogTitle>Nuevo Equipo</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>

            <Typography variant="h6">• Información</Typography>

            <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ marginTop: 2 }} spacing={2} alignItems="center" justifyContent="center">
                <TextField
                  fullWidth
                  size="small"
                  label="Descripcion"
                  value={descripcion}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">ESL-PC-</InputAdornment>,
                  }}
                  onChange={(e) => {
                    if (e.target.value !== "" && !ALPHA_NUMERIC_DASH_REGEX.test(e.target.value)) {
                      return;
                    }
                    setDescripcion(e.target.value)
                  }}
                />

              {/* Tipo Equipo */}
              <FormControl size='small' sx={{ width: '100%', marginTop: 2 }}>
                <InputLabel id="demo-select-small-label">Tipo Equipo</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={tipoEquipo}
                  label="Tipo Equipo"
                  onChange={(e) => setTipoEquipo(e.target.value)}
                >
                  <MenuItem value=""><em>None</em></MenuItem>
                  {tiposEquipo !== '' ? tiposEquipo.map((e, index) => (
                    <MenuItem key={e.idTipoEquipo} value={e.idTipoEquipo}>
                      {e.descripcion}
                    </MenuItem>
                  )) : <MenuItem value=""><em>None</em></MenuItem>}
                </Select>
              </FormControl>
            </Stack>

            {/* Dominio */}
            <FormControl size='small' sx={{ width: '100%', marginTop: 2 }}>
              <InputLabel id="demo-select-small-label">Dominio</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={dominio}
                label="Dominio"
                onChange={(e) => setDominio(e.target.value)}
              >
                <MenuItem value=""><em>None</em></MenuItem>
                {dominios !== '' ? dominios.map((e, index) => (
                  <MenuItem key={index} value={e.idDominio}>
                    {e.descripcion}
                  </MenuItem>
                )) : <MenuItem value=""><em>None</em></MenuItem>}
              </Select>
            </FormControl>

            {/* MAC, IP, IPVPN*/}
            <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ marginTop: 2 }} spacing={2} alignItems="center" justifyContent="center">
              <TextField
                fullWidth
                size="small"
                label="Número de MAC"
                value={numeroMac}
                onChange={(e) => {
                  if (e.target.value !== "" && !ALPHA_NUMERIC_MAC.test(e.target.value)) {
                    return;
                  }
                  setNumeroMac(e.target.value)
                }}
              />

              <TextField
                fullWidth
                size="small"
                label="IP"
                value={ip}
                onChange={(e) => {
                  if (e.target.value !== "" && !NUMERIC_DOT.test(e.target.value)) {
                    return;
                  }
                  setIp(e.target.value)
                }}
              />

              <TextField
                fullWidth
                size="small"
                label="IP VPN"
                value={vpn}
                onChange={(e) => {
                  if (e.target.value !== "" && !NUMERIC_DOT.test(e.target.value)) {
                    return;
                  }
                  setVpn(e.target.value)
                }}
              />
            </Stack>

            <TextField
              fullWidth
              size="small"
              label="Placa Madre"
              value={placaMadre}
              onChange={(e) => {
                if (e.target.value !== "" && !ALPHA_NUMERIC_NS.test(e.target.value)) {
                  return;
                }
                setPlacaMadre(e.target.value)
              }}
              margin="normal"
            />

            <FormControlLabel
              label="Remoto Actualizado"
              control={<Checkbox checked={remotoActualizado} onChange={(e) => setRemotoActualizado(e.target.checked)} color='default' />}
            />

            <Typography variant="h6" sx={{ marginTop: 2, marginBottom: 2 }}>• CPU</Typography>
            <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ marginTop: 2 }} spacing={2} alignItems="center" justifyContent="center">
              <TextField
                fullWidth
                label="Número de serie"
                value={numSerieCPU}
                onChange={(e) => {
                  if (e.target.value !== "" && !ALPHA_NUMERIC_DOT.test(e.target.value)) {
                    return;
                  }
                  setNumSerieCPU(e.target.value)
                }}
                size="small"
              />

              <TextField
                fullWidth
                label="Descripción"
                value={descCPU}
                onChange={(event) => {
                  if (event.target.value !== "" && !ALPHA_NUMERIC_DASH_REGEX.test(event.target.value)) {
                    return;
                  }
                  setDescCPU(event.target.value);
                }}
                size="small"
              />
            </Stack>

            {/* Agregar perifericos */}
            <Stack direction='row' sx={{ marginTop: 2, marginBottom: 2 }} alignItems={'center'} spacing={2}>
              <Typography variant="h6">• Periférico </Typography>
              <IconButton sx={{ height: 'fit-content', width: 'fit-content' }} onClick={handleAddComponente}>
                <AddIcon />
              </IconButton>
            </Stack>
            {!componentes ? null : componentes.map((componente, index) => (
              <div key={index} className="DivMonitor">
                <Stack direction='row' spacing={1} alignItems={'center'} fullWidth>
                  <Typography variant='p'>Componente {index + 1}</Typography>
                  <IconButton sx={{ height: 'fit-content', width: 'fit-content' }} onClick={(e) => { e.preventDefault(); handleDeleteComponente(index) }}>
                    <DeleteOutlineIcon />
                  </IconButton>
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ marginTop: 1 }} spacing={1} alignItems="center" justifyContent="center">

                  <FormControl sx={{ m: 1, minWidth: 200, ml: 0 }} size="small" fullWidth>
                    <InputLabel id="demo-select-small-label">Tipo Componente</InputLabel>
                    <Select
                      fullWidth
                      labelId="demo-select-small-label"
                      value={componente.idTipoComponente}
                      label="Tipo Componente"
                      required
                      onChange={(e) =>
                        handleComponenteChange(index, 'idTipoComponente', e.target.value)}
                    >
                      <MenuItem>
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={1} key={1}>Auriculares</MenuItem>
                      <MenuItem value={2} key={2}>Mouse</MenuItem>
                      <MenuItem value={3} key={3}>Teclado</MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl sx={{ m: 1, minWidth: 200, ml: 0 }} size="small" fullWidth>
                    <InputLabel id="demo-select-small-label">Propiedad</InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      value={componente.esPropio}
                      label="Propiedad"
                      onChange={(e) => handleComponenteChange(index, 'esPropio', e.target.value)}
                    >
                      <MenuItem>
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={true} key={1}>Propio</MenuItem>
                      <MenuItem value={false} key={2}>Empresa</MenuItem>
                    </Select>
                  </FormControl>

                  <TextField
                    fullWidth
                    label="Identificador"
                    type='number'
                    value={componente.idComponente}
                    onChange={(e) =>
                      {
                        handleComponenteChange(index, 'idComponente', e.target.value);
                        handleComponenteChange(index, 'esPropio', false);
                      }
                    }
                    size="small"
                  />
                </Stack>

              </div>
            ))}

            {/* Agregar monitores */}
            <Stack direction='row' sx={{ marginTop: 2, marginBottom: 2 }} alignItems={'center'} spacing={2}>
              <Typography variant="h6">• Monitores</Typography>
              <IconButton sx={{ height: 'fit-content', width: 'fit-content' }} onClick={handleAddMonitor}>
                <AddIcon />
              </IconButton>
            </Stack>
            {!monitores ? null : monitores.map((monitor, index) => (
              <div key={index} className="DivMonitor">
                <Stack direction='row' spacing={1} alignItems={'center'} fullWidth>
                  <Typography variant='p'>Monitor {index + 1}</Typography>
                  <IconButton sx={{ height: 'fit-content', width: 'fit-content' }} onClick={(e) => { e.preventDefault(); handleDeleteMonitor(index) }}>
                    <DeleteOutlineIcon />
                  </IconButton>
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ marginTop: 1 }} spacing={2} alignItems="center" justifyContent="center" fullWidth>
                  <TextField
                    fullWidth
                    label="Número de Serie"
                    size="small"
                    value={monitor.numSerie}
                    onChange={(e) => {
                      if (e.target.value !== "" && !ALPHA_NUMERIC_NS.test(e.target.value)) {
                        return;
                      }
                      handleMonitorChange(index, 'numSerie', e.target.value)
                    }
                    }
                  />
                  <TextField
                    fullWidth
                    label="Marca"
                    size="small"
                    value={monitor.marca}
                    onChange={(event) => {
                      if (event.target.value !== "" && !ALPHA_NUMERIC_DASH_REGEX.test(event.target.value)) {
                        return;
                      }
                      handleMonitorChange(index, 'marca', event.target.value);
                    }}
                  />

                  <TextField
                    fullWidth
                    label="Modelo"
                    size="small"
                    value={monitor.modelo}
                    onChange={(event) => {
                      if (event.target.value !== "" && !ALPHA_NUMERIC_DASH_REGEX.test(event.target.value)) {
                        return;
                      }
                      handleMonitorChange(index, 'modelo', event.target.value);
                    }}
                  />
                </Stack>

              </div>
            ))}
            {/* Agregar discos */}
            <Stack direction='row' sx={{ marginTop: 2, marginBottom: 2 }} alignItems={'center'} spacing={2}>
              <Typography variant="h6">• Discos</Typography>
              <IconButton sx={{ height: 'fit-content', width: 'fit-content' }} onClick={handleAddDisco}>
                <AddIcon />
              </IconButton>
            </Stack>
            {!discos ? null : discos.map((disco, index) => (
              <div key={index} className="DivMonitor">
                <Stack direction='row' spacing={1} alignItems={'center'} fullWidth>
                  <Typography variant='p'>Disco {index + 1}</Typography>
                  <IconButton sx={{ height: 'fit-content', width: 'fit-content' }} onClick={(e) => { e.preventDefault(); handleDeleteDisco(index) }}>
                    <DeleteOutlineIcon />
                  </IconButton>
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ marginTop: 1 }} spacing={2} alignItems="center" justifyContent="center" fullWidth>
                  <TextField
                    fullWidth
                    label="Capacidad"
                    size="small"
                    value={disco.capacidadMemoria}
                    onChange={(e) => {
                      if (e.target.value !== "" && !ALPHA_NUMERIC_DASH_REGEX.test(e.target.value)) {
                        return;
                      }
                      handleDiscoChange(index, 'capacidadMemoria', e.target.value)
                    }
                    }
                  />
                  <FormControl sx={{ m: 1, minWidth: 140, ml: 0 }} size="small" fullWidth>
                    <InputLabel id="demo-select-small-label">Tipo Disco</InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={disco.idTipoComponente}
                      required
                      label="Tipo Disco"
                      onChange={(e) => handleDiscoChange(index, 'idTipoComponente', e.target.value)}
                    >
                      <MenuItem><em>None</em></MenuItem>
                      <MenuItem value={5} key={5}>SSD</MenuItem>
                      <MenuItem value={6} key={6}>HDD</MenuItem>
                      <MenuItem value={8} key={8}>NVMe-M.2</MenuItem>
                    </Select>
                  </FormControl>

                  <FormControlLabel
                    label="Encriptado"
                    control={<Checkbox checked={disco.estaEncriptado} onChange={(e) => handleDiscoChange(index, 'estaEncriptado', e.target.checked)} color='default' />}
                  />
                </Stack>

              </div>
            ))}

            {/* Agregar RAM */}
            <Stack direction='row' sx={{ marginTop: 2, marginBottom: 2 }} alignItems={'center'} spacing={2}>
              <Typography variant="h6">• Memoria RAM</Typography>
              <IconButton sx={{ height: 'fit-content', width: 'fit-content' }} onClick={handleAddMemoria}>
                <AddIcon />
              </IconButton>
            </Stack>
            {!memorias ? null :memorias.map((memoria, index) => (
              <div key={index} className="DivMonitor">
                <Stack direction='row' spacing={1} alignItems={'center'} fullWidth>
                  <Typography variant='p'>Memoria {index + 1}</Typography>
                  <IconButton sx={{ height: 'fit-content', width: 'fit-content' }} onClick={(e) => { e.preventDefault(); handleDeleteMemoria(index) }}>
                    <DeleteOutlineIcon />
                  </IconButton>
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ marginTop: 1 }} spacing={2} alignItems="center" justifyContent="center" fullWidth>
                  <TextField
                    fullWidth
                    label="Capacidad"
                    size="small"
                    value={memoria.capacidadMemoria}
                    onChange={(e) => {
                      if (e.target.value !== "" && !ALPHA_NUMERIC_DASH_REGEX.test(e.target.value)) {
                        return;
                      }
                      handleMemoriaChange(index, 'capacidadMemoria', e.target.value)
                    }
                    }
                  />

                  <TextField
                    fullWidth
                    label="Descripcion"
                    size="small"
                    value={memoria.descripcion}
                    onChange={(e) => {
                      if (e.target.value !== "" && !ALPHA_NUMERIC_DASH_REGEX.test(e.target.value)) {
                        return;
                      }
                      handleMemoriaChange(index, 'descripcion', e.target.value)
                    }}
                  />
                </Stack>
              </div>
            ))}
          </form>
        </DialogContent>
        <DialogActions>
          <Stack spacing={1} width={'100%'} margin={1}>
            <Button type="submit" onClick={handleSubmit} variant="contained" sx={{ backgroundColor: '#62A0C0', textTransform: 'none', "&:hover": {backgroundColor: '#2C4C58',}}}>Registrar</Button>
            <Button onClick={handleCloseDialog} variant="contained" sx={{ backgroundColor: '#BABABA', textTransform: 'none', "&:hover": {backgroundColor: '#2C4C58',}}}>Cancelar</Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FormNuevoEquipo;