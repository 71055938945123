import * as React from 'react';
import { Stack } from '@mui/material';
import CustomButton from './CustomButton';
import Silla from './Silla';
import Divider from '@mui/material/Divider';

function PuestoPecera(props){

    return(
        <Stack direction='row'>
            <Stack direction={'column'} >
                <Silla dir='i' puesto={props.puesto}/>
                <Silla dir='i' puesto={props.puesto}/>
            </Stack>
            <CustomButton puesto={props.puesto} color={5}/>
            <Silla dir='d' puesto={props.puesto}/>
        </Stack>
    )
}

export default function Pecera(){
    return(
        <Stack 
        spacing={3}
        justifyContent={'space-between'}
        divider={<Divider orientation="horizontal" flexItem />}>
            <PuestoPecera puesto={'Pecera 1'}/>
            <PuestoPecera puesto={'Pecera 2'}/>
            <PuestoPecera puesto={'Pecera 3'}/>
        </Stack>
    )
}