import React from "react";
import MonitorIcon from '@mui/icons-material/Monitor';
import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import Paper from '@mui/material/Paper';
import { useContext } from 'react';
import AppContext from "../Context/AppContext";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function MiEquipo() {
    const [equipo, setEquipo] = React.useState([]);
    const [perifericos, setPerifericos] = React.useState([]);
    const [discos, setDiscos] = React.useState([]);
    const [monitores, setMonitores] = React.useState([]);
    const [ram, setRam] = React.useState([]);

    const { token } = useContext(AppContext);

    const navigate = useNavigate();

    //Conexión API Equipos por Id
    const getEquipo = async () => {
        try {
            const url = process.env.REACT_APP_API_PATH + '/usuario/equipo';
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    token: token
                }
            })
            const res = await response.json()
            setEquipo(res);
            setRam(res.componentes.filter((c) => c.tipo_componente.idTipoComponente === 4))
            setPerifericos(res.componentes.filter((c) => c.tipo_componente.idTipoComponente < 4))
            setMonitores(res.componentes.filter((c) => c.tipo_componente.idTipoComponente === 7))
            setDiscos(res.componentes.filter((c) => c.tipo_componente.idTipoComponente === 5 || c.tipo_componente.idTipoComponente === 6 || c.tipo_componente.idTipoComponente === 8))
        } catch (error) {
            console.error(error);
        }
    };

    React.useEffect(() => {
        getEquipo();
    }, [])

    if (equipo === null) {
        navigate('/error/sinEquipo');
    }
    if (!equipo || !equipo.info) {
        return <div className="divCentral"> <h1>Cargando...</h1></div>;
    }

    return (
        <div className="divCentral">
            {/* Cabecera */}
            <Stack direction='row' useFlexGap flexWrap="wrap" justifyContent="space-between" alignItems="center" spacing={1}>
                <Stack direction='row' spacing={2} alignItems="center" justifyContent="flex-start">
                    <MonitorIcon sx={{ fontSize: '5.9vw', color: '#B2C5C5' }} />
                    <Stack direction={'column'}>
                        <h1>Mi Equipo</h1>
                        <h3>{equipo.info.equipo.descripcion}</h3>
                    </Stack>
                </Stack>
            </Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="center" spacing={5}>
                <Stack direction="column" justifyContent="flex-start" sx={{ width: '90%' }} >
                    <Typography variant="h6" m={1}>Información General:</Typography>
                    <Paper elevation={3}>
                        <Stack direction="column" spacing={0.1} m={1.5}>
                            <Typography variant="body1" sx={{ color: '#62A0C0', fontWeight: 'bold' }}>Puesto: {
                                <Link className="non-link-cell" to={`/vista/puesto/${equipo.info.puesto}`}>
                                    <span>{equipo.info.puesto}</span>
                                </Link>
                            }</Typography>
                            <Typography variant="body1" sx={{ color: '#62A0C0', fontWeight: 'bold' }}>Empleado: {
                                <Link className="non-link-cell" to={`/vista/empleado/${equipo.info.empleado}`}>
                                    <span>{equipo.info.empleado}</span>
                                </Link>
                            }</Typography>
                            <Typography variant="body1">Tipo de Equipo:{equipo.info.equipo ? equipo.info.equipo.TipoEquipo.descripcion : null}</Typography>
                            <Typography variant="body1">VPN: {equipo.info.equipo.ipVPN}</Typography>
                            <Typography variant="body1">Ip de Equipo: {equipo.info.equipo.ipEquipo}</Typography>
                            <Typography variant="body1">Dominio: {equipo.info.equipo.dominio.descripcion}</Typography>
                            <Typography variant="body1">MAC: {equipo.info.equipo.mac}</Typography>
                            <Typography variant="body1">Remoto Actualizado: {equipo.info.equipo.remotoActualizado === 1 ? 'si' : 'no'}</Typography>
                            <Typography variant="body1">Placa Madre: {equipo.info.equipo.placaMadre}</Typography>
                            <Typography variant="body1">Teclado: {equipo.componentes.find((c) => c.tipo_componente.idTipoComponente === 3) ? 'si' : 'no'}</Typography>
                            <Typography variant="body1">Mouse: {equipo.componentes.find((c) => c.tipo_componente.idTipoComponente === 2) ? 'si' : 'no'}</Typography>
                            <Typography variant="body1">Auriculares: {equipo.componentes.find((c) => c.tipo_componente.idTipoComponente === 1) ? 'si' : 'no'}</Typography>
                        </Stack>
                    </Paper>

                    <Typography variant="h6" m={1}>CPU:</Typography>
                    <Paper elevation={3}>
                        <Stack direction="column" spacing={0.1} m={1.5}>
                            <Typography variant="body1">Número de Serie: {equipo.cpu ? equipo.cpu.numSerie : null}</Typography>
                            <Typography variant="body1">Descripción: {equipo.cpu ? equipo.cpu.descripcion : null}</Typography>
                        </Stack>
                    </Paper>

                </Stack>
                <Stack direction="column" justifyContent="flex-start" sx={{ width: '90%' }} >
                    <Typography variant="h6">Discos:</Typography>
                    <Paper sx={{ width: '100%', flexGrow: 1, height: '20%' }} elevation={3}>
                        <TreeView
                            aria-label="discos"
                            defaultCollapseIcon={<ExpandMoreIcon />}
                            defaultExpandIcon={<ChevronRightIcon />}
                            sx={{
                                width: '100%',
                                flexGrow: 1,
                                overflowY: 'auto',
                                overflowX: 'hidden',
                                height: '100%',
                            }}>
                            {discos.map((p, index) => (
                                <TreeItem key={index} nodeId={index.toString()} label={<Typography variant="body1" sx={{ color: '#62A0C0', fontWeight: 'bold' }}>{'Disco ' + index + 1}</Typography>}>
                                    <TreeItem key={index + '-' + 1} nodeId={index + '-' + 1} label={'Tipo Disco: ' + p.tipo_componente.descripcion} />
                                    <TreeItem key={index + '-' + 2} nodeId={index + '-' + 2} label={'Capacidad: ' + p.capacidadMemoria} />
                                    <TreeItem key={index + '-' + 3} nodeId={index + '-' + 3} label={p.estaEncriptado === 1 ? 'Encriptado: si' : 'Encriptado: no'}  />
                                </TreeItem>
                            ))}
                        </TreeView>
                    </Paper>
                    <Typography variant="h6">RAM:</Typography>
                    <Paper sx={{ width: '100%', flexGrow: 1, height: '20%', typography: 'p' }} elevation={3}>
                        <TreeView
                            aria-label="discos"
                            defaultCollapseIcon={<ExpandMoreIcon />}
                            defaultExpandIcon={<ChevronRightIcon />}
                            sx={{
                                width: '100%',
                                flexGrow: 1,
                                overflowY: 'auto',
                                overflowX: 'hidden',
                                height: '100%'
                            }}>
                            {ram.map((p, index) => (
                                <TreeItem key={index} nodeId={index.toString()} label={<Typography variant="body1" sx={{ color: '#62A0C0', fontWeight: 'bold' }}>{'Memoria RAM ' + index + 1}</Typography>}>
                                    <TreeItem key={index + '-' + 1} nodeId={index + '-' + 1} label={'Capacidad: ' + p.capacidadMemoria} sx={{ typography: 'p', color: 'black' }} />
                                    <TreeItem key={index + '-' + 2} nodeId={index + '-' + 2} label={'Descripcion: ' + p.descripcion} sx={{ typography: 'p', color: 'black' }} />
                                </TreeItem>
                            ))}
                        </TreeView>
                    </Paper>
                    <Typography variant="h6">Monitores:</Typography>
                    <Paper sx={{ width: '100%', flexGrow: 1, height: '20%', typography: 'p' }} elevation={3}>
                        <TreeView
                            aria-label="discos"
                            defaultCollapseIcon={<ExpandMoreIcon />}
                            defaultExpandIcon={<ChevronRightIcon />}
                            sx={{
                                width: '100%',
                                flexGrow: 1,
                                overflowY: 'auto',
                                overflowX: 'hidden',
                                height: '100%'
                            }}>
                            {monitores.map((p, index) => (
                                <TreeItem key={index} nodeId={index.toString()} label={<Typography variant="body1" sx={{ color: '#62A0C0', fontWeight: 'bold' }}>{'Monitor ' + index + 1}</Typography>}>
                                    <TreeItem key={index + '-' + 1} nodeId={index + '-' + 1} label={'Marca: ' + p.marca} sx={{ typography: 'p', color: 'black' }} />
                                    <TreeItem key={index + '-' + 2} nodeId={index + '-' + 2} label={'Modelo: ' + p.modelo} sx={{ typography: 'p', color: 'black' }} />
                                    <TreeItem key={index + '-' + 3} nodeId={index + '-' + 3} label={'Numero de Serie: ' + p.numSerie} sx={{ typography: 'p', color: 'black' }} />
                                </TreeItem>
                            ))}
                        </TreeView>
                    </Paper>

                </Stack>
            </Stack>


        </div>
    )
}

export default MiEquipo;