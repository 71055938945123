import * as React from 'react';
import { ChairOutlined } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

export default function Silla(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const navigate = useNavigate(); 

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const direccion = props.dir === 'i' ? 'rotate(270deg)' : 'rotate(90deg)';

    const color = props.puesto ? (props.puesto.estado === 'activo' ? '#C20600' : '#67C200') : '#2C4C58' ;

    return (
        <>
            <IconButton
             disableRipple={true}
             onClick={() => props.puesto.puesto !== undefined ? navigate("/vista/puesto/"+ props.puesto.puesto) : null}
             >
                <ChairOutlined
                    sx={{ fontSize: 30, transform: direccion, color: color,}}
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose} />
            </IconButton>

            <Popover
                id="mouse-over-popover"
                sx={{
                    pointerEvents: 'none',
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Typography sx={{ p: 1 }}>{props.puesto ? (props.puesto.estado ? props.puesto.estado : 'inactivo') : ''}</Typography>
            </Popover>
        </>
    )
}