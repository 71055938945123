import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import { DialogContentText, Stack, TextField } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import EditIcon from '@mui/icons-material/Edit';
import { useContext} from 'react';
import AppContext from "../../Context/AppContext";

export default function FormModificarComponente(props) {
  const [open, setOpen] = React.useState(false);
  const [mensajeError, setMensajeError] = React.useState(" ");
  const {token} = useContext(AppContext);

  //Datos
  const [tipo, setTipo] = React.useState('');
  const [equipo, setEquipo] = React.useState('');
  const [equipos, setEquipos] = React.useState(null);
  const [baja, setBaja] = React.useState(false);
  

  const handleChangeTipo= (event) => {
    setTipo(event.target.value);
  }

  const handleChangeEquipo= (event) => {
    setEquipo(event.target.value);
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const limpiarForm = () => {
    setMensajeError(" ");
    setTipo(null);
    setEquipo(null);
  }

  //Conexión API Componente por Id
  const getComponente = async () => {
    try {
      const url = process.env.REACT_APP_API_PATH + '/componente/';
      const response = await fetch(url + props.id, {
        method: "GET",
        headers: {
          token: token
        }
      })
      const res = await response.json()
      setTipo(res.tipo_componente.descripcion);
      setEquipo(res.idEquipo);
      setBaja(res.motivoBaja != null);
    } catch (error) {
      console.error(error);
    }
  };

  //Conexión API
  const getEquipos = async () => {
    try {
      const url = process.env.REACT_APP_API_PATH + '/equipo';
      const response = await fetch(url, {
        method: "GET",
        headers: {
          token: token
        }
      })
      const res = await response.json()
      setEquipos(res);
    } catch (error) {
      console.error(error);
    }
  };
  
  React.useEffect(() => {
    getEquipos()
    getComponente()
  }, []) 

  //Conexión API - Nuevo Componente
  const handleSubmit = () => {
    if (tipo != null) {
      const ComponenteNuevo = {
        idTipoComponente: tipo,
        idEquipo: equipo,
      }
      fetch(process.env.REACT_APP_API_PATH + "/componente/" + props.idComponente, {
        method: "PUT",
        headers: {
          token: token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(ComponenteNuevo)
      })
        .then((response) => {
          if(response.ok){
            return response.json()
          }
          throw new Error('No se pudo modificar el componente.');
        })
        .then((data) => {
          alert("El periferico fue modificado correctamente");
          props.setReloadData(true);
          limpiarForm()
        })
        .catch((error) => { console.log(error); alert("Error. Revise los datos ingresados.") });
      setOpen(false);
    }
    else {
      setMensajeError("Error. Debe completar todos los campos.");
    }
  }


  return (
    <>
      <>
        {props.texto ? (
          <Button variant="contained" disabled={baja} sx={{ backgroundColor: '#62A0C0', textTransform: 'none', "&:hover": {backgroundColor: '#2C4C58',}}} onClick={handleClickOpen}>
            {props.texto}
          </Button>)
          : (
              <EditIcon onClick={handleClickOpen} fontSize='small'/>
          )}
      </>
      <Dialog open={open} onClose={handleClose} maxWidth='xs' fullWidth>
        <DialogTitle>Editar Periférico</DialogTitle>
        <DialogContent>

          {/* ---- Tipo Componente ---- */}
          <FormControl size='small' sx={{ width: '100%', marginTop: 2 }}>
            <InputLabel id="demo-select-small-label">Tipo Componente</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={tipo}
              label="Tipo Componente"
              onChange={handleChangeTipo}
              required
            >
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>
              <MenuItem value={'auriculares'} key={1}>Auriculares</MenuItem>
              <MenuItem value={'mouse'} key={2}>Mouse</MenuItem>
              <MenuItem value={'teclado'} key={3}>Teclado</MenuItem>
            </Select>
          </FormControl>

          {/* ---- Equipo ---- */}
          <FormControl size='small' sx={{ width: '100%', marginTop: 2 }}>
            <InputLabel id="demo-select-small-label">Equipo</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={equipo}
              label="Equipo"
              onChange={handleChangeEquipo}
            >
              <MenuItem value=""><em>None</em></MenuItem>
              {equipos != null ? equipos.map((e, index) => (
                <MenuItem key={index} value={e.id}>
                  {e.id}
                </MenuItem>
              )) : <MenuItem value=""><em>None</em></MenuItem>}
            </Select>
          </FormControl>

          <DialogContentText color="error">{mensajeError}</DialogContentText>

        </DialogContent>

        <DialogActions>
          <Stack spacing={1} width={'100%'} margin={1}>
            <Button type="submit" onClick={handleSubmit} variant="contained" sx={{ backgroundColor: '#62A0C0', textTransform: 'none', "&:hover": {backgroundColor: '#2C4C58',}}}>Registrar</Button>
            <Button onClick={handleClose} variant="contained" sx={{ backgroundColor: '#BABABA', textTransform: 'none', "&:hover": {backgroundColor: '#2C4C58',}}}>Cancelar</Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
}