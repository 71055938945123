import * as React from 'react';
import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Maceta from './Maceta';

export default function Macetas(){
    return(
        <Stack direction={'column'} justifyContent={'space-between'} >
            <Maceta piso={'0'}/>
            <Maceta piso={'0'}/>
            <Maceta piso={'0'}/>
            <Maceta piso={'0'}/>
        </Stack>
    )
}