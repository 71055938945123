import React, { createContext, useState } from 'react';
import Cookies from 'js-cookie';

const AppContext = createContext();
export default AppContext;

export const AppProvider = ({ children }) => {
    const [estaLogueado, setEstaLogueado] = useState(false);
    const [token, setToken] = useState(''); 
    const [rol, setRol] = useState(''); 
    const [url, setUrl] = useState(''); 

    const iniciarSesion = () => {
        setEstaLogueado(true);
    }

    const cerrarSesion = () => {
        setEstaLogueado(false);
        setToken('');
        setRol('');
        setUrl('');
        Cookies.set('token', '');
        Cookies.set('rol', '');
        Cookies.set('redirectURL', '')
    }
    const establecerToken = (credencial) => {
        setToken(credencial);
    }
    const establecerUrl = (url) => {
        setUrl(url);
    }

    return (
        <AppContext.Provider
        value={{
            estaLogueado,
            iniciarSesion,
            cerrarSesion,
            token,
            establecerToken,
            rol, 
            setRol,
            establecerUrl, 
            url
        }}
        >
        {children}
        </AppContext.Provider>
    );
}
