import React from "react";
import cody from '../../images/Cody-Curioso.png';
import codyEscondido from '../../images/Cody-Escondido.png'
import FormControl from '@mui/material/FormControl';
import {Stack, TextField, OutlinedInput, InputLabel } from '@mui/material';
import Button from '@mui/material/Button';
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from 'react-router-dom';
import AppContext from "../Context/AppContext";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Cookies from 'js-cookie';

export default function InicioSesion(){
    const [correo, setCorreo] = React.useState('');
    const [contraseña, setContraseña] = React.useState('');
    const [showPassword, setShowPassword] = React.useState(false);
    const{iniciarSesion, establecerToken, setRol} = React.useContext(AppContext);
    const navigate = useNavigate(); 
    const redirectURL = Cookies.get('redirectURL');


    const handleChangeCorreo= (event) => {
        setCorreo(event.target.value);
    };

    const handleChangeContraseña= (event) => {
        setContraseña(event.target.value);
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword); 
      };
    
      const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if(contraseña != null && correo != null){
            const Datos = {
                email: correo,
                password: contraseña
            }
            try {
                const response = await fetch(process.env.REACT_APP_API_PATH +"/auth/login", {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(Datos),
                });
                const data = await response.json();
                if(data.token){
                    establecerToken(data.token);
                    Cookies.set('token', data.token, { expires: 1/3 }); // 1/3 representa 8 horas
                    Cookies.set('rol', data.usuario.idRol, { expires: 1/3 });
                    iniciarSesion();
                    setRol(data.usuario.idRol); 
                    if(redirectURL){
                        navigate(redirectURL);
                    }
                    else{
                        navigate('/home/primerpiso')
                    }
                    CleanForm();
                }
                else{
                    alert("Error. Revise los datos ingresados.");
                }
              } catch (error) {
                console.log(error);
                alert("Error. Revise los datos ingresados.");
              }
        } 
        else{
            CleanForm();
        }
    } 

    function CleanForm(){
        setContraseña('');
        setCorreo('');
    }

    return(
        <>
            <div className="divCentralInicio" style={{paddingTop:'8%'}}>
                
                <img src={cody} className="codyInicio" hidden={showPassword} alt='cody'></img>
                <img src={codyEscondido} className="codyInicio" hidden={!showPassword} alt='cody tapandose los ojos'></img>
                <h1>Iniciar Sesión</h1>

                <Stack direction={'column'} width={{xs:'60%', sm:'40%'}}> 

                    {/* ---- Correo ---- */}
                    <FormControl sx={{ width:'100%', marginTop: 2 }}>
                        <TextField
                            label="Correo Electrónico"
                            required
                            size="small"
                            value={correo}
                            type='email'
                            onChange={handleChangeCorreo}>
                        </TextField> 
                    </FormControl>

                    {/* ---- Contraseña ---- */}
                    <FormControl sx={{ width:'100%', marginTop: 2 }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password" size="small">Contraseña</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            required
                            size="small"
                            value={contraseña}
                            type={showPassword ? "text" : "password"}
                            onChange={handleChangeContraseña}
                            endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                  >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                  </IconButton>
                                </InputAdornment>
                            }
                            label="Contraseña"
                            />
                    </FormControl>  

                    <Button  type="submit" variant="contained" sx={{
                        backgroundColor:'#62A0C0',
                         textTransform:'none',
                          marginTop: 2,
                          "&:hover": {
                            backgroundColor: '#2C4C58',
                          }
                        }}
                         onClick={handleSubmit}>Iniciar Sesión</Button>
                </Stack> 
            </div>  
        </>
    )
}
