import * as React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import InfoUsuario from '../Usuario/InfoUsuario';
import ListaBarraLateral from './ListaBarraLateral';
import ListaBarraLateralUser from './ListaBarraLateralUser';
import ListaBarraLateralRRHH from './ListaBarraLateralRRHH';
import AppContext from '../Context/AppContext';
import cody from '../../images/Cody-Compu1.png';
import RoutesApp from '../Router/Router'
import '../../App.css'

const drawerWidth = 260;

function ResponsiveDrawer(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const location = useLocation();
  const trigger = useScrollTrigger({threshold:10});

  const { cerrarSesion, rol } = React.useContext(AppContext);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleCerrarSesion = (event) => {
    event.preventDefault();
    cerrarSesion();
  }

  const BotonCerrarSesion = () => {
    return (
      <Button
        variant="contained"
        sx={{
          backgroundColor: '#FFFFFF',
          color: '#543440',
          textTransform: 'none',
          width: '100%',
          height: '30px',
          position: 'absolute',
          bottom: '10px',
          "&:hover": {
            backgroundColor: '#62A0C0',
          }
        }}
        onClick={handleCerrarSesion}>
        Cerrar Sesión
      </Button>
    )
  }

  const isLoginPage = location.pathname === '/login';

  const drawer = (
    <div className='barra-lateral'>
      <div className='centerDiv'>
        <img src={cody} className='codyBarra' alt='cody trabajando'></img>
        <h4>SISTEMA DE GESTIÓN DE ACTIVOS</h4>
      </div>

      {/* Items segun rol */}
      {
        rol === 1 ? <ListaBarraLateral/> : (
        rol === 2 ? <ListaBarraLateralRRHH/> : <ListaBarraLateralUser />)
      }

      {/* Pie de Barra Lateral */}
      {rol === 3 ? <InfoUsuario/> : <BotonCerrarSesion/>}
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex', height: '100%' }}>
      <Slide appear={false} direction="down" in={!trigger} sx={{width:'100%'}}>
        <AppBar
          id={'AppBar'}
          style={{ background: 'transparent', boxShadow: 'none' }}
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
            display: { xs: isLoginPage ? 'none' : 'block' },
          }}
        >
          <Toolbar sx={{ paddingLeft: 0 }}>
            {!isLoginPage && (
              <IconButton
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ ml: 2, mr: 2 }}
              >
                <MenuIcon sx={{ color: '#2C4C58' }} />
              </IconButton>
            )}
          </Toolbar>
        </AppBar>
      </Slide>

      <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}>
        <Drawer
          id={'BarraLateral'}
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            display: { xs: isLoginPage ? 'none' : 'block', sm: 'none' },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          id={'BarraLateral'}
          variant="permanent"
          sx={{
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            display: { xs: 'none', sm: isLoginPage ? 'none' : 'block' },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component="main" sx={{ flexGrow: 1, p: 3, paddingTop:{xs: '10%', sm:'4%'}, width: { sm: `calc(100% - ${drawerWidth}px)` } }}>
        <RoutesApp />
      </Box>
    </Box>
  );
}

ResponsiveDrawer.propTypes = {
  window: PropTypes.func,
};

export default ResponsiveDrawer;
