import React from "react";
import {Stack } from '@mui/material';
import AppContext from "../Context/AppContext";
import { useContext} from 'react';
import PlantillaPuestos from "./PlantillaPuestos";


export default function Subsuelo(){
    const { token, cerrarSesion} = useContext(AppContext);
    const [puestos, setPuestos] = React.useState([]);
    const [puestosSS, setPuestosSS] = React.useState([]);

    //Conexión API Puestos 
    const getPuestos = async () => {
        try {
        const url = process.env.REACT_APP_API_PATH + '/puesto';
        const response = await fetch(url, {
            method: "GET",
            headers: {
                token: token
            },
        });
        const res = await response.json();
        setPuestos(res);
        setPuestosSS(res.filter((p) => p.idPlanta === 1));
        console.log(res.status);
        } 
        catch (error) {
            console.error(error);
            cerrarSesion();
        }
    };

    React.useEffect(() => {
        getPuestos();
    }, []);

    if (!puestosSS || !puestosSS) {
        return <div className="divCentral"> <h1>Cargando...</h1></div>;
    }

    return(
        <div className="divCentral">
            <h1>Subsuelo</h1>
                <Stack 
                    direction="row"
                    spacing={5}
                    sx={{
                        overflowX: 'auto',
                        overflowY: 'hidden',
                        flexWrap: 'nowrap',
                        maxWidth: '100%',
                        marginTop: '4%',
                        paddingBottom: '4%',
                    }}>
                    <PlantillaPuestos puestos={puestosSS.slice(0, 12)} color={1}></PlantillaPuestos>
                    <PlantillaPuestos puestos={puestosSS.slice(12, 24)} color={1}></PlantillaPuestos>
                    <PlantillaPuestos puestos={puestosSS.slice(24, 37)} color={1}></PlantillaPuestos>
                    <PlantillaPuestos puestos={puestosSS.slice(36, 48)} color={1}></PlantillaPuestos>
                </Stack>
           
        </div>
    )
}