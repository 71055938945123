import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import { DialogContentText, Stack, TextField } from '@mui/material';

import { useContext} from 'react';
import AppContext from '../../Context/AppContext';


export default function Contrasenia() {
  const [open, setOpen] = React.useState(false);
  const [mensajeError, setMensajeError] = React.useState(" ");
  const {token} = useContext(AppContext);

  //Datos
  const [actualPassword, setActualPassword] = React.useState('');
  const [nuevaPassword, setNuevaPassword] = React.useState('');
  const [usuario, setUsuario] = React.useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    limpiarForm();
    setOpen(false);
  };


  //Conexión API - Modificar contraseña
  const handleSubmit = () => {
    if (nuevaPassword && actualPassword) {
      const ComponenteNuevo = {
        nuevaPassword: nuevaPassword, 
        actualPassword: actualPassword,

      }
      fetch(process.env.REACT_APP_API_PATH + "/usuario/cambiarPassword", {
        method: "PUT",
        headers: {
          token: token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(ComponenteNuevo)
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          alert("La contraseña fue modificada correctamente");
          limpiarForm()
        })
        .catch((error) => { console.log(error); alert("Error. Revise los datos ingresados.") });
      setOpen(false);
      limpiarForm();
    }
    else {
      setMensajeError("Error. Debe ingresar todos los datos para continuar.");
    }
  }


  const limpiarForm = () => {
    setMensajeError(" ");
    setActualPassword('');
    setNuevaPassword('');
  }

  return (
    <div>
      <Button variant="contained" sx={{ backgroundColor: '#62A0C0', textTransform: 'none' }} onClick={handleClickOpen} fullWidth>
        Modificar Contraseña
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth>
        <DialogTitle>Modificar Contraseña</DialogTitle>
        <DialogContent>
          
          <FormControl fullWidth>

            {/* ---- Contraseña Actual ---- */}
            <TextField
              sx={{ marginTop: 2 }}
              fullWidth
              type='password'
              label="Contraseña Actual"
              required
              size="small"
              value={actualPassword}
              onChange={(e) => setActualPassword(e.target.value)}
            />

            {/* ---- Contraseña Nueva---- */} 
            <TextField
                sx={{ marginTop: 2 }}
                fullWidth
                label="Contraseña Nueva"
                type='password'
                required
                size="small"
                value={nuevaPassword}
                onChange={(e) => setNuevaPassword(e.target.value)}
            />
          </FormControl>

          <DialogContentText color="error">{mensajeError}</DialogContentText>

        </DialogContent>

        <DialogActions>
          <Stack spacing={1} width={'100%'} margin={1}>
            <Button type="submit" onClick={handleSubmit} variant="contained" sx={{ backgroundColor: '#62A0C0', textTransform: 'none', "&:hover": {backgroundColor: '#2C4C58',}}}>Registrar</Button>
            <Button onClick={handleClose} variant="contained" sx={{ backgroundColor: '#BABABA', textTransform: 'none', "&:hover": {backgroundColor: '#2C4C58',}}}>Cancelar</Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </div>
  );
}