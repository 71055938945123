import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {Stack} from '@mui/material';
import FormNuevaMemoria from './NuevaMemoria';
import FormNuevoComponente from './NuevoComponente';
import FormNuevoDisco from './NuevoDisco';
import FormNuevoMonitor from './NuevoMonitor';
import ComponentesContext from '../../Context/ComponenteContext';

export default function CrearComponente(props) {
  const {isOpen, setIsOpen} = React.useContext(ComponentesContext);

  const handleClickOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <Button variant="contained" sx={{ backgroundColor: '#62A0C0', textTransform: 'none', "&:hover": {backgroundColor: '#2C4C58',}}} onClick={handleClickOpen}>
        Nuevo Componente
      </Button>
      <Dialog open={isOpen} onClose={handleClose} maxWidth='xs' fullWidth>
        <DialogTitle>Nuevo Componente</DialogTitle>
        <DialogContent>
          <Stack spacing={2} >
            <FormNuevoComponente setReloadData={props.setReloadData} />
            <FormNuevoMonitor setReloadData={props.setReloadData} />
            <FormNuevoDisco setReloadData={props.setReloadData} />
            <FormNuevaMemoria setReloadData={props.setReloadData} />
          </Stack>
        </DialogContent>

        <DialogActions>
          <Stack spacing={1} width={'100%'} margin={1}>
            <Button onClick={handleClose} variant="contained" sx={{ backgroundColor: '#BABABA', textTransform: 'none', "&:hover": {backgroundColor: '#2C4C58',}}}>Cerrar</Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </div>
  );
}