import React, { useContext, useState} from "react";
import { DataGrid, GridActionsCellItem, esES } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import IconButton from '@mui/material/IconButton';
import FormNuevoEmpleado from "../Formularios/Crear/NuevoEmpleado";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import AppContext from "../Context/AppContext";
import FormModificarEmpleado from "../Formularios/Modificar/ModificarEmpleado";
import BorrarEmpleado from "../Formularios/Borrar/BorrarEmpleado";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import SearchIcon from '@mui/icons-material/Search';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Link } from "react-router-dom";
import Resetear from "../Formularios/Admin/Resetear";
import CustomNoRowsOverlay from "./CustomNoRowsOverlay";


//Iconos acciones
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

var today = new Date();


//Conversion de tabla a PDF
const DownloadPDFButton = ({ data }) => {
  const handleDownloadPDF = () => {
    const doc = new jsPDF();
    doc.text("Empleados", 10, 10)
    const tableData = data.map((row) => [
      row.nombre,
      row.estado,
      row.correo,
      row.puesto,
    ]);

    doc.autoTable({
      head: [['Empleado', 'Estado', 'Correo', 'Puesto']],
      body: tableData,
    });

    doc.setFontSize(12);
    var now = today.toLocaleString();
    doc.text(now, 40, 10)
    doc.save('empleados.pdf');
  };

  return (
    <Button variant="contained" onClick={handleDownloadPDF} sx={{backgroundColor:'#62A0C0', textTransform:'none', "&:hover": {backgroundColor: '#2C4C58',}}}>
      Descargar
    </Button>
  );
};

export default function ConsultaEmpleados(){
  const [anchorEl, setAnchorEl] = React.useState(null); //popover
  const [reloadData, setReloadData] = React.useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [empleados, setEmpleados] = useState([]);
  const [pageSize, setPageSize] = useState(5);
  const [filters, setFilters] = useState({
    idPlanta: '',
    estado: '',
    busqueda: '',
  });

  const {token} = useContext(AppContext);

  const open = Boolean(anchorEl);

  //Filtrado de datos según selección
  const filteredData = ConvertirObjeto(empleados).filter(item => {
    return (
      (filters.idPlanta === '' || item.idPlanta.toString() === filters.idPlanta) &&
      (filters.estado === '' || item.estado === filters.estado) &&
      (filters.busqueda === '' ||
        item.id.toString().includes(filters.busqueda) ||
        item.puesto.includes(filters.busqueda) ||
        item.nombre.includes(filters.busqueda) ||
        item.correo.includes(filters.busqueda))
    );
  });

  const columns = [
    {
      field: 'nombre',
      headerName: 'Nombre',
      width: 'flex',
      flex: 1,
      minWidth: 180,
      renderCell: (params) => {
        return (
          <>
            <Link className="non-link-cell" to={`/vista/empleado/${params.value}`}>
              <IconButton>
                <PersonOutlineOutlinedIcon color="#2C4C58" fontSize="medium" />
              </IconButton>
              <span>{params.value}</span>
            </Link>
          </>);
      }
    },
    {
      field: 'correo',
      headerName: 'Correo',
      width: 'flex',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'puesto',
      headerName: 'Puesto',
      width: 'flex',
      flex: 0.75,
      minWidth: 100,
      renderCell: (params) => (
        <Link className="non-link-cell" to={`/vista/puesto/${params.value}`}>
          <span>{params.value}</span>
        </Link>
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      minWidth: 200,
      getActions: (params) => [
        <GridActionsCellItem
          icon={!params.row.puesto ? <BorrarEmpleado empleadoId={params.id} setReloadData={setReloadData} /> : <DeleteIcon fontSize="small" color="disabled" />}
          label="Delete"
          size="medium"
          width="auto"
        />,
        <GridActionsCellItem
          icon={!params.row.puesto ? <FormModificarEmpleado empleado={params.id} setReloadData={setReloadData} /> : <EditIcon fontSize="small" color="disabled" />}
          label="Edit"
          size="medium"
          width="auto"
        />,
        <GridActionsCellItem
          icon={<Resetear empleado={params.id} />}
          label="Resetear Contraseña"
          size="medium"
          width="auto"
        />,
      ],
    },
  ];

  const getEmpleados = async () => {
    try {
      const url = process.env.REACT_APP_API_PATH + '/empleado' + (reloadData ? `?timestamp=${Date.now()}` : '');
      const response = await fetch(url, {
        method: "GET",
        headers: {
          token: token
        },
      });
      const res = await response.json();
      setEmpleados(res);
      setReloadData(false);
      console.log(res.status);
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    getEmpleados();
  }, [reloadData]);


  const rows = empleados !== null ? ConvertirObjeto(empleados) : { nombre:'Nombre', estado:'activo'};

  //Filas Seleccionadas
  const onRowsSelectionHandler = (ids) => {
    const selectedRowsData = ids.map((id) => rows.find((row) => row.id === id));
    setSelectedRows(selectedRowsData)
  };

  return (
    <div className="divCentral">
      <Stack direction='row' useFlexGap flexWrap="wrap" alignItems="center" justifyContent="space-between" paddingBottom={'2%'}>
        <h1>Empleados</h1>
        <FormNuevoEmpleado setReloadData={setReloadData}></FormNuevoEmpleado>
      </Stack>

      <Stack direction='row' useFlexGap flexWrap="wrap" alignItems="center" spacing={'10%'} justifyContent={{sx:"center", sm:"space-between"}}>

        {/* Filtro por Planta */}
        <FormControl sx={{ m: 1, minWidth:{xs:'45%', sm:120}, mr:0, ml:0 }} size="small">
          <InputLabel id="demo-select-small-label">Planta</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={filters.idPlanta}
            onChange={e => setFilters({ ...filters, idPlanta: e.target.value })}
            label="Planta"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={'1'}>SS</MenuItem>
            <MenuItem value={'2'}>PB</MenuItem>
            <MenuItem value={'3'}>1P</MenuItem>
          </Select>
        </FormControl>

        {/* Busqueda */}
        <FormControl sx={{ m: 1, width:{xs:'100%', sm:'25ch'}, mr:0, ml:0}} variant="outlined" size="small">
          <InputLabel htmlFor="outlined-adornment-password">Buscar...</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            value={filters.busqueda}
            onChange={e => setFilters({ ...filters, busqueda: e.target.value })}
            endAdornment={
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            }
            label="Buscar"
          />
        </FormControl>
      </Stack>

      <Box
        sx={{
          height: 400,
          width: '100%',
          paddingTop: '1%'
        }}>
        <DataGrid
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          pageSize={pageSize}
          rowsPerPageOptions={[5, 10, 20]}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          pagination
          rows={empleados ? filteredData : empleados}
          columns={columns}
          checkboxSelection
          disableColumnFilter
          disableDensitySelector
          disableColumnSelector
          headerClassName="headerCeleste"
          onRowSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
          selectionModel={selectedRows}
          slots={{
            noRowsOverlay: CustomNoRowsOverlay,
          }}
        />
      </Box>
      <Stack direction="row" alignItems="center" justifyContent="center" sx={{ width: '100%', paddingTop: '1%' }}>
        <DownloadPDFButton data={selectedRows.length !== 0 ? selectedRows : rows} />
      </Stack>
    </div>
  )
}

function ConvertirObjeto(empleadosJson) {
  const dataProcesada = [];
  empleadosJson.map((e, index) => {
    dataProcesada.push({
      id: e.idEmpleado,
      nombre: e.nombre,
      correo: e.email,
      usuario: e.idUsuario,
      tieneUsuario: e.idUsuario ? true : false, 
      estado: e.estado,
      idPlanta: e.idPlanta,
      puesto: e.puesto,
    });
  });
  return dataProcesada;
}  