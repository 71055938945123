import React from "react";
import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { useContext } from 'react';
import AppContext from "../Context/AppContext";
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { Link } from "react-router-dom";
import Contrasenia from "../Formularios/Usuario/Contraseña";

export default function MiPerfil(){
    const [empleado, setEmpleado] = React.useState();
    const { token} = useContext(AppContext);

    //Conexión API Empleados por Nombre
    const getEmpleado = async () => {
        try {
            const url = process.env.REACT_APP_API_PATH + '/usuario/info ';
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    token: token
                },
            });
            const res = await response.json();
            setEmpleado(res);
        } catch (error) {
            console.error(error);
        }
    };

    React.useEffect(() => {
        getEmpleado();
    }, []);

    
    if (!empleado) {
        return <div className="divCentral"> <h1>Cargando...</h1></div>;
    }
    
    return (
        <div className="divCentral">
            {/* Cabecera */}
            <Stack direction='row' useFlexGap flexWrap="wrap" justifyContent="space-between" alignItems="center" spacing={1}>
                <Stack direction='row' spacing={2} alignItems="center" justifyContent="flex-start">
                    <AccountCircleOutlinedIcon sx={{ fontSize: 85, color: '#B2C5C5' }} />
                    <Stack direction="column" spacing={0}>
                        <h1>Mi Perfil</h1>
                        <Typography variant="subtitle2">@{empleado.usuario}</Typography>
                    </Stack>
                </Stack>
                <Stack direction='row' spacing={2} alignItems="center" justifyContent="flex-end">
                    <Contrasenia/>
                </Stack>
            </Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="flex-start" spacing={5}>
                <Stack direction="column" justifyContent="flex-start" width={{ sm: '50%', xs: '90%' }} >
                    <Typography variant="h6" m={1}>Información General:</Typography>
                    <Paper elevation={3}>
                        <Stack direction="column" spacing={0.1} m={1.5}>
                        <Typography variant="body1" sx={{ color: '#62A0C0', fontWeight: 'bold' }}>Nombre: {empleado.nombre}</Typography>
                            <Typography variant="body1" sx={{ color: '#62A0C0', fontWeight: 'bold' }}>Puesto: {
                                <Link className="non-link-cell" to={`/vista/puesto/${empleado.puesto}`}>
                                    <span>{empleado.puesto}</span>
                                </Link>}</Typography>
                            <Typography variant="body1" sx={{ color: '#62A0C0', fontWeight: 'bold' }}>Equipo: {
                                <Link className="non-link-cell" to={`/vista/equipo/${empleado.descripcion}`}>
                                    <span>{empleado.descripcion}</span>
                                </Link>
                            }</Typography>
                            <Typography variant="body1">ID Empleado: {empleado.idEmpleado}</Typography>
                            <Typography variant="body1">DNI: {empleado.dni}</Typography>
                            <Typography variant="body1">Correo: {empleado.email}</Typography>
                            <Typography variant="body1">Estado: {empleado.estado}</Typography>
                            <Typography variant="body1">Planta: {empleado.idPlanta}</Typography>
                        </Stack>
                    </Paper>

                </Stack>

            </Stack>
            
        </div>
    )

}