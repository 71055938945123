import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import { DialogContentText, Stack, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useContext} from 'react';
import AppContext from '../../Context/AppContext';
import ComponentesContext from '../../Context/ComponenteContext';

export default function FormNuevaMemoria(props) {
  const [open, setOpen] = React.useState(false);
  const [mensajeError, setMensajeError] = React.useState(" ");
  const {token} = useContext(AppContext);
  const {setIsOpen} = React.useContext(ComponentesContext);
  const [equipos, setEquipos] = React.useState('');
  const [capacidad, setCapacidad] = React.useState('');
  const [descripcion, setDescripcion] = React.useState('');
  const [equipo, setEquipo] = React.useState(null);
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    limpiarForm();
    setOpen(false);
    setIsOpen(false);
  };


  //Conexión API - Nuevo Componente
  const handleSubmit = () => {
    if (capacidad !== '' && descripcion !== '') {
      const DiscoNuevo = {
        capacidadMemoria: capacidad,
        descripcion: descripcion,
        idEquipo: equipo,
        idTipoComponente: 4

      }
      fetch(process.env.REACT_APP_API_PATH + "/componente/", {
        method: "POST",
        headers: {
          token: token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(DiscoNuevo)
      })
        .then((response) => {
          if(response.ok){
            return response.json()
          }
          throw new Error('No se pudo crear la memoria.');
        })
        .then((data) => {
          alert("La RAM fue creada correctamente");
          props.setReloadData(true);
          limpiarForm()
        })
        .catch((error) => { console.log(error); alert("Error. Revise los datos ingresados.") });
      setOpen(false);
      limpiarForm();
    }
    else {
      setMensajeError("Error. Debe completar todos los campos.");
    }
  }


  //Conexión API - Equipos
  const getEquipos = async () => {
    try {
      const url = process.env.REACT_APP_API_PATH + '/equipo';
      const response = await fetch(url, {
        method: "GET",
        headers: {
          token: token
        }
      })
      const res = await response.json()
      setEquipos(res);
    } catch (error) {
      console.error(error);
    }
  };
  

  React.useEffect(() => {
    getEquipos()
  }, []) 


  const limpiarForm = () => {
    setMensajeError(" ");
    setDescripcion('');
    setCapacidad('');
    setEquipo('');
  }


  return (
    <div>
      <Button variant="contained" sx={{ backgroundColor: '#62A0C0', textTransform: 'none', "&:hover": {backgroundColor: '#2C4C58',}}} onClick={handleClickOpen} fullWidth>
        Nueva Memoria RAM
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth>
        <DialogTitle>Nueva Memoria</DialogTitle>
        <DialogContent>

          {/* ---- Capacidad ---- */}
          <TextField
            sx={{ marginTop: 2 }}
            fullWidth
            label="Capacidad"
            required
            size="small"
            value={capacidad}
            onChange={(e) => setCapacidad(e.target.value)}
          />

          {/* ---- Descripcion ---- */}
          <TextField
            sx={{ marginTop: 2 }}
            fullWidth
            label="Descripción"
            required
            size="small"
            value={descripcion}
            onChange={(e) => setDescripcion(e.target.value)}
          />

          {/* ---- Equipo ---- */}
          <FormControl size='small' sx={{ width: '100%', marginTop: 2 }}>
            <InputLabel id="demo-select-small-label">Equipo</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={equipo}
              label="Equipo"
              onChange={(e) => setEquipo(e.target.value)}
            >
              <MenuItem value={null}><em>None</em></MenuItem>
              {equipos !== '' ? equipos.map((e, index) => (
                <MenuItem key={index} value={e.id}>
                  {e.descripcion}
                </MenuItem>
              )) : <MenuItem value={null}><em>None</em></MenuItem>}
            </Select>
          </FormControl>
          <DialogContentText color="error">{mensajeError}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Stack spacing={1} width={'100%'} margin={1}>
            <Button type="submit" onClick={handleSubmit} variant="contained" sx={{ backgroundColor: '#62A0C0', textTransform: 'none', "&:hover": {backgroundColor: '#2C4C58',}}}>Registrar</Button>
            <Button onClick={handleClose} variant="contained" sx={{ backgroundColor: '#BABABA', textTransform: 'none', "&:hover": {backgroundColor: '#2C4C58',}}}>Cancelar</Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </div>
  );
}