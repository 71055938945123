import React, { useContext} from "react";
import IconButton from '@mui/material/IconButton';
import AppContext from "../../Context/AppContext";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from "@mui/material";

import CancelIcon from '@mui/icons-material/Cancel';

export default function CrearUsuario(props) {
  const [open, setOpen] = React.useState(false);
  const {token} = useContext(AppContext);

  //Conexión API
  const handleDelete= () => {
    fetch(process.env.REACT_APP_API_PATH + "/usuario/empleado/" + props.empleado, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: token,
      }
    })
    .then((response) => response.json())
    .then((data) => {console.log(data);
      alert('El componente fue creado correctamente.');})
    .catch((error) => {console.log(error);
      alert('Error. No se pudo crear el componente.')
    });
    props.setReloadData(true);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event) => {
    event.preventDefault();
    handleDelete();
    setOpen(false);
  };

  const handleCancel = (event) => {
    event.preventDefault(); 
    setOpen(false);
  };

  return (
    <div>
      {/* Botón Dar de Baja */}
      <>
        {props.texto ? (
          <Button variant="contained" sx={{ backgroundColor: '#62A0C0', textTransform: 'none' }} onClick={handleClickOpen}>
            {props.texto}
          </Button>)
          : (
            <IconButton onClick={handleClickOpen}>
                <CancelIcon color="error" fontSize="medium"/>
            </IconButton>
          )}
      </>


      {/* Mensaje */}
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Crear una cuenta a "}{props.nombre}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography>Se le creara una cuenta a este empleado.</Typography>
            <Typography>Sus credenciales serán lo registrado en SIGEAC.</Typography>
            <div>
                <Typography>• Usuario: correo</Typography>
                <Typography>• Contraseña: DNI</Typography>
            </div>
           
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} variant="outlined" sx={{color:'#62A0C0', borderColor:'#62A0C0', "&:hover": {backgroundColor: '#2C4C58',}}}>Cancelar</Button>
          <Button onClick={handleClose} variant="contained" autoFocus sx={{backgroundColor:'#62A0C0', "&:hover": {backgroundColor: '#2C4C58',}}} >Crear Usuario</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
