import React from "react";
import { Route, Routes, Navigate, useNavigate} from "react-router-dom";
import ConsultaEquipos from "../PantallasConsulta/Equipos";
import ConsultaPuestos from "../PantallasConsulta/Puestos";
import ConsultaHistorial from "../PantallasConsulta/Historial";
import ConsultaEmpleados from "../PantallasConsulta/Empleados";
import ConsultaComponentes from "../PantallasConsulta/Componentes";
import PantallaPaginaNoEncontrada from "../PantallasError/PaginaNoEncontrada";
import PantallaErrorServidor from "../PantallasError/ErrorServidor";
import GenerarQr from "../PantallasGenerar/qr";
import GenerarDocumento from "../PantallasGenerar/documentos";
import { useEffect } from "react";

import AppContext from "../Context/AppContext";

//Autenticacion
import InicioSesion from '../Autenticacion/InicioSesion';

//Vistas Individuales
import Equipo from "../PantallasVistas/Equipo";
import Puesto from "../PantallasVistas/Puesto";
import Empleado from "../PantallasVistas/Empleado";
import Componente from "../PantallasVistas/Componente";

//Home
import Subsuelo from "../Home/Subsuelo";
import PrimerPiso from "../Home/PrimerPiso";
import PlantaBaja from "../Home/PlantaBaja";

//Usuario
import MiPerfil from "../Usuario/MiPerfil";
import MiEquipo from "../Usuario/MiEquipo";
import ConsultaDocumentos from "../PantallasConsulta/Documentos";
import LectorQr from "../PantallasConsulta/LectorQr";
import PantallaSinEquipo from "../PantallasError/SinEquipo";

//RRHH
import ConsultaDocumentosRRHH from "../PantallasConsulta/DocumentosRRHH";

//Admin
import ConsultaDocumentosAdmin from "../PantallasConsulta/DocumentosAdmin";

export default function RoutesApp(){
  const navigate = useNavigate();
  const {rol, url, establecerUrl} = React.useContext(AppContext);

    if(rol === 3){

      return(
        <Routes>
          <Route index path="/" element={<Navigate to={'consulta/documentos'}/>}></Route>
          <Route path="error/paginaNoEncontrada" element={<PantallaPaginaNoEncontrada/>}></Route>
          <Route path="error/servidor" element={<PantallaErrorServidor/>}></Route>
          <Route path="error/sinEquipo" element={<PantallaSinEquipo/>}></Route>
          <Route path="vista/equipo/:id" element={<Equipo/>} />
          <Route path="vista/puesto/:id" element={<Puesto/>} />
          <Route path="vista/empleado/:id" element={<Empleado/>} />
          <Route path="vista/componente/:id" element={<Componente/>} />
          <Route path="consulta/documentos" element={<ConsultaDocumentos/>}></Route>
          <Route path="perfil" element={<MiPerfil/>}></Route>
          <Route path="equipo" element={<MiEquipo/>}></Route>
          <Route path="login" element={<InicioSesion/>}></Route>
          <Route  path="lector/qr" element={<LectorQr/>}></Route>
          <Route path="*" element={<Navigate to={'consulta/documentos'}/>}></Route>
        </Routes>
      )
    }
    else{

      if(rol === 2){

        return(
          <Routes>
            <Route index path="/" element={<Navigate to={'consulta/documentos'}/>}></Route>
            <Route path="error/paginaNoEncontrada" element={<PantallaPaginaNoEncontrada/>}></Route>
            <Route path="error/servidor" element={<PantallaErrorServidor/>}></Route>
            <Route path="vista/equipo/:id" element={<Equipo/>} />
            <Route path="vista/puesto/:id" element={<Puesto/>} />
            <Route path="vista/empleado/:id" element={<Empleado/>} />
            <Route path="vista/componente/:id" element={<Componente/>} />
            <Route path="error/sinEquipo" element={<PantallaSinEquipo/>}></Route>
            <Route path="consulta/documentos" element={<ConsultaDocumentosRRHH/>}></Route>
            <Route path="login" element={<InicioSesion/>}></Route>
            <Route path="lector/qr" element={<LectorQr/>}></Route>
            <Route path="*" element={<Navigate to={'consulta/documentos'}/>}></Route>
          </Routes>
        )
      }
      else{
        if(rol === 1){

          return(
            <Routes>
            <Route index path="/" element={<Navigate to={'home/primerpiso'}/>}></Route>
            <Route path="error/paginaNoEncontrada" element={<PantallaPaginaNoEncontrada/>}></Route>
            <Route path="error/servidor" element={<PantallaErrorServidor/>}></Route>
            <Route path="vista/equipo/:id" element={<Equipo/>} />
            <Route path="vista/puesto/:id" element={<Puesto/>} />
            <Route path="vista/empleado/:id" element={<Empleado/>} />
            <Route path="vista/componente/:id" element={<Componente/>} />
            <Route path="consulta/documentos" element={<ConsultaDocumentosAdmin/>}></Route>
            <Route path="consulta/puestos" element={<ConsultaPuestos/>}></Route>
            <Route path="consulta/equipos" element={<ConsultaEquipos/>}></Route>
            <Route path="consulta/historial" element={<ConsultaHistorial/>}></Route>
            <Route path="consulta/empleados" element={<ConsultaEmpleados/>}></Route>
            <Route path="consulta/componentes" element={<ConsultaComponentes/>}></Route>
            <Route path="generar/qr" element={<GenerarQr/>}></Route>
            <Route path="generar/documentos" element={<GenerarDocumento/>}></Route>
            <Route path="home/subsuelo" element={<Subsuelo/>}></Route>
            <Route path="home/plantabaja" element={<PlantaBaja/>}></Route>
            <Route path="home/primerpiso" element={<PrimerPiso/>}></Route>
            <Route path="login" element={<InicioSesion/>}></Route>
            <Route path="lector/qr" element={<LectorQr/>}></Route>
            <Route path="*" element={<Navigate to={'error/paginaNoEncontrada'}/>}></Route>
          </Routes>
          )
        }
        else{
          return(
            <Routes>
              <Route path="login" element={<InicioSesion/>}></Route>
              <Route path="*" element={<Navigate to={'/login'}/>}></Route>
            </Routes>
            )
        }
      }
  
    }
}