import React from 'react';
import cody from '../../images/Cody-Archivos.png'

export default function PantallaSinEquipo(){
    return(
        <div className="divError">
            <img src={cody} className="codyError" alt='imagen-error'></img>
            <h1>Aún no tienes un equipo asignado.</h1>
            <h3>Estamos trabajando en eso.</h3>
            <p style={{margin:'1%'}}>Cuando se te asigne un equipo podrás acceder a la información desde esta sección.</p>

        </div>
    )
    
}