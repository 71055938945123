import React from "react";
import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { useParams } from 'react-router-dom';
import { useContext } from 'react';
import AppContext from "../Context/AppContext";
import { Link } from "react-router-dom";

import StorageIcon from '@mui/icons-material/Storage'; //discos
import MemoryIcon from '@mui/icons-material/Memory'; //memoria
import KeyboardIcon from '@mui/icons-material/Keyboard'; //teclado
import HeadphonesIcon from '@mui/icons-material/Headphones'; //auricular
import MouseIcon from '@mui/icons-material/Mouse'; //mouse
import MonitorIcon from '@mui/icons-material/Monitor'; //monitor

import FormModificarMonitor from "../Formularios/Modificar/ModificarMonitor";
import FormModificarDisco from "../Formularios/Modificar/ModificarDisco";
import FormModificarMemoria from "../Formularios/Modificar/ModificarMemoria";
import FormModificarComponente from "../Formularios/Modificar/ModificarComponente";
import BorrarComponente from "../Formularios/Borrar/BorrarComponente";


function Componente() {
    const [componente, setComponente] = React.useState();
    const [reloadData, setReloadData] = React.useState(false);
    const { id } = useParams();
    const { token } = useContext(AppContext);

    //Conexión API Componente por Id
    const getComponente = async () => {
        try {
            const url = process.env.REACT_APP_API_PATH + '/componente/';
            const response = await fetch(url + id + (reloadData ? `?timestamp=${Date.now()}` : ''), {
                method: "GET",
                headers: {
                    token: token
                }
            })
            const res = await response.json()
            setComponente(res);
            setReloadData(false);
        } catch (error) {
            console.error(error);
        }
    };

    React.useEffect(() => {
        getComponente();
    }, [reloadData])


    if (!componente || !componente.tipo_componente.idTipoComponente) {
        return <PantallaCargando />;
    }
    return (
        <div className="divCentral">
            {/* Cabecera */}
            <Stack direction='row' useFlexGap flexWrap="wrap" justifyContent="space-between" alignItems="center" spacing={1}>
                <Stack direction='row' spacing={2} alignItems="center" justifyContent="flex-start">
                    {componente.tipo_componente.idTipoComponente === 7 ? <MonitorIcon sx={{ fontSize: 85, color: '#B2C5C5' }} /> :
                        componente.tipo_componente.idTipoComponente === 4 ? <MemoryIcon sx={{ fontSize: 85, color: '#B2C5C5' }} /> :
                            componente.tipo_componente.idTipoComponente === 2 ? <MouseIcon sx={{ fontSize: 85, color: '#B2C5C5' }} /> :
                                componente.tipo_componente.idTipoComponente === 1 ? <HeadphonesIcon sx={{ fontSize: 85, color: '#B2C5C5' }} /> :
                                    componente.tipo_componente.idTipoComponente === 3 ? <KeyboardIcon sx={{ fontSize: 85, color: '#B2C5C5' }} /> : <StorageIcon sx={{ fontSize: 85, color: '#B2C5C5' }} />}
                    <Stack direction="column" spacing={0}>
                        <h1>{componente.tipo_componente.descripcion}  #{componente.idComponente}</h1>
                    </Stack>

                </Stack>
                <Stack direction='row' spacing={2} alignItems="center" justifyContent="flex-end">
                    {componente.tipo_componente.idTipoComponente === 4 ? <FormModificarMemoria texto="Editar" id={id} setReloadData={setReloadData} /> :
                        componente.tipo_componente.idTipoComponente === 7 ? <FormModificarMonitor texto="Editar" id={id} setReloadData={setReloadData} /> :
                            componente.tipo_componente.idTipoComponente === 1 || componente.tipo_componente.idTipoComponente === 2 || componente.tipo_componente.idTipoComponente === 3 ? <FormModificarComponente texto="Editar" id={id} /> :
                                <FormModificarDisco texto="Editar" id={id} setReloadData={setReloadData} />}
                    <BorrarComponente texto="Dar de Baja" componenteId={id} setReloadData={setReloadData} motivoBaja={componente.motivoBaja != null}></BorrarComponente>
                </Stack>
            </Stack>
            <>
                {componente.tipo_componente.idTipoComponente === 4 ? <VistaRAM componente={componente} /> :
                    componente.tipo_componente.idTipoComponente === 7 ? <VistaMonitor componente={componente} /> :
                        componente.tipo_componente.idTipoComponente === 1 || componente.tipo_componente.idTipoComponente === 2 || componente.tipo_componente.idTipoComponente === 3 ? <VistaPeriferico componente={componente} /> :
                            <VistaDisco componente={componente} />}
            </>
        </div>
    )
}

function VistaPeriferico(props) {
    return (
        <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="flex-start" spacing={5}>
            <Stack direction="column" justifyContent="flex-start" width={{ sm: '50%', xs: '90%' }} >
                <Typography variant="h6" m={1}>Información General:</Typography>
                <Paper elevation={3}>
                    <Stack direction="column" spacing={0.1} m={1.5}>
                        <Typography variant="body1" sx={{ color: '#62A0C0', fontWeight: 'bold' }}>Equipo: {
                            <Link className="non-link-cell" to={props.componente.Equipo ? `/vista/equipo/${props.componente.Equipo.descripcion}` : null}>
                                <span>{props.componente.Equipo ? props.componente.Equipo.descripcion : '-' }</span>
                            </Link>
                        }</Typography>
                        <Typography variant="body1">Propiedad: {props.componente.esPropio ? (props.componente.esPropio === 0 ? 'propio' : 'empresa') : '-'}</Typography>
                        <Typography variant="body1">Estado: {props.componente.idEstado ? (props.componente.idEstado === 1 ? 'activo' : 'inactivo') : '-'}</Typography>
                        <Typography variant="body1">{props.componente.idEstado === 2 ? ('Motivo de Baja: ' + props.componente.motivoBaja) : null}</Typography>
                    </Stack>
                </Paper>
            </Stack>
        </Stack>
    )
}

function VistaMonitor(props) {
    return (
        <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="flex-start" spacing={5}>
            <Stack direction="column" justifyContent="flex-start" width={{ sm: '50%', xs: '90%' }} >
                <Typography variant="h6" m={1}>Información General:</Typography>
                <Paper elevation={3}>
                    <Stack direction="column" spacing={0.1} m={1.5}>
                        <Typography variant="body1" sx={{ color: '#62A0C0', fontWeight: 'bold' }}>Equipo: {
                            <Link className="non-link-cell" to={props.componente.Equipo ? `/vista/equipo/${props.componente.Equipo.descripcion}` : null}>
                                <span>{props.componente.Equipo ? props.componente.Equipo.descripcion : '-'}</span>
                            </Link>
                        }</Typography>
                        <Typography variant="body1">Numero de Serie: {props.componente.numSerie}</Typography>
                        <Typography variant="body1">Marca: {props.componente.marca ? props.componente.marca : '-'}</Typography>
                        <Typography variant="body1">Modelo: {props.componente.modelo ? props.componente.modelo : '-'}</Typography>
                        <Typography variant="body1">Estado: {props.componente.idEstado ? (props.componente.idEstado === 1 ? 'activo' : 'inactivo') : '-'}</Typography>
                        <Typography variant="body1">{props.componente.idEstado === 2 ? ('Motivo de Baja: ' + props.componente.motivoBaja) : null}</Typography>
                    </Stack>
                </Paper>
            </Stack>
        </Stack>
    )

}

function VistaDisco(props) {
    return (
        <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="flex-start" spacing={5}>
            <Stack direction="column" justifyContent="flex-start" width={{ sm: '50%', xs: '90%' }} >
                <Typography variant="h6" m={1}>Información General:</Typography>
                <Paper elevation={3}>
                    <Stack direction="column" spacing={0.1} m={1.5}>
                        <Typography variant="body1" sx={{ color: '#62A0C0', fontWeight: 'bold' }}>Equipo: {
                            <Link className="non-link-cell" to={props.componente.Equipo ? `/vista/equipo/${props.componente.Equipo.descripcion}` : null}>
                                <span>{props.componente.Equipo ? props.componente.Equipo.descripcion : '-'}</span>
                            </Link>
                        }</Typography>
                        <Typography variant="body1">Capacidad: {props.componente.capacidadMemoria ? props.componente.capacidadMemoria : '-'}</Typography>
                        <Typography variant="body1">Encriptado: {props.componente.estaEncriptado ? (props.componente.estaEncriptado === 1 ? 'si' : 'no') : '-'}</Typography>
                        <Typography variant="body1">Estado: {props.componente.idEstado ? (props.componente.idEstado === 1 ? 'activo' : 'inactivo') : '-'}</Typography>
                        <Typography variant="body1">{props.componente.idEstado === 2 ? ('Motivo de Baja: ' + props.componente.motivoBaja) : null}</Typography>
                    </Stack>
                </Paper>
            </Stack>
        </Stack>
    )
}

function VistaRAM(props) {
    return (
        <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="flex-start" spacing={5}>
            <Stack direction="column" justifyContent="flex-start" width={{ sm: '50%', xs: '90%' }} >
                <Typography variant="h6" m={1}>Información General:</Typography>
                <Paper elevation={3}>
                    <Stack direction="column" spacing={0.1} m={1.5}>
                        <Typography variant="body1" sx={{ color: '#62A0C0', fontWeight: 'bold' }}>Equipo: {
                            <Link className="non-link-cell" to={props.componente.Equipo ? `/vista/equipo/${props.componente.Equipo.descripcion}` : null}>
                                <span>{props.componente.Equipo ? props.componente.Equipo.descripcion : '-'}</span>
                            </Link>
                        }</Typography>
                        <Typography variant="body1">Capacidad: {props.componente.capacidadMemoria ? props.componente.capacidadMemoria : '-'}</Typography>
                        <Typography variant="body1">Descripcion: {props.componente.descripcion ? props.componente.descripcion : '-'}</Typography>
                        <Typography variant="body1">Estado: {props.componente.idEstado ? (props.componente.idEstado === 1 ? 'activo' : 'inactivo') : '-'}</Typography>
                        <Typography variant="body1">{props.componente.idEstado === 2 ? ('Motivo de Baja: ' + props.componente.motivoBaja) : null}</Typography>
                    </Stack>
                </Paper>
            </Stack>
        </Stack>
    )
}

function PantallaCargando() {
    return <div className="divCentral"> <h1>Cargando...</h1></div>;
}



export default Componente;