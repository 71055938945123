import React from "react";
import { Stack } from '@mui/material';
import Fila from './Fila';

export default function PlantillaPuestos(props) {
    return (
        <Stack direction='column'>
            <Fila color={props.color} puestos={[props.puestos[0], props.puestos[11]]} />
            <Fila color={props.color} puestos={[props.puestos[1], props.puestos[10]]} />
            <Fila color={props.color} puestos={[props.puestos[2], props.puestos[9]]} />
            <Fila color={props.color} puestos={[props.puestos[3], props.puestos[8]]} />
            <Fila color={props.color} puestos={[props.puestos[4], props.puestos[7]]} />
            <Fila color={props.color} puestos={[props.puestos[5], props.puestos[6]]} />
        </Stack>
    )
}

