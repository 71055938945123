import React from "react";
import {Stack } from '@mui/material';
import AppContext from "../Context/AppContext";
import { useContext} from 'react';
import { PlantillaPuestos5 } from "./PlantillaPuestos5";
import Pecera from "./Peceras";

export default function PrimerPiso(){
    const { token, cerrarSesion} = useContext(AppContext);
    const [puestos, setPuestos] = React.useState([]);
    const [puestosSS, setPuestosSS] = React.useState([]);

    //Conexión API Puestos 
    const getPuestos = async () => {
        try {
        const url = process.env.REACT_APP_API_PATH + '/puesto';
        const response = await fetch(url, {
            method: "GET",
            headers: {
                token: token
            },
        });
        const res = await response.json();
        setPuestos(res);
        setPuestosSS(res.filter((p) => p.idPlanta === 3));

        console.log(res.status);
        } catch (error) {
        console.error(error);
        cerrarSesion();
        }
    };

    React.useEffect(() => {
        getPuestos();
    }, []);

    if (!puestosSS || !puestosSS) {
        return <div className="divCentral"> <h1>Cargando...</h1></div>;
    }

    return (
        <div className="divCentral">
            <h1>Primer Piso</h1>
            <div>
                <Stack direction="row"
                    spacing={5}
                    sx={{
                        overflowX: 'auto',
                        overflowY: 'hidden',
                        flexWrap: 'nowrap',
                        maxWidth: '100%',
                        marginTop: '4%',
                        paddingBottom: '4%',
                    }}>
                    <PlantillaPuestos5 puestos={puestosSS.slice(0, 11)} color={3}></PlantillaPuestos5>
                    <PlantillaPuestos5 puestos={puestosSS.slice(10, 21)} color={3}></PlantillaPuestos5>
                    <PlantillaPuestos5 puestos={puestosSS.slice(20, 31)} color={3}></PlantillaPuestos5>
                    <PlantillaPuestos5 puestos={puestosSS.slice(30, 41)} color={4}></PlantillaPuestos5>
                    <PlantillaPuestos5 puestos={puestosSS.slice(40, 51)} color={4}></PlantillaPuestos5>
                    <PlantillaPuestos5 puestos={puestosSS.slice(50, 61)} color={4}></PlantillaPuestos5>
                    <Pecera />
                </Stack>
            </div>
        </div>
    )
}