import React from "react";
import { useState } from "react";
import { DataGrid, esES} from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CheckIcon from '@mui/icons-material/Check';
import Chip from '@mui/material/Chip';
import CloseIcon from '@mui/icons-material/Close';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import SearchIcon from '@mui/icons-material/Search';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Link } from "react-router-dom";
import CustomNoRowsOverlay from "./CustomNoRowsOverlay";

import { useContext} from 'react';
import AppContext from "../Context/AppContext";
var today = new Date();

const DownloadPDFButton = ({ data }) => {
  const handleDownloadPDF = () => {
    const doc = new jsPDF();
    doc.text("Equipos", 10, 10)
    const tableData = data.map((row) => [
      row.puesto,
      row.estado !== null ? 'inactivo' : 'activo',
      row.empleado,
      row.equipo,
      row.fechaInicio,
      row.fechaFin
    ]);

    doc.autoTable({
      head: [['Puesto', 'Estado', 'Empleado', 'Equipo', 'Fecha Inicio', 'Fecha Fin']],
      body: tableData,
    });

    doc.setFontSize(12);
    var now = today.toLocaleString();
    doc.text(now, 40, 10)
    doc.save('historial.pdf');
  };

  return (
    <Button variant="contained" onClick={handleDownloadPDF} sx={{backgroundColor:'#62A0C0', textTransform:'none', "&:hover": {backgroundColor: '#2C4C58',}}}>
      Descargar
    </Button>
  );
};

  
const columns = [
  {
    field: 'puesto',
    headerName: 'Puesto',
    width: 'flex',
    flex: 0.5,
    minWidth: 100,
    renderCell: (params) => (
      <Link className="non-link-cell" to={`/vista/puesto/${params.value}`}>
        <span>{params.value}</span>
      </Link>
    ),
  },
  {
    field: 'estado',
    headerName: 'Estado',
    width: 'flex',
    flex: 0.5,
    minWidth: 100,
    renderCell: (params) => {
      const estaInactivo = params.value != null;
      return <Chip size="small" icon={estaInactivo ? <CloseIcon fontSize="small" /> : <CheckIcon fontSize="small" />} label={estaInactivo ? "Inactivo" : "Activo"} variant={"outlined"} color={estaInactivo ? "error" : "success"} />;
    }
  },
  {
    field: 'empleado',
    headerName: 'Empleado',
    width: 'flex',
    flex: 1,
    minWidth: 200,
    renderCell: (params) => {
      return (
        <>
          <Link className="non-link-cell" to={`/vista/empleado/${params.value}`}>
            <IconButton>
              <PersonOutlineOutlinedIcon color="#2C4C58" fontSize="medium" />
            </IconButton>
            <span>{params.value}</span>
          </Link>
        </>);
    }
  },
  {
    field: 'equipo', headerName: 'Equipo', width: 'flex', flex: 0.5, minWidth: 100,
    renderCell: (params) => (
      <Link className="non-link-cell" to={`/vista/equipo/${params.value}`}>
        <span>{params.value}</span>
      </Link>
    ),
  },
  { field: 'fechaInicio', headerName: 'Fecha Inicio', width: 'flex', flex: 0.5, minWidth: 100,},
  { field: 'fechaFin', headerName: 'Fecha Fin', width: 'flex', flex: 0.5, minWidth: 100,},
];


export default function ConsultaHistorial(){
  const [selectedRows, setSelectedRows] = useState([]);
  const [historial, setHistorial] = useState([]);
  const [pageSize, setPageSize] = useState(5);
  const [filters, setFilters] = useState({
    idPlanta: '',
    estado: '',
    busqueda: '',
  });

  const {token} = useContext(AppContext);

  //Conexión API
  const getHistorial = async () => {
    try {
      const url = process.env.REACT_APP_API_PATH + '/historial';
      const response = await fetch(url, {
        method: "GET",
        headers: {
          token: token
        }
      })
      const res = await response.json()
      setHistorial(res);
      console.log(res.status);
    } catch (error) {
      console.error(error);
    }
  };

  //Filtrado de datos según selección
  const filteredData = ConvertirObjeto(historial).filter(item => {
    return (
      (filters.idPlanta === '' || item.idPlanta === filters.idPlanta) &&
      (filters.estado === '' || item.estadoActual === filters.estado) &&
      (filters.busqueda === '' ||
        item.id.toString().includes(filters.busqueda) ||
        item.puesto.includes(filters.busqueda) ||
        item.empleado.includes(filters.busqueda) ||
        item.fechaInicio.toString().includes(filters.busqueda) ||
        item.equipo.toString().includes(filters.busqueda))
    );
  });

  React.useEffect(() => {
    getHistorial()
  }, [])

  const rows = historial != null ? ConvertirObjeto(historial) : { id: 0, equipo:'uwu', componentes:['Mouse', 'Teclado', 'Monitor', 'Auriculares']};

  const handleRowSelection = (rowId) => {
    const isSelected = selectedRows.includes(rowId);
    let updatedSelection;
    if (isSelected) {
      updatedSelection = selectedRows.filter((id) => id !== rowId);
    } else {
      updatedSelection = [...selectedRows, rowId];
    }
    setSelectedRows(updatedSelection);
  };

  return (
    <div className="divCentral">
      <div style={{ paddingBottom: '2%' }}>
        <h1>Historial</h1>
      </div>
      <Stack direction='row' useFlexGap flexWrap="wrap" alignItems="center" spacing={'10%'} justifyContent={{sx:"center", sm:"space-between"}}>

        {/* Filtro por Planta */}
        <FormControl sx={{ m: 1, minWidth:{xs:'45%', sm:120}, mr:0, ml:0 }} size="small">
          <InputLabel id="demo-select-small-label">Planta</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={filters.idPlanta}
            onChange={e => setFilters({ ...filters, idPlanta: e.target.value })}
            label="Planta"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={'1'}>SS</MenuItem>
            <MenuItem value={'2'}>PB</MenuItem>
            <MenuItem value={'3'}>1P</MenuItem>
          </Select>
        </FormControl>

        {/* Filtro por Estado */}
        <FormControl sx={{ m: 1, minWidth:{xs:'45%', sm:120}, mr:0, ml:0 }} size="small">
          <InputLabel id="demo-select-small-label">Estado</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={filters.estado}
            onChange={e => setFilters({ ...filters, estado: e.target.value })}
            label="Estado"
          >
            <MenuItem value="">
              <em >None</em>
            </MenuItem>
            <MenuItem value={'activo'}>Activo</MenuItem>
            <MenuItem value={'inactivo'}>Inactivo</MenuItem>
          </Select>
        </FormControl>

        {/* Busqueda */}
        <FormControl sx={{ m: 1, width:{xs:'100%', sm:'25ch'}, mr:0, ml:0}} variant="outlined" size="small">
          <InputLabel htmlFor="outlined-adornment-password">Buscar...</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            value={filters.busqueda}
            onChange={e => setFilters({ ...filters, busqueda: e.target.value })}
            endAdornment={
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            }
            label="Buscar"
          />
        </FormControl>
      </Stack>

      <Box
        sx={{
          height: 400,
          width: '100%',
          paddingTop: '1%'
        }}>
        <DataGrid
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          rows={historial ? filteredData : null}
          columns={columns}
          rowsPerPageOptions={[5, 10, 20]}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          checkboxSelection
          disableColumnFilter
          disableDensitySelector
          disableColumnSelector
          headerClassName="headerCeleste"
          pagination
          pageSize={pageSize}
          onSelectionModelChange={handleRowSelection}
          selectionModel={selectedRows}
          slots={{
            noRowsOverlay: CustomNoRowsOverlay,
          }}
        />
      </Box>
      <Stack direction="row" alignItems="center" justifyContent="center" sx={{ width: '100%', paddingTop: '1%' }}>
        <DownloadPDFButton data={selectedRows.length !== 0 ? selectedRows : rows} />
      </Stack>
    </div>
  )
}

function ConvertirObjeto(historialJson) {
  const dataProcesada = [];
  historialJson.map((h, index) => {
    const est = (h.fechaFin !== null) ? 'inactivo' : 'activo';
    dataProcesada.push({
      id: index,
      estado: h.fechaFin,
      puesto: h.puesto,
      empleado: h.empleado,
      equipo: h.descripcion,
      fechaInicio: new Date(h.fechaInicio).toLocaleDateString(),
      fechaFin: h.fechaFin ? new Date(h.fechaFin).toLocaleDateString() : '',
      estadoActual: est
    })
  });
  return dataProcesada;
}