import React from "react";
import cody from '../../images/Cody-Triste.png'

export default function PantallaErrorServidor(){
    return(
        <div className="divError">
            <img src={cody} className="codyError" alt='imagen-error'></img>
            <h1>Error 500</h1>
            <h3>Error de Servidor</h3>
            <p style={{margin:'1%', width:'40%'}}>Tu solicitud no pudo ser completada. Por favor intentalo de nuevo más tarde.</p>

        </div>
    )
    
}