import React, { useState } from 'react';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import FileOpenOutlinedIcon from '@mui/icons-material/FileOpenOutlined';
import AppContext from './Context/AppContext';
import SaveAltIcon from '@mui/icons-material/SaveAlt';


function PdfViewerModal(props) {
    const [open, setOpen] = useState(false);
    const [documento, setDocumento] = useState('');
    const { token, rol } = React.useContext(AppContext);

    //Conexión Api - Documento
    const getDocumento = async (documento) => {
        try {
          const urlApi = process.env.REACT_APP_API_PATH + '/documento/nombre/' + documento;
          fetch(urlApi, {
            method: 'GET',
            headers:{
              token: token,
            }
          })
            .then(async (response) => await response.blob())
            .then(blob => {
              setDocumento(URL.createObjectURL(blob));
            })
            .catch(error => console.error('Error al obtener el archivo', error));
        } catch (error) {
          console.error(error);
        }
      };
    
    //Conexión API
    const marcarDocumentoVisto = () => {
        const urlApi = process.env.REACT_APP_API_PATH + "/firma/leer/" + props.idDocumento;
        fetch(urlApi, {
            method: "PUT",
            headers: {
                token: token,
            }
        })
            .then(async (response) => await response.json())
            .then((data) => {
                console.log(data);
                props.setReloadData(true);
            })
            .catch((error) => {
                console.log(error);
                props.setReloadData(false);
            });
    };


    const handleOpen = async () => {
        getDocumento(props.pdfDocument);
        console.log(('Documento', props.pdfDocument));
        setOpen(true);
        if(rol === 3){
             marcarDocumentoVisto();
        }
       
    };


    const handleClose = () => {
        setOpen(false);
        setDocumento('');
    };


    return (
        <>
            <>{props.rol === 3 ?
            <Button variant="contained" sx={{ maxWidth: 'fit-content', backgroundColor: '#62A0C0', textTransform: 'none' }} onClick={handleOpen}>
                <FileOpenOutlinedIcon fontSize="small" sx={{ padding: '2px' }} />
            </Button> :
                <SaveAltIcon fontSize="small" onClick={handleOpen}/>}
            </>
            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                <DialogTitle>{props.tipo}</DialogTitle>
                <DialogContent>
                     <iframe
                        title={props.tipo}
                        src={documento}
                        width="100%"
                        height="400"
                    ></iframe>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant='contained' sx={{backgroundColor: '#62A0C0'}}>
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default PdfViewerModal;