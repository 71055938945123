import * as React from 'react';
import { Stack } from '@mui/material';
import CustomButton from './CustomButton';
import Silla from './Silla';

function PuestoIzquierda(props){

    return(
        <Stack direction='row'>
            <Silla dir='i' puesto={props.puesto}/>
            <CustomButton puesto={props.puesto ? props.puesto.puesto : 'Puesto'} color={props.color}/> 
        </Stack>
    )
}

function PuestoDerecha(props){

    return(
        <Stack direction='row'>
            <CustomButton puesto={props.puesto ? props.puesto.puesto : 'Puesto'} color={props.color}/>
            <Silla dir='d' puesto={props.puesto}/>
        </Stack>
    )
}


export default function Fila(props){
    return(
       <Stack direction='row'>
            <PuestoIzquierda puesto={props.puestos[0]} color={props.color} />
            <PuestoDerecha puesto={props.puestos[1]} color={props.color} />
       </Stack> 
    )
}