import React from "react";
import { useContext } from 'react';
import AppContext from "../Context/AppContext";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import QRCode from "qrcode.react";
import Typography from '@mui/material/Typography';
import DownloadIcon from '@mui/icons-material/Download';
import Box from '@mui/material/Box';
import { Autocomplete, TextField } from '@mui/material';

export default function GenerarQr() {

    //Datos 
    const [puestos, setPuestos] = React.useState([]);
    const [puestosQR, setPuestosQR] = React.useState([]);
    const [componentes, setComponentes] = React.useState([]);
    const [mouses, setMouses] = React.useState([]);
    const [monitores, setMonitores] = React.useState([]);
    const [auriculares, setAuriculares] = React.useState([]);
    const [teclados, setTeclados] = React.useState([]);
    const [componentesQR, setComponentesQR] = React.useState([]);
    const [reloadData, setReloadData] = React.useState(false);
    const [puesto, setPuesto] = React.useState('');
    const [componente, setComponente] = React.useState('');
    const [tipo, setTipo] = React.useState('');
    const [componentesAsociados, setComponentesAsociados] = React.useState(false);
    const { token } = useContext(AppContext);


    const handleAgregarPuesto = (e) => {
        e.preventDefault();
        if (puesto) {
            setReloadData(true);
        }
        else {
            alert("Error. Debe seleccionar algún puesto para agregar.");
        }
    };


    const handleAgregarComponente = (e) => {
        e.preventDefault();
        if (componente) {
            setReloadData(true);
            return null;
        }
        else {
            alert("Error. Debe seleccionar algún componente para agregar.");
        }
    };


    const handleDescargar = (e) => {
        e.preventDefault();
        window.print()
    }

    //Conexión API Puestos 
    const getPuestos = async () => {
        try {
            const url = process.env.REACT_APP_API_PATH + '/puesto';
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    token: token
                }
            })
            const res = await response.json()
            setPuestos(res);
            console.log(res.status);
        } catch (error) {
            console.error(error);
        }
    };

    //Conexión API Puesto Seleccionado
    const getPuestoNuevo = async () => {
        try {
            const url = process.env.REACT_APP_API_PATH + '/puesto/fullData/' + puesto;
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    token: token
                }
            })
            const res = await response.json()
            puestosQR.push(res);
            console.log(puestosQR);
        } catch (error) {
            console.error(error);
        }
        setReloadData(false);
    };

    //Conexión API Componentes
    const getComponentes = async () => {
        try {
            const url = process.env.REACT_APP_API_PATH + '/componente';
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    token: token
                }
            })
            const res = await response.json()
            setComponentes(res.filter((c) => c.idEstado === 1));
            setMouses(res.filter((c) => c.tipo_componente.idTipoComponente === 2 && c.idEstado === 1));
            setAuriculares(res.filter((c) => c.tipo_componente.idTipoComponente === 1 && c.idEstado === 1));
            setTeclados(res.filter((c) => c.tipo_componente.idTipoComponente === 3 && c.idEstado === 1));
            setMonitores(res.filter((c) => c.tipo_componente.idTipoComponente === 7 && c.idEstado === 1));
        } catch (error) {
            console.error(error);
        }
    };

    
    React.useEffect(() => {
        getPuestos();
        getComponentes();
    }, [])


    React.useEffect(() => {
        if (puesto && !puestosQR.find((p) => p.id === puesto)) {
            getPuestoNuevo()
        }
        if(componente && !componentesQR.find((c) => c === componente)) {
            componentesQR.push(componente);
            setReloadData(false);
        }
    }, [reloadData])


    return (
        <div className="divCentral">
            <div style={{ paddingBottom: '2%' }}>
                <h1>Generador de QR</h1>
            </div>

            <Stack direction='row' alignItems="center" spacing={1} justifyContent={{ xs: 'center', sm: 'center', md: 'space-between' }} sx={{ marginTop: 2 }}>

                {/* ---- Seleccion Puesto ---- */}
                <Autocomplete
                    sx={{ width: '85%' }}
                    options={puestos}
                    getOptionLabel={(option) => option.puesto}
                    value={puestos.find((e) => e.id === puesto) || null}
                    onChange={(e, value) => setPuesto(value ? value.id : '')}
                    noOptionsText="No se encontraron puestos"
                    renderInput={(params) => (
                        <TextField {...params} label="Puesto" variant="outlined" size="small" fullWidth />
                    )}
                />

                <Button variant="contained" sx={{ backgroundColor: '#62A0C0', textTransform: 'none', "&:hover": {backgroundColor: '#2C4C58',}}} onClick={handleAgregarPuesto} >
                    Agregar
                    <AddIcon />
                </Button>
            </Stack>

            <FormControlLabel
                fullWidth
                sx={{ paddingTop: 2 }}
                label="Generar QR de componentes asociados"
                control={<Checkbox checked={componentesAsociados} onChange={(e) => setComponentesAsociados(e.target.checked)} color='default' />}
            />
            <Stack direction='row' alignItems='center' className="boton" spacing={1} justifyContent={{ xs: 'center', sm: 'center', md: 'space-between' }} sx={{ marginTop: 1, width: '100%' }}>

                {/* ---- Tipo Componente ---- */}
                <FormControl size='small' sx={{ width: '40%' }}>
                    <InputLabel id="demo-select-small-label">Tipo Componente</InputLabel>
                    <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={tipo}
                        label="Tipo Componente"
                        onChange={(e) => setTipo(e.target.value)}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        <MenuItem value={1} key={1}>Auriculares</MenuItem>
                        <MenuItem value={2} key={2}>Mouse</MenuItem>
                        <MenuItem value={3} key={3}>Teclado</MenuItem>
                        <MenuItem value={4} key={4}>Monitor</MenuItem>
                    </Select>
                </FormControl>

                {/* ---- Seleccion Id Componente ---- */}
                <FormControl sx={{ width: '40%' }} size="small">
                    <InputLabel id="demo-simple-select-required-label" >Id Componente</InputLabel>
                    <Select
                        labelId="demo-simple-select-required-label"
                        id="demo-simple-select-required"
                        value={componente}
                        onChange={(e) => setComponente(e.target.value)}
                        label="Id Componente"
                    >
                        <MenuItem value=""><em>None</em></MenuItem>
                        {tipo === 1 ? (auriculares ? auriculares.map((e, index) => (
                            <MenuItem key={index} value={e.idComponente}>
                                {e.idComponente}
                            </MenuItem>
                        )) : <MenuItem value=""><em>None</em></MenuItem>)
                            : (tipo === 2 ? (mouses ? mouses.map((e, index) => (
                                <MenuItem key={index} value={e.idComponente}>
                                    {e.idComponente}
                                </MenuItem>
                            )) : <MenuItem value=""><em>None</em></MenuItem>)
                                : (tipo === 3 ? (teclados ? teclados.map((e, index) => (
                                    <MenuItem key={index} value={e.idComponente}>
                                        {e.idComponente}
                                    </MenuItem>
                                )) : <MenuItem value=""><em>None</em></MenuItem>)
                                    : (tipo === 4 ? (monitores ? monitores.map((e, index) => (
                                        <MenuItem key={index} value={e.idComponente}>
                                            {e.idComponente}
                                        </MenuItem>
                                    )) : <MenuItem value=""><em>None</em></MenuItem>)
                                        : (componentes ? componentes.map((e, index) => (
                                            <MenuItem key={index} value={e.idComponente}>
                                                {e.idComponente}
                                            </MenuItem>
                                        )) : <MenuItem value=""><em>None</em></MenuItem>))))
                        }
                    </Select>
                </FormControl>

                <Button variant="contained" sx={{ backgroundColor: '#62A0C0', textTransform: 'none', "&:hover": {backgroundColor: '#2C4C58',}}} onClick={handleAgregarComponente}>
                    Agregar
                    <AddIcon />
                </Button>
            </Stack>

            <Stack Stack direction={{xs:'column', md:'row'}} spacing={1} justifyContent='center' sx={{ marginTop: 1, width: '100%' }} >
                <Stack direction='column' sx={{ width: '50%' }} alignItems='flex-start' className="boton">
                    <Typography variant="h5" margin={1}>Seleccionados</Typography>

                    <TreeView
                        aria-label="puestos"
                        defaultCollapseIcon={<ExpandMoreIcon />}
                        defaultExpandIcon={<ChevronRightIcon />}

                        sx={{
                            width: '100%',
                            flexGrow: 1,
                            overflowY: 'auto',
                            overflowX: 'hidden',
                            height: 250
                        }}>
                        {!puestosQR ? null : puestosQR.map((p, index) => (
                            <TreeItem key={index} nodeId={index.toString()} label={p.puesto}>
                                {!p.componentes || !componentesAsociados ? null : p.componentes.map((c, index2) => (
                                    <TreeItem key={index.toString() + '-' + index2.toString()} nodeId={index.toString() + '-' + index2.toString()} label={c.tipo_componente.descripcion} />
                                ))}
                            </TreeItem>
                        ))}
                        {!componentesQR ? null : componentesQR.map((c, index3) => (
                            <TreeItem key={'c-' + index3.toString()} nodeId={'c-' + index3.toString()} label={'Componente ' + c}></TreeItem>
                        ))}
                    </TreeView>
                    <Button variant="contained" sx={{ backgroundColor: '#62A0C0', textTransform: 'none', width: '80%', "&:hover": {backgroundColor: '#2C4C58',}}} onClick={handleDescargar} >
                        <DownloadIcon />
                        Descargar
                    </Button>
                </Stack>
                <Stack direction='column'
                    sx={{ width: '50%',}}
                    alignItems='flex-start'
                    className="stack">
                    <Typography variant="h5" margin={1} className="boton">Vista Previa</Typography>
                    <Box sx={{ height: '18.5rem', overflowY: 'auto' }}>
                        <div id="qrDiv">
                            <div className="qr-row">
                                {!puestosQR ? null : puestosQR.map((p, index) => (
                                    <>
                                        <div className="qr-container" key={index}>
                                            <div className="qr-code">
                                                <QRCode
                                                    size={150}
                                                    value={process.env.REACT_APP_WEBAPP_PATH + '/vista/puesto/' + p.puesto}
                                                    renderAs="canvas"
                                                    viewBox="0 0 256 256"
                                                />
                                                <Typography variant="body">{p.puesto}</Typography>
                                            </div>
                                        </div>
                                        {!p.componentes || !componentesAsociados ? null : p.componentes.map((c, index) => (
                                            <div className="qr-container" key={index}>
                                                <div className="qr-code">
                                                    <QRCode
                                                        size={100}
                                                        value={process.env.REACT_APP_WEBAPP_PATH + '/vista/componente/' + c.idComponente}
                                                        renderAs="canvas"
                                                        viewBox="0 0 256 256"
                                                    />
                                                    <Typography variant="body">Componente: {c.idComponente}</Typography>
                                                </div>
                                            </div>
                                        ))}
                                    </>

                                ))}
                                {!componentesQR ? null : componentesQR.map((text, index) => (
                                    <div className="qr-container" key={index}>
                                        <div className="qr-code">
                                            <QRCode
                                                size={100}
                                                value={process.env.REACT_APP_WEBAPP_PATH + '/vista/componente/' + text}
                                                renderAs="canvas"
                                                viewBox="0 0 256 256"
                                            />
                                            <Typography variant="body">Componente {text}</Typography>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Box>

                </Stack>
            </Stack>

        </div>
    )

}