import * as React from 'react';
import { Stack } from '@mui/material';
import Box from '@mui/material/Box';

export default function Maceta(props){

    const style = ({
        customMaceta: {
            height: props.planta === 1 ? '40px' : '108px',
            minWidth: props.planta === 1 ? '120px' :'40px', 
            width: props.planta === 1 ? '120px' :'40px',
            borderRadius: '0',
            borderWidth: '1px',
            borderColor: 'black',
            borderStyle: 'solid',
            display:'flex',
            alignItems:'center',
            justifyContent:'center',
        },
        customPlanta:{
            height: props.planta === 1 ? '5px' : '100px',
            minWidth: props.planta === 1 ? '110px' :'5px',
            width: props.planta === 1 ? '110px' :'5px',
            borderRadius: '0',
            borderWidth: '1px',
            borderColor: 'black',
            borderStyle: 'solid',
        }
    });

    return(
        <Box className='maceta' sx={style.customMaceta}>
            <Box className='planta' sx={style.customPlanta}></Box>
        </Box>
    )
}