import React from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import { useContext} from 'react';
import AppContext from "../../Context/AppContext";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

export default function BorrarComponente(props) {
  const [open, setOpen] = React.useState(false);
  const [motivoBaja, setMotivoBaja] = React.useState('');
  const {token} = useContext(AppContext);

  //Conexión API
  const handleDelete= () => {
    if (motivoBaja) {
      const Detalle = {
        motivoBaja: motivoBaja
      };

      const url = process.env.REACT_APP_API_PATH + "/componente/baja/" + props.componenteId

      fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
        body: JSON.stringify(Detalle)
      }).then((response) => {
        if (response.ok) {
          props.setReloadData(true);
          alert('El componente fue borrado correctamente.');
          return response.json();
        }
        throw new Error('No se pudo eliminar el componente.');
      })
        .catch((error) => {
          console.log(error)
          alert('Error. No se pudo eliminar el componente.')
        });
    }
    else {
      alert('Error. Debe ingresar un motivo de baja.');
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event) => {
    event.preventDefault();
    handleDelete();
    setOpen(false);
    setMotivoBaja('')
  };

  const handleCancel = (event) => {
    event.preventDefault(); 
    setOpen(false);
    setMotivoBaja('');
  };

  return (
    <>
      <>{props.texto ? (
        <Button
          variant="contained"
          sx={{ backgroundColor: '#62A0C0', textTransform: 'none', "&:hover": {backgroundColor: '#2C4C58',}}}
          onClick={handleClickOpen}
          disabled={props.motivoBaja}
        >
          {props.texto}
        </Button>
      ) : (
          <DeleteIcon fontSize="small" onClick={handleClickOpen}/>
      )}</>

      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Dar de Baja Componente"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Está seguro/a que desea dar de baja el componente? Para continuar ingrese a continuación el motivo de la baja.
            <TextField
              sx={{ marginTop: 2 }}
              fullWidth
              label="Motivo de Baja"
              size="small"
              value={motivoBaja}
              onChange={(e) => setMotivoBaja(e.target.value)}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} variant="outlined" sx={{color:"#AF1F1F", borderColor:"#AF1F1F", "&:hover": {borderColor: '#6c1313',}}}>Cancelar</Button>
          <Button onClick={handleClose} variant="contained" autoFocus sx={{backgroundColor:"#AF1F1F", "&:hover": {backgroundColor: '#6c1313',}}} >Confirmar Baja</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}