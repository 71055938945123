import React, { Component } from "react";
import { Stack, MenuItem, Select } from "@mui/material";
import { Navigate } from "react-router-dom";
import jsQR from "jsqr";
import cody from '../../images/Cody_qr3.png'

class LectorQr extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: 'No result',
            camara: '',
        };
        this.videoRef = React.createRef();
        this.canvasRef = React.createRef();
    }

    componentDidMount() {
        this.startCamera();
        this.processFrame();
    }

    componentWillUnmount() {
        this.stopCamera();
    }

    startCamera = async () => {
        const { camara } = this.state;
        const constraints = {
            video: { facingMode: camara }
        };

        try {
            const stream = await navigator.mediaDevices.getUserMedia(constraints);
            if (this.videoRef.current) {
                this.videoRef.current.srcObject = stream;
            }
        } catch (err) {
            console.error('Error al acceder a la cámara:', err);
        }
    };

    stopCamera = () => {
        if (this.videoRef.current && this.videoRef.current.srcObject) {
            this.videoRef.current.srcObject.getTracks().forEach(track => track.stop());
        }
    };

    processFrame = () => {
        const video = this.videoRef.current;
        const canvas = this.canvasRef.current;
        const context = canvas.getContext('2d');

        const processFrame = () => {
            context.drawImage(video, 0, 0, canvas.width, canvas.height);
            const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
            const code = jsQR(imageData.data, imageData.width, imageData.height);

            if (code) {
                this.setState({ data: code.data });
            }

            requestAnimationFrame(processFrame);
        };

        const intervalId = requestAnimationFrame(processFrame);

        this.cleanupFrame = () => {
            cancelAnimationFrame(intervalId);
        };
    };

    render() {
        const { data, camara } = this.state;

        return (
            <div className="divCentral">
                <h1>Lector QR</h1>
                <Stack direction={'row'} justifyContent="center" alignItems={{ xs: "center", sm: "flex-end" }} spacing={1}>
                    <img src={cody} className="codySaludo"></img>
                    <Stack direction={'row'} justifyContent="center" alignItems={{ xs: "center", sm: "flex-end" }} spacing={1} >
                        <Stack>
                            <Select
                                value={camara}
                                onChange={(e) => this.setState({ camara: e.target.value })}
                                displayEmpty
                                size="small"
                                paddingBottom={'1%'}
                            >
                                <MenuItem value="" disabled>Seleccionar Cámara</MenuItem>
                                <MenuItem value="user">Frontal</MenuItem>
                                <MenuItem value="environment">Trasera</MenuItem>
                            </Select>

                            <video ref={this.videoRef} autoPlay playsInline style={{ width: '100%', maxWidth: '100%' }} />
                            <canvas ref={this.canvasRef} style={{ display: 'none' }}></canvas>

                            {data !== "No result" ? <Navigate to={data.replace(process.env.REACT_APP_WEBAPP_PATH, '/')}></Navigate> : null}
                        </Stack>
                    </Stack>
                </Stack>
            </div>
        );
    }
}

export default LectorQr;