import React from "react";
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

//Iconos Usuario 
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

export default function ReferenciasFirma(){
    return(
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1} useFlexGap flexWrap="wrap" width={'47%'}>
                <Stack><Typography variant="subtitle2" color={'#62A0C0'}>REFERENCIAS:</Typography></Stack>
                <Stack direction={'row'}>
                    <CloseIcon color='error'></CloseIcon>
                    <Typography variant="body2">Anulado</Typography>
                </Stack>
                <Stack direction={'row'}>
                    <CheckIcon color="secondary"></CheckIcon>
                    <Typography variant="body2">Pendiente</Typography>
                </Stack>
                <Stack direction={'row'}>
                    <DoneAllIcon color="info"></DoneAllIcon>
                    <Typography variant="body2">Leido sin firmar</Typography>
                </Stack>
                <Stack direction={'row'}>
                    <ErrorOutlineIcon color="warning"></ErrorOutlineIcon>
                    <Typography variant="body2">Firmado con protesto</Typography>
                </Stack>
                <Stack direction={'row'}>
                    <TaskAltIcon color="success"></TaskAltIcon>
                    <Typography variant="body2">Firmado con conformidad</Typography>
                </Stack>
            </Stack>

    )
}