import encodeLogo from "../../images/imageBase64.js"

export default function CAE(nombre,rol, componentes, fecha, hora, equipo) {

  const tableData = !componentes ? null : componentes.map((component) => [1, component.numSerie, component.tipo_componente.descripcion]);

  const docDefinition = {
    pageSize: 'A4',
    content: [
      {
        //imagen en base64
        image: encodeLogo(),
        fit: [160, 160]
       },
       {
        text: "Control de Asignación de Equipos",
        style: "margen",
      },
      {
        text: "\n\n CONTROL DE ASIGNACIÓN DE EQUIPOS",
        style: "header",
      },
      "\n",
      {
        style: 'tableExample',
        table: {
          widths: [80, '*', 80, '*'],
          body: [
            ['Asignado a:', nombre, 'Fecha:', fecha],
            ['Sector:', rol, 'Hora:', hora]
          ],
        }
      },

      {
        table: {
          widths: ['*', '*', '*'],
          headerRows: 1,
          body: [['Cantidad', 'Número de Serie', 'Descripción'], ...tableData],
        },
      },
      "\n",
      {
        text: "\nUSO ACEPTABLE DE LOS ACTIVOS",
        style: "subheader",
      },

      "\nEl personal y/o terceros al que se le asigna estos activos deben conocer la políca de uso aceptable de los " +
      "activos (POL-TEC-01) y se deben comprometer a cumplir con lo que establece la misma.",

      "\nEs obligación del empleado y/o terceros el buen uso de los activos que le son asignados.",
      "\n\n\n\n",
      "Entregado a: .......................................................................................................................",
      {
        text: "Firma Colaborador",
        style: "firma1",
      },
      "\n\n\n",
      "Entregado por: ....................................................................................................................",
      {
        text: "Responsable de Microinformática e Infraestructura",
        style: "firma2",
      },
      "\n\n\n",
      "Controlado y aprobado por:...............................................................................................",
      {
        text: "Responsable de RRHH",
        style: "firma3",
      },

    ],
    styles: {
      header: {
        fontSize: 14,
        bold: true,
        alignment: 'center',
      },
      margen:{
        fontSize: 12, 
        bold: true, 
        alignment: 'right',
      },
      subheader: {
        fontSize: 12,
        bold: true,
        alignment: 'center',
      },
      tableExample: {
        margin: [0, 5, 0, 15],
      },
      tableHeader: {
        bold: true,
        fontSize: 13,
        color: "black",
      },
      firma1:{
        margin: [200, 0, 0, 10],
        italics: true
      },
      firma2:{
        margin: [130, 0, 0, 10],
        italics: true
      },
      firma3:{
        margin: [220, 0, 0, 10],
        italics: true
      }
    },
    defaultStyle: {
      alignment: 'justify',
      FontFace: 'arial'
    },
  };

  return docDefinition;
}