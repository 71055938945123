import React from "react";
import { useState } from "react";
import { DataGrid, GridActionsCellItem, useGridApiRef, esES } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import BorrarEquipo from "../Formularios/Borrar/BorrarEquipo";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import FormNuevoEquipo from "../Formularios/Crear/NuevoEquipo";
import FormModificarEquipo from "../Formularios/Modificar/ModificarEquipo";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import SearchIcon from '@mui/icons-material/Search';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useContext} from 'react';
import AppContext from "../Context/AppContext";
import { Link } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import CustomNoRowsOverlay from "./CustomNoRowsOverlay";

var today = new Date();

const DownloadPDFButton = ({ data }) => {
  const handleDownloadPDF = () => {
    const doc = new jsPDF();
    doc.text("Equipos", 10, 10)
    const tableData = data.map((row) => [
      row.descripcion,
      row.id,
      row.tipo,
      row.componentes.map((c) => c.tipo_componente.descripcion),
      row.puesto,
    ]);

    doc.autoTable({
      head: [['Equipo', 'Id', 'Tipo Equipo', 'Componentes', 'Puesto']],
      body: tableData,
    });

    doc.setFontSize(12);
    var now = today.toLocaleString();
    doc.text(now, 40, 10)
    doc.save('equipos.pdf');
  };

  return (
    <Button variant="contained" onClick={handleDownloadPDF} sx={{ backgroundColor: '#62A0C0', textTransform: 'none', "&:hover": {backgroundColor: '#2C4C58',} }}>
      Descargar
    </Button>
  );
};


//Setear el color de los chips de componentes
function colorChip(prop){
  switch(prop){
    case 'mouse': 
      return '#6941C6';
    case 'teclado':
      return '#175CD3';
    case 'monitor':
      return '#9f5ae5';
    case 'auriculares':
      return '#9c9c9c';
    case 'ram':
      return '#62A0C0';
    case 'disco NVMe-M.2':
      return '#2c2536';
    case 'disco HDD':
      return '#7f69a5';
    default:
      return '#40c0ff';
  }
}

export default function ConsultaEquipos(){
  const [reloadData, setReloadData] = React.useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [pageSize, setPageSize] = useState(5);
  const[equipos, setEquipos] = useState([]);
  const [filters, setFilters] = useState({
    idPlanta: '',
    tipo: '',
    componente: '',
    busqueda: '',
    estado:'',
  });

  const columns = [
    {
      field: 'descripcion', headerName: 'Equipo', width: 'flex', flex: 0.55, minWidth: 100,
      renderCell: (params) => (
        <Link className="non-link-cell" to={`/vista/equipo/${params.value}`}>
          <span>{params.value}</span>
        </Link>
      ),
    },
    { field: 'idEstado', headerName: 'Estado', width: 'flex', flex: 0.65, 
      renderCell: (params) => {
        const estaInactivo = params.value === 2 ? true : false;
        return <Chip size="small" icon={estaInactivo ? <CloseIcon fontSize="small" /> : <CheckIcon fontSize="small" />} label={estaInactivo === false ? "activo" : "inactivo"} variant={"outlined"} color={estaInactivo ? "error" : "success"} />;
      }},
    { field: 'tipo', headerName: 'Tipo Equipo', width: 'flex', flex: 0.65, minWidth: 100, },
    {
      field: 'componentes',
      width: 'flex',
      flex: 1.8,
      minWidth: 200,
      headerName: 'Componentes',
      renderCell: (params) => {
        return params.value.map((p, index) => {
          return (<Link className="non-link-cell" to={`/vista/componente/${p.idComponente}`} key={index+1}>
            <Chip label={p.tipo_componente.descripcion} variant={"outlined"} size="small" sx={{ color: colorChip(p.tipo_componente.descripcion) }}></Chip>
          </Link>)
        })
      }
    },
    {
      field: 'puesto', headerName: 'Puesto', idth: 'flex', flex: 0.65, minWidth: 100,
      renderCell: (params) => (
        <Link className="non-link-cell" to={`/vista/puesto/${params.value}`}>
          <span>{params.value}</span>
        </Link>
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      width: 'flex',
      flex: 0.7,
      minWidth: 100,
      getActions: (params) => [
        <GridActionsCellItem
          icon={(!params.row.puesto && params.row.idEstado === 1) ? <BorrarEquipo equipoId={params.id} setReloadData={setReloadData} /> : <DeleteIcon fontSize="small" color="disabled" />}
          label="Delete"
        />,
        <GridActionsCellItem
          icon={(!params.row.puesto && params.row.idEstado === 1) ? <FormModificarEquipo equipoId={params.id} setReloadData={setReloadData}/> : <EditIcon fontSize="small" color="disabled" />}
          label="Edit"
        />,
      ],
    },
  ];

  //Filtrado de datos según selección
  const filteredData = equipos.filter(item => {
    return (
      (filters.idPlanta === '' || item.idPlanta.toString() === filters.idPlanta) &&
      (filters.estado === '' || item.idEstado.toString() === filters.estado) &&
      (filters.tipo=== '' || item.tipo === filters.tipo) &&
      (filters.componente === '' || item.componentes.some((c) => c.tipo_componente.idTipoComponente.toString() === filters.componente)) &&
      (filters.busqueda === '' ||
        item.id.toString().includes(filters.busqueda) ||
        item.idEstado.toString().includes(filters.busqueda) ||
        item.tipo.includes(filters.busqueda) ||
        item.descripcion.includes(filters.busqueda))
    );
  });

  const apiRef = useGridApiRef();

  const { token } = useContext(AppContext);

  //Conexión API Equipos
  const getEquipos = async () => {
    try {
      const url = process.env.REACT_APP_API_PATH + '/equipo' + (reloadData ? `?timestamp=${Date.now()}` : '');
      const response = await fetch(url, {
        method: "GET",
        headers: {
          token: token
        },
      });
      const res = await response.json();
      setEquipos(res);
      setReloadData(false);
      console.log(res.status);
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    getEquipos();
  }, [reloadData]);

  const rows = equipos != null ? equipos : { id: 0, equipo: 'uwu', componentes: ['Mouse', 'Teclado', 'Monitor', 'Auriculares'] };

  //Filas Seleccionadas
  const onRowsSelectionHandler = (ids) => {
    const selectedRowsData = ids.map((id) => rows.find((row) => row.id === id));
    setSelectedRows(selectedRowsData)
  };

  return (
    <div className="divCentral">
      <Stack direction='row' useFlexGap flexWrap="wrap" alignItems="center" justifyContent="space-between" paddingBottom={'2%'}>
        <h1>Equipos</h1>
        <FormNuevoEquipo setReloadData={setReloadData} />
      </Stack>


      <Stack direction='row' useFlexGap flexWrap="wrap" alignItems="center" spacing={'2%'} justifyContent={{sx:"center", sm:"space-between"}}>
        {/* Filtro por Planta */}
        <FormControl sx={{ m: 1, minWidth:{xs:'49%', sm:120}, mr:0, ml:0 }} size="small">
          <InputLabel id="demo-select-small-label">Planta</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={filters.idPlanta}
            onChange={e => setFilters({ ...filters, idPlanta: e.target.value })}
            label="Planta"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={'1'}>SS</MenuItem>
            <MenuItem value={'2'}>PB</MenuItem>
            <MenuItem value={'3'}>1P</MenuItem>
          </Select>
        </FormControl>

        {/* Filtro por Estado */}
        <FormControl sx={{ m: 1, minWidth:{xs:'49%', sm:120}, mr:0, ml:0 }} size="small">
          <InputLabel id="demo-select-small-label">Estado</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={filters.estado}
            onChange={e => setFilters({ ...filters, estado: e.target.value })}
            label="Estado"
          >
            <MenuItem value=''>
              <em>None</em>
            </MenuItem>
            <MenuItem value={'1'}>Activo</MenuItem>
            <MenuItem value={'2'}>Inactivo</MenuItem>
          </Select>
        </FormControl>

        {/* Filtro por Tipo */}
        <FormControl sx={{ m: 1, minWidth:{xs:'49%', sm:160}, mr:0, ml:0 }} size="small">
          <InputLabel id="demo-select-small-label">Tipo Equipo</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={filters.tipo}
            onChange={e => setFilters({ ...filters, tipo: e.target.value })}
            label="Tipo Equipo"
          >
            <MenuItem value=''>
              <em>None</em>
            </MenuItem>
            <MenuItem value={'escritorio'} key={1}>Escritorio</MenuItem>
            <MenuItem value={'macbook'} key={2}>Macbook</MenuItem>
            <MenuItem value={'notebook'} key={3}>Notebook</MenuItem>
            <MenuItem value={'propio'} key={4}>Propio</MenuItem>
          </Select>
        </FormControl>

        {/* Filtro por Tipo de Componente */}
        <FormControl sx={{ m: 1, minWidth:{xs:'49%', sm:200}, mr:0, ml:0 }} size="small">
          <InputLabel id="demo-select-small-label">Tipo Componente</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={filters.componente}
            label="Tipo Componente"
            onChange={e => setFilters({ ...filters, componente: e.target.value })}
          >
            <MenuItem value=''>
              <em>None</em>
            </MenuItem>
            <MenuItem value={'1'}>Auriculares</MenuItem>
            <MenuItem value={'2'}>Mouse</MenuItem>
            <MenuItem value={'3'}>Teclado</MenuItem>
            <MenuItem value={'4'}>RAM</MenuItem>
            <MenuItem value={'5'}>Disco SSD</MenuItem>
            <MenuItem value={'6'}>Disco HDD</MenuItem>
            <MenuItem value={'7'}>Monitor</MenuItem>
            <MenuItem value={'8'}>disco NVMe-M.2</MenuItem>
          </Select>
        </FormControl>

        {/* Busqueda */}
        <FormControl sx={{ m: 1, width:{xs:'100%', sm:'25ch'}, mr:0, ml:0}} variant="outlined" size="small">
          <InputLabel htmlFor="outlined-adornment-password">Buscar...</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            value={filters.busqueda}
            onChange={e => setFilters({ ...filters, busqueda: e.target.value })}
            endAdornment={
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            }
            label="Buscar"
          />
        </FormControl>
      </Stack>

      <Box
        sx={{
          height: 400,
          width: '100%',
          paddingTop: '1%'
        }}>
        <DataGrid
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          rows={equipos ? filteredData : null}
          apiRef={apiRef}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[5, 10, 20]}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          pagination
          checkboxSelection
          disableColumnFilter
          disableDensitySelector
          disableColumnSelector
          headerClassName="headerCeleste"
          onRowSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
          selectionModel={selectedRows}
          slots={{
            noRowsOverlay: CustomNoRowsOverlay,
          }}
        />
      </Box>
      <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" justifyContent="center" sx={{ width: '100%', paddingTop: '1%' }}>
        <DownloadPDFButton data={selectedRows.length !== 0 ? selectedRows : rows} />
      </Stack>

    </div>
  )
}