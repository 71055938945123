import React from "react";
import { Stack } from '@mui/material';
import GridViewIcon from '@mui/icons-material/GridView';
import Typography from '@mui/material/Typography';
import BorrarPuesto from "../Formularios/Borrar/BorrarPuesto";
import Paper from '@mui/material/Paper';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { useParams } from 'react-router-dom';
import { useContext } from 'react';
import AppContext from "../Context/AppContext";
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import ModalPaginado from "../Formularios/Crear/NuevoPuesto";


function Puesto() {
    const [puesto, setPuesto] = React.useState();
    const [puestos, setPuestos] = React.useState();
    const [reloadData, setReloadData] = React.useState(false);
    const { id } = useParams();
    const { token } = useContext(AppContext);

    //Conexión API Equipos por Id
    const getPuesto = async () => {
        try {
            const url = process.env.REACT_APP_API_PATH + '/puesto/descripcion/';
            const response = await fetch(url + id + (reloadData ? `?timestamp=${Date.now()}` : ''), {
                method: "GET",
                headers: {
                    token: token
                }
            })
            const res = await response.json()
            setPuesto(res);
            setReloadData(false);
        } catch (error) {
            console.error(error);
        }
    };

    //Conexión API Puestos 
    const getPuestos = async () => {
        try {
            const url = process.env.REACT_APP_API_PATH + '/puesto';
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    token: token
                }
            })
            const res = await response.json()
            setPuestos(res);
        } catch (error) {
            console.error(error);
        }
    };

    React.useEffect(() => {
        getPuesto();
        getPuestos();
    }, [reloadData])

    if (!puesto || !puesto.puesto) {
        return <div className="divCentral"> <h1>Cargando...</h1></div>;
    }

    return (
        <div className="divCentral">
            {/* Cabecera */}
            <Stack direction='row' useFlexGap flexWrap="wrap" justifyContent="space-between" alignItems="center" spacing={1}>
                <Stack direction='row' spacing={2} alignItems="center" justifyContent="flex-start">
                    <GridViewIcon sx={{ fontSize: 85, color: '#B2C5C5' }} />
                    <h1>Puesto {puesto.puesto ? puesto.puesto : 'Puesto'}</h1>
                </Stack>
                <Stack direction='row' spacing={2} alignItems="center" justifyContent="flex-end">
                    {puesto.empleado ? <Button variant="contained" disabled sx={{ backgroundColor: '#62A0C0', textTransform: 'none' }}>Editar</Button> : <ModalPaginado texto="Editar" puestoId={puesto.id} setReloadData={setReloadData} puestos={puestos}></ModalPaginado>}
                    {!puesto.empleado ? <Button variant="contained" disabled sx={{ backgroundColor: '#62A0C0', textTransform: 'none' }}>Dar de Baja</Button> : <BorrarPuesto texto="Dar de Baja" puestoId={puesto.id} setReloadData={setReloadData}/>}
                </Stack>
            </Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="flex-start" spacing={5}>
                <Stack direction="column" justifyContent="flex-start" width={{ sm: '50%', xs: '90%' }} >
                    <Typography variant="h6" m={1}>Información General:</Typography>
                    <Paper elevation={3}>
                        <Stack direction="column" spacing={0.1} m={1.5}>
                            <Typography variant="body1" sx={{ color: '#62A0C0', fontWeight: 'bold' }}>Empleado: {
                                <Link className="non-link-cell" to={`/vista/empleado/${puesto.empleado}`}>
                                    <span>{puesto.empleado ? puesto.empleado : '-'}</span>
                                </Link>
                            }</Typography>
                            <Typography variant="body1" sx={{ color: '#62A0C0', fontWeight: 'bold' }}>Equipo: {
                                <Link className="non-link-cell" to={`/vista/equipo/${puesto.descripcion}`}>
                                    <span>{puesto.descripcion ? puesto.descripcion : '-'}</span>
                                </Link>
                            }</Typography>
                            <Typography variant="body1">Estado:{puesto.estado ? puesto.estado : null}</Typography>
                            <Typography variant="body1">Fecha Actualización: {puesto.fechaActualizacion ? new Date(puesto.fechaActualizacion).toLocaleDateString() : null}</Typography>
                        </Stack>
                    </Paper>

                    <Typography variant="h6" m={1}>Documentación:</Typography>
                    <Paper elevation={3}>
                        <Stack direction="column" spacing={1} m={1.5} width={{ sm: '50%', xs: '90%' }}>
                            <Stack direction="row" alignItems="center">
                                <DescriptionOutlinedIcon sx={{ fontSize: 60, color: '#B2C5C5' }} />
                                <Stack direction="column">
                                    <Typography variant="body1" sx={{ color: '#62A0C0', fontWeight: 'bold' }}>Entrega de Dispositivos</Typography>
                                    <Typography variant="body1">{puesto.documentacion ? puesto.documentacion.acuerdoDispositivo : ' '}</Typography>
                                </Stack>
                            </Stack>
                            <Stack direction="row" alignItems="center" >
                                <DescriptionOutlinedIcon sx={{ fontSize: 60, color: '#B2C5C5' }} />
                                <Stack direction="column">
                                    <Typography variant="body1" sx={{ color: '#62A0C0', fontWeight: 'bold' }}>Gestión de Activos</Typography>
                                    <Typography variant="body1">{puesto.documentacion ? puesto.documentacion.gestionActivos : ' '}</Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Paper>

                </Stack>


            </Stack>


        </div>
    )
}

export default Puesto;