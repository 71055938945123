//https://codesandbox.io/s/nlqy0q?file=/demo.js

import React from "react";
import BorrarPuesto from "../Formularios/Borrar/BorrarPuesto";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { useState} from "react";
import { DataGrid, GridColDef, GridActionsCellItem, esES} from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CheckIcon from '@mui/icons-material/Check';
import Chip from '@mui/material/Chip';
import CloseIcon from '@mui/icons-material/Close';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import IconButton from '@mui/material/IconButton';
import { useContext} from 'react';
import AppContext from "../Context/AppContext";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import SearchIcon from '@mui/icons-material/Search';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import ModalPaginado from "../Formularios/Crear/NuevoPuesto";
import CustomNoRowsOverlay from "./CustomNoRowsOverlay";

var today = new Date();

//Descarga de Datos de la Tabla en PDF
const DownloadPDFButton = ({ data }) => {
  const handleDownloadPDF = () => {
    const doc = new jsPDF();
    doc.text("Puestos", 10, 10)
    const tableData = data.map((row) => [
      row.puesto,
      row.estado,
      row.empleado,
      row.descripcion,
    ]);
    doc.autoTable({
      head: [['Puesto', 'Estado', 'Empleado', 'Equipo']],
      body: tableData,
    });
    doc.setFontSize(12);
    var now = today.toLocaleString();
    doc.text(now, 40, 10)
    doc.save('puestos.pdf');
  };
  return (
    <Button variant="contained" onClick={handleDownloadPDF} sx={{ backgroundColor: '#62A0C0', textTransform: 'none', "&:hover": {backgroundColor: '#2C4C58',}}}>
      Descargar
    </Button>
  );
};


export default function ConsultaPuestos() {
  const [reloadData, setReloadData] = React.useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [puestos, setPuestos] = useState([]);
  const [pageSize, setPageSize] = useState(5);
  const [filters, setFilters] = useState({
    idPlanta: '',
    estado: '',
    busqueda: '',
  });

  const { token, cerrarSesion } = useContext(AppContext);

  //Filas Seleccionadas
  const onRowsSelectionHandler = (ids) => {
    const selectedRowsData = ids.map((id) => rows.find((row) => row.id === id));
    setSelectedRows(selectedRowsData)
  };

  //Filtrado de datos según selección
  const filteredData = puestos.filter(item => {
    return (
      (filters.idPlanta === '' || item.idPlanta.toString() === filters.idPlanta) &&
      (filters.estado === '' || item.estado === filters.estado) &&
      (filters.busqueda === '' ||
        item.id.toString().includes(filters.busqueda) ||
        item.puesto.includes(filters.busqueda)));
  });

  //Columnas de tabla
  const columns = [
    {
      field: 'puesto',
      headerName: 'Puesto',
      width: 'flex',
      flex: 0.75,
      minWidth: 90,
      renderCell: (params) => (
        <Link className="non-link-cell" to={`/vista/puesto/${params.value}`}>
          <span>{params.value}</span>
        </Link>
      ),
    },
    {
      field: 'estado',
      headerName: 'Estado',
      width: 'flex',
      flex: 0.5,
      minWidth: 100,
      renderCell: (params) => {
        const estaInactivo = params.value === "inactivo";
        return <Chip size="small" icon={estaInactivo ? <CloseIcon fontSize="small" /> : <CheckIcon fontSize="small" />} label={params.value} variant={"outlined"} color={estaInactivo ? "error" : "success"} />;
      }
    },
    {
      field: 'empleado',
      headerName: 'Empleado',
      width: 'flex',
      minWidth: 160,
      flex: 1,
      renderCell: (params) => {
        if (params.value) {
          return (
            <>
              <Link className="non-link-cell" to={`/vista/empleado/${params.value}`}>
                <IconButton>
                  <PersonOutlineOutlinedIcon color="#2C4C58" fontSize="medium" />
                </IconButton>
                <span>{params.value}</span>
              </Link>
            </>
          );
        }
      }
    },
    {
      field: 'descripcion', headerName: 'Equipo', width: 'flex', flex: 0.75, minWidth: 100,
      renderCell: (params) => (
        <Link className="non-link-cell" to={`/vista/equipo/${params.value}`}>
          <span>{params.value}</span>
        </Link>
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      width: 'flex',
      minWidth: 100,
      flex: 0.5, 
      getActions: (params) => [
        <GridActionsCellItem
          icon={<BorrarPuesto puestoId={params.id} setReloadData={setReloadData} />}
          label="Delete"
          width="auto"
          size="medium"
        />,
        <GridActionsCellItem
          icon={!params.row.equipo ? <ModalPaginado puestos={puestos} puestoId={params.row.id} setReloadData={setReloadData} /> : <EditIcon color="disabled" fontSize="small" />}
          label="Edit"
          width="auto"
          size="medium"
        />,
      ],
    },
  ];

  //Conexión API Puestos 
  const getPuestos = async () => {
    try {
      const url = process.env.REACT_APP_API_PATH + '/puesto' + (reloadData ? `?timestamp=${Date.now()}` : '');
      const response = await fetch(url, {
        method: "GET",
        headers: {
          token: token
        },
      });
      const res = await response.json();
      setPuestos(res);
      setReloadData(false);
      console.log(res.status);
    } catch (error) {
      console.error(error);
      cerrarSesion();
    }
  };

  React.useEffect(() => {
    getPuestos();
  }, [reloadData]);


  //Filas de la tabla 
  const rows = (puestos !== null && puestos !== undefined) ? puestos : { id: 1, puesto: 'PB-F3-07', estado: 'Inactivo', empleado: 'Luna Velazquez', equipo: 'M-895', fechaInicio: '15/05/2023', fechaFin: '20/05/2023' } ;

  return (
    <div className="divCentral">
      <Stack direction='row' useFlexGap flexWrap="wrap" alignItems="center" justifyContent="space-between" paddingBottom={'2%'}>
        <h1>Puestos</h1>
        <ModalPaginado puestos={puestos} setReloadData={setReloadData} texto={'Actualizar puesto'}/>
      </Stack>
      <Stack direction='row' useFlexGap flexWrap="wrap" alignItems="center" spacing={'10%'} justifyContent={{sx:"center", sm:"space-between"}}>

        {/* Filtro por Planta */}
        <FormControl sx={{ m: 1, minWidth:{xs:'45%', sm:120}, mr:0, ml:0 }} size="small">
          <InputLabel id="demo-select-small-label">Planta</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={filters.idPlanta}
            onChange={e => setFilters({ ...filters, idPlanta: e.target.value })}
            label="Planta"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={'1'}>SS</MenuItem>
            <MenuItem value={'2'}>PB</MenuItem>
            <MenuItem value={'3'}>1P</MenuItem>
          </Select>
        </FormControl>

        {/* Filtro por Estado */}
        <FormControl sx={{ m: 1, minWidth:{xs:'45%', sm:120}, mr:0, ml:0 }} size="small">
          <InputLabel id="demo-select-small-label">Estado</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={filters.estado}
            onChange={e => setFilters({ ...filters, estado: e.target.value })}
            label="Estado"
          >
            <MenuItem value="">
              <em >None</em>
            </MenuItem>
            <MenuItem value={'activo'}>Activo</MenuItem>
            <MenuItem value={'inactivo'}>Inactivo</MenuItem>
          </Select>
        </FormControl>

        {/* Busqueda */}
        <FormControl sx={{ m: 1, width:{xs:'100%', sm:'25ch'}, mr:0, ml:0}} variant="outlined" size="small">
          <InputLabel htmlFor="outlined-adornment-password">Buscar...</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            value={filters.busqueda}
            onChange={e => setFilters({ ...filters, busqueda: e.target.value })}
            endAdornment={
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            }
            label="Buscar"
          />
        </FormControl>
      </Stack>
      <Box
        sx={{
          height: 400,
          width: '100%',
          paddingTop: '1%'
        }}>
        <DataGrid
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          rows={puestos ? filteredData : null}
          pageSize={pageSize}
          rowsPerPageOptions={[5, 10, 20]}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          pagination
          columns={columns}
          checkboxSelection
          disableColumnFilter
          disableDensitySelector
          disableColumnSelector
          onRowSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
          slots={{
            noRowsOverlay: CustomNoRowsOverlay,
          }}
        />
      </Box>
      <Stack direction="row" alignItems="center" justifyContent="center" sx={{ width: '100%', paddingTop: '1%' }}>
        <DownloadPDFButton data={selectedRows.length !== 0 ? selectedRows : rows} />
      </Stack>
    </div>

  )
}
