import React from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import {TextField } from '@mui/material';
import DrawOutlinedIcon from '@mui/icons-material/DrawOutlined';
import AppContext from "../Context/AppContext";
import { useContext } from "react";

export default function FirmarDocumento(props) {
  const [open, setOpen] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [validado, setValidado] = React.useState(false);
  const {token} = useContext(AppContext);

  const getValidacion = async () => {
        const Datos = {
            password: password
        };

        const url = process.env.REACT_APP_API_PATH + '/usuario/validarPassword';
        fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                token: token
            },
            body: JSON.stringify(Datos)
        })
        .then(async (response) => {
            await response; 
            if (response.ok) {
                setValidado(true);
                return response.json();  
            }
            setValidado(false);
            throw new Error('No se pudo validar la contraseña');
          })
          .then((res) => {
            if(res.msg){
                console.log(res.msg);
            }
            else{
              throw new Error('No se pudo validar la contraseña');
            }
          })
          .catch((error) => {
            console.log(error);
            alert("No se pudo validar la contraseña");
        });
};

  //Conexión API
  const firmarDocumentoConformidad = () => {
    fetch(process.env.REACT_APP_API_PATH + "/firma/conformidad/" + props.documento, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        token: token,
      }
    })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
       props.setReloadData(true);
    })
    .catch((error) => {console.log(error);
      props.setReloadData(false);
      throw new Error('No se pudo firmar el documento.');
    });
  };

  //Conexión API
  const firmarDocumentoProtesto = () => {
    fetch(process.env.REACT_APP_API_PATH + "/firma/protesto/" + props.documento, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        token: token,
      }
    })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
       props.setReloadData(true);
    })
    .catch((error) => {console.log(error);
      props.setReloadData(false);
      console.log(error);
      throw new Error('No se pudo firmar el documento.');
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleFirmaConformidad = async (event) => {
    event.preventDefault();
    try {
      await getValidacion();
      if(validado){
        await firmarDocumentoConformidad();
      }
      
    }
    catch (error) {
      console.error(error);
      alert('No se pudo firmar el documento.')
    }
    setOpen(false);
    setValidado(false);
  };

  const handleFirmaProtesto = async (event) => {
    event.preventDefault();
    try {
      await getValidacion();
      if(validado){
        await firmarDocumentoProtesto(); 
        setOpen(false);
      }
      
    }
    catch (error) {
      console.error(error);
      alert('No se pudo firmar el documento.')
    }
    setValidado(false);
    setOpen(false);
  };

  const handleCancel = (event) => {
    event.preventDefault();
    setOpen(false);
  };

  return (
    <>
      {/* Botón Firmar Documento */}
      <>{props.rol === 3 ? <Button variant="contained" disabled={props.firmado} sx={{ backgroundColor: '#62A0C0', textTransform: 'none' }} onClick={handleClickOpen}>Firmar Documento</Button>
      : (props.firmado ? <DrawOutlinedIcon fontSize="small" onClick={handleClickOpen}/> : <DrawOutlinedIcon fontSize="small" color="disabled"/>)}
      </>

      {/* Mensaje */}
      <Dialog
        open={open}
        maxWidth={'60%'}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Ingrese su contraseña"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Por favor, ingrese su contraseña nuevamente para validar su identidad.
            
            {/* Ingreso de contraseña */}
            <FormControl fullWidth>
               <TextField
                    sx={{ marginTop: 2 }}
                    fullWidth
                    label="Contraseña"
                    type='password'
                    required
                    size="small"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                /> 
            </FormControl>
            
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} variant="outlined" sx={{ color: "#62A0C0", borderColor: "#62A0C0"}}>Cancelar</Button>
          <Button onClick={handleFirmaProtesto} variant="outlined" autoFocus sx={{color:"#AF1F1F", borderColor:"#AF1F1F", "&:hover": {borderColor: '#6c1313',}}}>Firmar con Protesto</Button>
          <Button onClick={handleFirmaConformidad} variant="contained" autoFocus sx={{ backgroundColor: "#62A0C0", "&:hover": {backgroundColor: '#2C4C58',}}} >Firmar con Conformidad</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}