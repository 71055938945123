import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormControl from '@mui/material/FormControl';
import AppContext from '../../Context/AppContext';
import { useContext } from 'react';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import createCAE from '../../PantallasGenerar/GeneradorCAE';
import createRSE from '../../PantallasGenerar/GeneradorRSE';
import {Stack} from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import EditIcon from '@mui/icons-material/Edit';
import documentoEquipoPropio from '../../../files/POL-TEC-07.pdf';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';

const ModalPaginado = (props) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [open, setOpen] = React.useState(false);
    const { token, cerrarSesion } = useContext(AppContext);

    //Datos
    const [descripcion, setDescripcion] = React.useState();
    const [empleado, setEmpleado] = React.useState('');
    const [equipo, setEquipo] = React.useState('');
    const [fecha, setFecha] = React.useState('')
    const [cae, setCae] = React.useState(null);
    const [rse, setRse] = React.useState(null);
    const [caeBase64, setCaeBase64] = React.useState(null);
    const [rseBase64, setRseBase64] = React.useState(null);
    const [mensajeError, setMensajeError] = React.useState("");
    const [empleados, setEmpleados] = React.useState(null);
    const [equipos, setEquipos] = React.useState(null);
    const [puestos, setPuestos] = React.useState(null);
    const [password, setPassword] = React.useState(null);
    const [observacion, setObservacion] = React.useState(null);
    const [estaValidado, setEstaValidado] = React.useState(false);
    const [mensajeValidacion, setMensajeValidacion]= React.useState('');


    const tieneComponentesAsignados = (eq) => {
        if( eq && eq.componentes.length > 0){
            return true;
        }
        return false; 
    }


    const esPropiedadEmpresa = (eq) => {
        if((eq && eq.idTipo !== 4 ) || (eq && eq.idTipo === 4 && tieneComponentesAsignados(eq))){
            return true
        }
        return false
    }


    const crearDocumentos = () => {
        const res = createCAE(equipo, empleado.titulo, empleado.nombre);

        console.log(typeof(res));
        res.getBase64((encodedString) => {
            const data = encodedString;
            setCaeBase64(data); 
        });
        var doc = null;
        res.getBlob((blob) => {
            doc = blob;
            setCae(doc);
        });

        const res2 = createRSE(equipo, empleado.nombre, empleado.cuil, empleado.legajo, empleado.titulo);
        
        res2.getBase64((encodedString) => {
            const data = encodedString;
            setRseBase64(data); 
        });

        res2.getBlob((blob) => {
            doc = blob
            setRse(doc); 
        })
    }


    const limpiarDatos = () => {
        setDescripcion(null);
        setFecha(null);
        setEquipo(null); 
        setCae(null);
        setRse(null);
        setCaeBase64(null);
        setRseBase64(null);
        setPassword(null);
        setEstaValidado(false);
        setMensajeValidacion(null); 
    }


    //Conexión API Empleados 
    const getEmpleados = async () => {
        try {
            const url = process.env.REACT_APP_API_PATH + '/empleado/disponibles';
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    token: token
                }
            })
            const res = await response.json()
            setEmpleados(res);
        } catch (error) {
            console.error(error.error);
            cerrarSesion();
        }
    };


    //Conexión API Puestos
    const getPuestos = async () => {
        try {
            const url = process.env.REACT_APP_API_PATH + '/puesto/disponibles';
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    token: token
                }
            })
            const res = await response.json()
            setPuestos(res);
        } catch (error) {
            console.error(error.error);
            cerrarSesion();
        }
    };


    const getValidacion = async () => {
        try {
            const Datos = {
                password: password
            };

            const url = process.env.REACT_APP_API_PATH + '/usuario/validarPassword';
            fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    token: token
                },
                body: JSON.stringify(Datos)
            })
            .then(async (response) => {
                await response; 
                if (response.ok) {
                    return response.json();  
                }
                throw new Error('No se pudo validar la contraseña');
              })
              .then((res) => {
                if(res.msg){
                    setEstaValidado(true);
                    setMensajeValidacion('La contraseña coincide');
                }
                else{
                    setEstaValidado(false);
                    setMensajeValidacion('La contraseña no coincide'); 
                }
              })
              .catch((error) => {
                console.log(error);
                setEstaValidado(false);
                setMensajeValidacion('La contraseña no coincide'); 
            });
        } catch (error) {
            console.error(error);
            setEstaValidado(false);
            setMensajeValidacion('La contraseña no coincide'); 
        }
    };


    //Conexión API Equipos
    const getEquipos = async () => {
        try {
            const url = process.env.REACT_APP_API_PATH + '/equipo/disponibles';
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    token: token
                }
            })
            const res = await response.json()
            setEquipos(res);
        } catch (error) {
            console.error(error);
        }
    };


    React.useEffect(() => {
        getPuestos();
        getEmpleados();
        getEquipos(); 
    }, [open])


    React.useEffect(() => {
        if (props.puestoId && puestos) {
            setDescripcion(puestos.find((p) => p.id === props.puestoId));
        }
    }, [puestos])


    const handlePageChange = (newPage) => {
        if(newPage === 2){
            if(!empleado || !equipo || !fecha || !descripcion){
                setMensajeError("Error. Debe ingresar todos los datos para continuar.");
                return
            }
            if(esPropiedadEmpresa(equipo)){
                crearDocumentos();
            }
            setMensajeError("");
        }
        if(newPage === 3 && ! esPropiedadEmpresa(equipo)){
            setCurrentPage(4);
        }
        else{
            setCurrentPage(newPage);
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
        setCurrentPage(1);
    };

    const handleClose = () => {
        setOpen(false);
        limpiarDatos(); 
    };

    const handleEnviaryFirmar = () => {
        crearPuesto();
        setOpen(false); 
    }

    const handleChangeEmpleado = (event) => {
        setEmpleado(event.target.value);
    }

    const handleChangeEquipo = (event) => {
        setEquipo(event.target.value);
    }

    const handleChangeFecha = (event) => {
        setFecha(event);
    }

    const handleChangeDescripcion = (event) => {
        setDescripcion(event);
    }


    //Conexión API - Actualizar Puesto
    const crearPuesto = () => {
        if (!estaValidado) {
            alert("Error. Debe validar su contraseña para continuar.");
        }
        else{
            const PuestoNuevo = {
                idEmpleado: empleado.idEmpleado,
                idEquipo: equipo.id,
                fechaInicio: fecha,
                CAE: caeBase64,
                RSE: rseBase64,
                idPuesto: descripcion.id,
                observacion: observacion
            }
    
            fetch(process.env.REACT_APP_API_PATH + "/puesto/" + descripcion.id, {
                method: "PUT",
                headers: {
                    token: token,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(PuestoNuevo)
            })
                .then(async (response) => {
                    await response.json();
                    if (response.ok) {
                        alert("El puesto fue creado correctamente");
                        props.setReloadData(true);
                    }
                    else {
                        throw new Error('No se pudo crear el puesto');
                    }
    
                })
                .catch((error) => {
                    console.log(error)
                    alert("Error. No se pudo actualizar el puesto.");
                });
                setOpen(false);
        }
    }


    return (
        <>  <>
            {!props.texto ? <EditIcon fontSize="small" onClick={handleClickOpen}/>
            : <Button
            variant="contained"
            sx={{
                backgroundColor: '#62A0C0',
                textTransform: 'none',
                "&:hover": { backgroundColor: '#2C4C58' },
            }}
            onClick={handleClickOpen}
        >
            {props.texto}
        </Button> }
            </>
            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                <DialogTitle>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        {[1, 2, 3, 4].map((pageNumber, index, array) => (
                            <IconButton
                                key={pageNumber}
                                color="primary"
                                style={{
                                    backgroundColor: currentPage === pageNumber ? '#62A0C0' : '#CCE5F1',
                                    color: currentPage === pageNumber ? 'white' : 'white',
                                    width: '28px',
                                    height: '28px',
                                    borderRadius: '50%',
                                    margin: index < array.length - 1 ? '0 10px 0 0' : '0',
                                    fontSize: '0.8rem',
                                }}
                                onClick={() => handlePageChange(pageNumber)}
                            >
                                {pageNumber}
                            </IconButton>
                        ))}
                    </div>
                </DialogTitle>
                <DialogContent sx={{ height: '400px', overflowY:'auto' }}>
                    {currentPage === 1 && (
                        <>
                            <DialogTitle>Actualizar Puesto</DialogTitle>
                            {/* ---- Puesto ---- */}
                            <FormControl sx={{ width: '100%', marginTop: 2 }} size="small">
                                <InputLabel required>Puesto</InputLabel>
                                <Select
                                    labelId="demo-simple-select-required-label"
                                    id="demo-simple-select-required"
                                    value={descripcion}
                                    name={descripcion ? descripcion.puesto : ''}
                                    onChange={(e) => {handleChangeDescripcion(e.target.value)}}
                                    label="puesto"
                                >
                                    <MenuItem value=''><em>None</em></MenuItem>
                                    {puestos ? puestos.map((e, index) => (
                                        <MenuItem key={e.id} value={e}>
                                            {e.puesto}
                                        </MenuItem>
                                    )) : <MenuItem value=''><em>None</em></MenuItem>}
                                </Select>
                            </FormControl>

                            {/* ---- Empleado ---- */}
                            <FormControl sx={{ width: '100%', marginTop: 2 }} size="small">
                                <InputLabel required>Empleado</InputLabel>
                                <Select
                                    value={empleado} 
                                    name={empleado.nombre ? empleado.nombre : ''}
                                    label="Empleado"
                                    onChange={handleChangeEmpleado}>
                                    <MenuItem value=""><em>None</em></MenuItem>
                                    {empleados !== undefined && empleados !== null ? empleados.map((e, index) => (
                                        <MenuItem key={e.idEmpleado} value={e}>
                                            {e.nombre}
                                        </MenuItem>
                                    )) : <MenuItem value=''><em>None</em></MenuItem>}
                                </Select>
                            </FormControl>


                            {/* ---- Equipo ---- */}
                            <FormControl sx={{ width: '100%', marginTop: 2 }} size="small">
                                <InputLabel id="demo-simple-select-required-label" required>Equipo</InputLabel>
                                <Select
                                    labelId="demo-simple-select-required-label"
                                    id="demo-simple-select-required"
                                    value={equipo}
                                    name={equipo ? equipo.descripcion : ''}
                                    onChange={handleChangeEquipo}
                                    label="equipo"
                                >
                                    <MenuItem><em>None</em></MenuItem>
                                    {equipos != null ? equipos.map((e, index) => (
                                        <MenuItem key={e.id} value={e}>
                                            {e.descripcion}
                                        </MenuItem>
                                    )) : <MenuItem value=''><em>None</em></MenuItem>}
                                </Select>
                            </FormControl>


                            {/* ---- Fecha ----- */}
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer
                                    components={['DatePicker']}
                                    sx={{ width: '100%', marginTop: 1 }}
                                >
                                    <DatePicker
                                        label="Fecha *"
                                        format="DD-MM-YYYY"
                                        slotProps={{ textField: { size: 'small', fullWidth: true } }}
                                        fullWidth
                                        onChange={handleChangeFecha} />
                                </DemoContainer>
                            </LocalizationProvider>


                            {/* ---- Observaciones ---- */}
                            <TextField
                                sx={{ marginTop: 2 }}
                                fullWidth
                                label="Observaciones"
                                size="small"
                                value={observacion}
                                onChange={(e) => setObservacion(e.target.value)}
                            />

                            <Typography variant="body1" color={'red'} paddingTop={2}>{mensajeError}</Typography>
                        </>
                    )}
                    {currentPage === 2 && (
                        <iframe
                            title={"RSE"}
                            src={esPropiedadEmpresa(equipo) ? (rse ? URL.createObjectURL(rse) : null) : documentoEquipoPropio}
                            width="100%"
                            height="390"
                        ></iframe>
                    )}
                    {currentPage === 3 && (
                        <iframe
                            title={"CAE"}
                            src={cae ? URL.createObjectURL(cae) : null}
                            width="100%"
                            height="390"
                        ></iframe>
                    )}
                    {currentPage === 4 && (
                        <>
                            <Typography variant="body1" padding={2}>Datos Ingresados:</Typography>
                            <Card>
                                <CardContent>
                                    <Typography variant="body1">Puesto: {descripcion ? descripcion.puesto : null}</Typography>
                                    <Typography variant="body1">Empleado: {empleado.nombre}</Typography>
                                    <Typography variant="body1">Equipo: {equipo ? equipo.descripcion : null}</Typography>
                                    <Typography variant="body1">Fecha: {new Date(fecha).toLocaleDateString()}</Typography>
                                </CardContent>
                            </Card>

                            <Typography variant="body1" padding={2}>Ingrese su contraseña para continuar: </Typography>
                            <Card>
                                <CardContent>
                                    <Stack direction='row' alignItems="center" marginTop={1} width='90' justifyContent={'center'}>
                                        {/* ---- Contraseña ---- */}
                                        <FormControl sx={{ width: '90%' }}>
                                            <TextField
                                                fullWidth
                                                type='password'
                                                label="Contraseña"
                                                size="small"
                                                value={password}
                                                required
                                                onChange={(e) => { setPassword(e.target.value) }}
                                            />
                                            <FormHelperText>{mensajeValidacion}</FormHelperText>
                                        </FormControl>

                                        <Button onClick={getValidacion}>Validar</Button>
                                    </Stack>
                                </CardContent>
                            </Card>
                            
                            <Typography variant="body1" padding={2}>
                                <WarningAmberIcon sx={{color:'#62A0C0', paddingRight:1}}/>
                                {esPropiedadEmpresa(equipo) ? 
                                'Al hacer click en "Firmar" usted firmando electrónicamente con conformidad los documentos.' :
                                'Al hacer click en "Aceptar" se le enviará un correo al empleado con la política traiga su propio dispositivo. '
                                }
                            </Typography>
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handlePageChange(currentPage - 1)} color="primary" disabled={currentPage === 1}>
                        Volver
                    </Button>
                    <Button onClick={() => (currentPage === 4 ? handleEnviaryFirmar() : handlePageChange(currentPage + 1))} color="primary">
                        {currentPage === 4 && esPropiedadEmpresa(equipo) ? "Firmar" : "Aceptar"}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ModalPaginado;