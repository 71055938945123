import React from 'react';
import { DataGrid, GridActionsCellItem, esES} from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CheckIcon from '@mui/icons-material/Check';
import Chip from '@mui/material/Chip';
import CloseIcon from '@mui/icons-material/Close';
import FormModificarComponente from '../Formularios/Modificar/ModificarComponente';
import FormModificarMonitor from "../Formularios/Modificar/ModificarMonitor";
import FormModificarDisco from "../Formularios/Modificar/ModificarDisco";
import FormModificarMemoria from "../Formularios/Modificar/ModificarMemoria";
import BorrarComponente from '../Formularios/Borrar/BorrarComponente';
import CrearComponente from '../Formularios/Crear/CrearComponente';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useContext} from 'react';
import AppContext from '../Context/AppContext';
import { Link} from 'react-router-dom';
import CustomNoRowsOverlay from './CustomNoRowsOverlay';

import jsPDF from 'jspdf';
import 'jspdf-autotable';

var today = new Date();

const DownloadPDFButton = ({ data }) => {
  const handleDownloadPDF = () => {
    const doc = new jsPDF();
    doc.text("Componentes", 10, 10)
    const tableData = data.map((row) => [
      row.id,
      row.estado,
      row.equipo,
      row.tipo,
    ]);

    doc.autoTable({
      head: [['Identificador', 'Estado', 'Equipo', 'Tipo']],
      body: tableData,
    });

    doc.setFontSize(12);
    var now = today.toLocaleString();
    doc.text(now, 70, 10)
    doc.save('componentes.pdf');
  };

  return (
    <Button variant="contained" onClick={handleDownloadPDF} sx={{backgroundColor:'#62A0C0', textTransform:'none', "&:hover": {backgroundColor: '#2C4C58',} }}>
      Descargar
    </Button>
  );
};

export default function ConsultaComponentes(){

  const {token} = useContext(AppContext);
  const [reloadData, setReloadData] = React.useState(false);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [componentes, setComponentes] = React.useState([]);
  const [pageSize, setPageSize] = React.useState(5);
  const [filters, setFilters] = React.useState({
    idTipoComponente: '',
    estado: '',
    busqueda: '',
  });

  //Filtrado de datos según selección
  const filteredData = ConvertirObjeto(componentes).filter(item => {
    return (
      (filters.idTipoComponente === '' || item.idTipoComponente.toString() === filters.idTipoComponente) &&
      (filters.estado === '' || item.estado.toString() === filters.estado) &&
      (filters.busqueda === '' || 
        item.identificador.toString().includes(filters.busqueda) ||
        item.equipo ? item.equipo.toString().includes(filters.busqueda) : '' ||
        item.tipo.includes(filters.busqueda))
    );
  });

  //Columnas
  const columns = [
    {
      field: 'identificador',
      headerName: 'Identificador',
      width: 'flex',
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <Link className="non-link-cell" to={`/vista/componente/${params.value}`}>
          <span>{params.value}</span>
        </Link>
      ),
    },
    {
      field: 'estado',
      headerName: 'Estado',
      width: 'flex',
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        const estaInactivo = params.value === 2;
        return <Chip size="small" icon={estaInactivo ? <CloseIcon fontSize="small" /> : <CheckIcon fontSize="small" />} label={estaInactivo === false ? "activo" : "inactivo"} variant={"outlined"} color={estaInactivo ? "error" : "success"} />;
      }
    },
    {
      field: 'equipo',
      headerName: 'Equipo',
      width: 'flex',
      flex: 1,
      minWidth: 100,
      renderCell: (params) => (
        <Link className="non-link-cell" to={`/vista/equipo/${params.value}`}>
          <span>{params.value}</span>
        </Link>
      ),
    },
    { field: 'tipo', headerName: 'Tipo', width: 'flex', flex: 1, minWidth: 100,},
    {
      field: 'actions',
      type: 'actions',
      width: 120,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<BorrarComponente componenteId={params.id} setReloadData={setReloadData} />}
          label="Borrar"
        />,
        <GridActionsCellItem
          icon={<>{params.row.tipo === 'ram' ? <FormModificarMemoria id={params.id} setReloadData={setReloadData} /> :
            params.row.tipo === 'monitor' ? <FormModificarMonitor id={params.id} setReloadData={setReloadData} /> :
              params.row.tipo === 'auriculares' || params.row.tipo === 'teclado' || params.row.tipo === 'mouse' ? <FormModificarComponente id={params.id} setReloadData={setReloadData} /> :
                <FormModificarDisco id={params.id} setReloadData={setReloadData} />}</>}
          label="Editar"
        />,
      ],
    },
  ];

  //Conexión API componentes
  const getComponentes = async () => {
    try {
      const url = process.env.REACT_APP_API_PATH + '/componente' + (reloadData ? `?timestamp=${Date.now()}` : '');
      const response = await fetch(url, {
        method: "GET",
        headers: {
          token: token
        },
      });
      const res = await response.json();
      setComponentes(res);
      setReloadData(false);
      console.log(res.status);
    } catch (error) {
      console.error(error);
    }
  };
  
  React.useEffect(() => {
    getComponentes();
  }, [reloadData]);

  const rows = componentes !== null ? ConvertirObjeto(componentes) :  null;
  
  const onRowsSelectionHandler = (ids) => {
    const selectedRowsData = ids.map((id) => rows.find((row) => row.id === id));
    setSelectedRows(selectedRowsData)
  };

  return (
    <div className="divCentral">
      <Stack direction='row' useFlexGap flexWrap="wrap" alignItems="center" justifyContent="space-between" paddingBottom={'2%'}>
        <h1>Componentes</h1>
        <CrearComponente setReloadData={setReloadData} />
      </Stack>
      <Stack direction='row' useFlexGap flexWrap="wrap" alignItems="center" spacing={'10%'} justifyContent={{sx:"center", sm:"space-between"}}>

        {/* Filtro por Tipo de Componente */}
        <FormControl sx={{ m: 1, minWidth:{xs:'45%', sm:200}, mr:0, ml:0 }} size="small">
          <InputLabel id="demo-select-small-label">Tipo Componente</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={filters.idTipoComponente}
            label="Tipo Componente"
            onChange={e => setFilters({ ...filters, idTipoComponente: e.target.value })}
          >
            <MenuItem value=''>
              <em>None</em>
            </MenuItem>
            <MenuItem value={'1'}>Auriculares</MenuItem>
            <MenuItem value={'2'}>Mouse</MenuItem>
            <MenuItem value={'3'}>Teclado</MenuItem>
            <MenuItem value={'4'}>RAM</MenuItem>
            <MenuItem value={'5'}>Disco SSD</MenuItem>
            <MenuItem value={'6'}>Disco HDD</MenuItem>
            <MenuItem value={'7'}>Monitor</MenuItem>
            <MenuItem value={'8'}>disco NVMe-M.2</MenuItem>
          </Select>
        </FormControl>

        {/* Filtro por Estado */}
        <FormControl sx={{ m: 1, minWidth:{xs:'45%', sm:120}, mr:0, ml:0 }} size="small">
          <InputLabel id="demo-select-small-label">Estado</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={filters.estado}
            label="Estado"
            onChange={e => setFilters({ ...filters, estado: e.target.value })}
          >
            <MenuItem value=''>
              <em >None</em>
            </MenuItem>
            <MenuItem value={'1'}>Activo</MenuItem>
            <MenuItem value={'2'}>Inactivo</MenuItem>
          </Select>
        </FormControl>

        {/* Busqueda */}
        <FormControl sx={{ m: 1, width:{xs:'100%', sm:'25ch'}, mr:0, ml:0}} variant="outlined" size="small">
          <InputLabel htmlFor="outlined-adornment-password">Buscar...</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            value={filters.busqueda}
            onChange={e => setFilters({ ...filters, busqueda: e.target.value })}
            endAdornment={
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            }
            label="Buscar"
          />
        </FormControl>
      </Stack>
      <Box
        sx={{
          height: 400,
          width: '100%',
          paddingTop: '1%'
        }}>
        <DataGrid
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          rows={componentes ? filteredData : null}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[5, 10, 20]}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          pagination
          checkboxSelection
          disableColumnFilter
          disableDensitySelector
          disableColumnSelector
          onRowSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
          slots={{
            noRowsOverlay: CustomNoRowsOverlay,
          }}
        />
      </Box>
      <Stack direction="row" alignItems="center" justifyContent="center" sx={{ width: '100%', paddingTop: '1%',}}>
        <DownloadPDFButton data={selectedRows.length !== 0 ? selectedRows : rows} />
      </Stack>
    </div>

  )
}

function ConvertirObjeto(equiposJson) {
  const dataProcesada = [];
  equiposJson.map((c, index) => {
    dataProcesada.push({
      id: c.idComponente,
      identificador: c.idComponente,
      estado: c.idEstado,
      equipo: c.Equipo ? c.Equipo.descripcion : '' ,
      tipo: c.tipo_componente.descripcion,
      idTipoComponente: c.tipo_componente.idTipoComponente,
    })
  });
  return dataProcesada;
} 