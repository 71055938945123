import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import CAE from "../pdf/CAE";
import React from "react";
pdfMake.vfs = pdfFonts.pdfMake.vfs;


const createCAE = (equipo, rol, empleado ) => {
    var cae = null; 
    const fecha = new Date(Date.now());
    cae = pdfMake.createPdf(CAE(empleado, rol, equipo.componentes, fecha.toLocaleDateString(), fecha.toLocaleTimeString('it-IT'), equipo.id));
    return cae; 
};

export default createCAE; 
